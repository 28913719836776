import { Input, Typography, Box, Button } from "@mui/material";
import { Formik } from "formik";
import AppLogo from "../../../../assets/icons/app-logo.svg";
import { useNavigate } from "react-router-dom";
import EmailIcon from "../../../../assets/icons/email.svg";
import { forgotPassword } from "../../../../controller/services/auth";
import toast from "react-hot-toast";
import BackIcon from "../../../../assets/icons/back-dark.svg";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");
  const handleSubmit = (values, setSubmitting) => {
    setSubmitting(true);
    const payload = {
      userType: userType,
      email: values.email,
    };
    forgotPassword(payload)
      .then((resp) => {
        navigate("/verify-otp", {
          state: {
            token: resp.data.token,
            email: values.email,
          },
        });
        toast.success(resp.message);
      })
      .catch((Err) => {
        setSubmitting(false);
        toast.error(Err.message);
      });
  };
  return (
    <Box
      sx={{
        padding: {
          xs: "30px",
          sm: "0 30px",
          md: "0 70px",
          lg: "0 70px",
          xl: "0 70px",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: {
          xs: "auto",
          sm: "auto",
          md: "100vh",
          lg: "100vh",
          xl: "100vh",
        },
      }}
    >
      <Box
        border={2}
        borderColor={"#38879F"}
        borderRadius={5}
        sx={{
          position: "relative",
          width: "100%",
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          sx={{
            padding: {
              xs: "30px 20px",
              sm: "45px",
              md: "45px",
              lg: "45px",
              xl: "70px 45px",
            },
            width: {
              xs: "100%",
              sm: "100%",
              md: "70%",
              lg: "35%",
              xl: "30%",
            },
          }}
        >
          <img
            src={BackIcon}
            alt="back"
            style={{
              position: "absolute",
              left: "10vw",
              top: "15vh",
              cursor: "pointer",
            }}
            className="back-icon"
            onClick={() => navigate(-1)}
          />
          <img className="app-logo-role" src={AppLogo} alt="logo" />
          <Typography
            variant="h3"
            color="#0d2645"
            fontWeight={600}
            padding={"20px 0 10px"}
            sx={{
              fontSize: {
                xs: 20,
                sm: 22,
                md: 24,
                lg: 26,
                xl: 26,
              },
              lineHeight: {
                xs: "27px",
                sm: "28px",
                md: "31.2px",
                lg: "31.2px",
                xl: "31.2px",
              },
            }}
          >
            Reset Password
          </Typography>
          <Typography
            variant="h6"
            color="#8391a0"
            fontWeight={400}
            sx={{
              padding: {
                xs: "0 0 30px",
                sm: "10px",
                md: "10px",
                lg: "10px",
                xl: "10px",
              },
              fontSize: {
                xs: "12px",
                sm: "14px",
                md: "16px",
                lg: "16px",
                xl: "16px",
              },
              lineHeight: {
                xs: "20px",
                sm: "24px",
                md: "25px",
                lg: "25px",
                xl: "25px",
              },
            }}
          >
            Enter the email address associated with your account and get a new
            password
          </Typography>

          <Formik
            initialValues={{ email: "", password: "", confirmPassword: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values, setSubmitting);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    paddingBottom: {
                      xs: 0,
                      sm: "20px",
                      md: "20px",
                      lg: "20px",
                      xl: "20px",
                    },
                    marginTop: {
                      xs: 0,
                      sm: "40px",
                      md: "40px",
                      lg: "40px",
                      xl: "40px",
                    },
                  }}
                >
                  <Input
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    startAdornment={<img src={EmailIcon} alt="email" />}
                    inputProps={{
                      style: {
                        padding: "10px",
                      },
                    }}
                    sx={{
                      padding: "10px 5px 10px 20px",
                      background: "#eff6f8",
                      width: "100%",
                      height: "50px",
                      borderRadius: "10px",
                      "&:after": {
                        borderBottom: "none",
                      },
                      "&:before": {
                        borderBottom: "none",
                      },
                      "&:hover:not(.Mui-disabled, .Mui-error):before": {
                        borderBottom: "none",
                      },
                      "&.Mui-focused": {
                        borderBottom: "none",
                      },
                    }}
                  />
                  {errors.email && touched.email && (
                    <Typography textAlign={"left"} variant="body2" color="red">
                      {errors.email}
                    </Typography>
                  )}
                </Box>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  sx={{
                    width: "100%",
                    height: "55px",
                    marginTop: "15px",
                    background: "#38879f",
                    color: "#fff",
                    fontWeight: 600,
                    borderRadius: "10px",
                    "&.Mui-disabled": {
                      background: "#adcdd7",
                      color: "#fff",
                    },
                    "&:hover": {
                      backgroundColor: "#38879f",
                    },
                  }}
                >
                  Send
                </Button>
                <Typography
                  fontSize={"14px"}
                  color="#8592a1"
                  marginTop={"25px"}
                >
                  Back to{" "}
                  <span
                    style={{
                      fontWeight: 600,
                      color: "#38879f",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      navigate(
                        userType === "hospital"
                          ? "/hospital/signin"
                          : "/nurse/signin"
                      )
                    }
                  >
                    Sign In
                  </span>
                </Typography>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
}
