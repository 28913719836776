import axiosInstance from "../api/axios";

export const FileUpload = async (payload) => {
  const res = await axiosInstance.post("/api/v1/user/uploads", payload);
  return res.data;
};

export const DeleteUploadedFile = async (payload) => {
  const res = await axiosInstance.post("/api/v1/user/uploads/delete", payload);
  return res.data;
};

export const SendSupportQuery = async (payload) => {
  const res = await axiosInstance.post("/api/v1/support/contact", payload);
  return res.data;
};

export const DeleteUser = async () => {
  const res = await axiosInstance.delete("/api/v1/user/");
  return res.data;
};

export const SendReview = async (payload) => {
  const res = await axiosInstance.post("/api/v1/rating/", payload);
  return res.data;
};

export const GetStateCityData = async () => {
  const res = await axiosInstance.get("/api/v1/user/states");
  return res.data;
};

export const GetNotifications = async (payload) => {
  const res = await axiosInstance.post("/api/v1/notification/list", payload);
  return res.data;
};

export const GetRatings = async (payload) => {
  const res = await axiosInstance.post("/api/v1/rating/list", payload);
  return res.data;
};
