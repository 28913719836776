import { Box, Button, Chip, Divider, Grid, Typography } from "@mui/material";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../redux/slices/flags_slice";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { getFormattedValue, getShiftNames } from "../../../utils/helper";
import { GetNurseJobList } from "../../../controller/services/nurse/jobs";
import NoDataFound from "../../common/no-data";
import InfiniteScrollWrapper from "../../common/infinite-scroll";
import { setBreadcrumb } from "../../../redux/slices/breadcrumb_slice";
import EmptyIcon from "../../../assets/icons/empty-board.svg";

export default function NurseDashboard() {
  const [jobList, setJobList] = useState([]);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.filters);
  console.log(filters, "filters");
  const [hasNextResult, setHasNextResult] = useState(0);
  useEffect(() => {
    if (page) {
      setTimeout(() => {
        fetchAllJobs();
      }, 500);
    }
  }, [page, filters]);

  useEffect(() => {
    setPage(1);
  }, [filters]);
  useEffect(() => {
    const arr = [
      {
        title: "Job Board",
        route: "",
      },
    ];
    dispatch(setBreadcrumb(arr));
  }, []);
  const fetchAllJobs = () => {
    dispatch(setLoading(true));
    const payload = {
      page: page,
      limit: 10,
      state: filters.state == "Select" ? "" : filters.state,
      city: filters.city == "Select" ? "" : filters.city,
      expMin: filters.experience?.length > 0 ? filters.experience[0] : 0,
      expMax: filters.experience?.length > 0 ? filters.experience[1] : 0,
      searchKey: filters.searchQuery,
    };
    GetNurseJobList(payload)
      .then((resp) => {
        console.log(resp, "dsfsdf");
        if (page == 1) {
          setJobList(resp.data.list);
        } else {
          setJobList((prev) => [...prev, ...resp.data.list]);
        }
        setHasNextResult(resp.data?.hasResult);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  return jobList?.length > 0 ? (
    <InfiniteScrollWrapper
      handleSetPage={() => setPage((prev) => prev + 1)}
      hasResult={hasNextResult}
    >
      <Grid container spacing={4}>
        {jobList.map((job) => {
          return <PendingJobCard job={job} key={job?._id} />;
        })}
      </Grid>
    </InfiniteScrollWrapper>
  ) : (
    <NoDataFound title={"No Jobs Available"} icon={EmptyIcon} />
  );
}
const labelStyles = {
  fontFamily: "Lato",
  fontSize: {
    xs: "10px",
    sm: "11px",
    md: "12px",
    lg: "12px",
    xl: "12px",
  },
  fontWeight: 400,
  lineHeight: "13.4px",
  textAlign: "left",
  color: "#8592a1",
};

const valueStyles = {
  fontFamily: "Lato",
  fontSize: {
    xs: "14px",
    sm: "15px",
    md: "16px",
    lg: "16px",
    xl: "16px",
  },
  fontWeight: 500,
  lineHeight: "19.2px",
  textAlign: "left",
};
const PendingJobCard = ({ job }) => {
  const navigate = useNavigate();
  return (
    <Grid xs={12} sm={12} md={12} item lg={6} xl={6}>
      <Box
        bgcolor={"#fff"}
        padding={"20px"}
        borderRadius={"25px"}
        boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
        height="100%"
      >
        <Grid container>
          <Grid xs={12} sm={2} md={2} lg={1} xl={1} item>
            <img
              src={job?.hospitalDetails?.hospitalLogo}
              alt="logo"
              width={50}
              height={50}
              style={{ borderRadius: "50%" }}
            />
          </Grid>
          <Grid
            xs={12}
            sm={10}
            md={10}
            lg={11}
            xl={11}
            item
            sx={{
              paddingLeft: {
                xs: 0,
                sm: "10px",
                md: "15px",
                lg: "20px",
                xl: "20px",
              },
              paddingTop: {
                xs: "15px",
                sm: 0,
                md: 0,
                lg: 0,
                xl: 0,
              },
            }}
          >
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      sm: "18px",
                      md: "18px",
                      lg: "20px",
                      xl: "20px",
                    },
                  }}
                  fontWeight={700}
                  lineHeight={"24px"}
                  textAlign={"left"}
                  color={"primary"}
                >
                  {job?.title}
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "14px",
                      lg: "15px",
                      xl: "16px",
                    },
                    paddingTop: {
                      xs: 0,
                      sm: "3px",
                      md: "5px",
                      lg: "7px",
                      xl: "7px",
                    },
                  }}
                  fontWeight={700}
                  lineHeight={"19.2px"}
                  color="#8592a1"
                  textAlign={"left"}
                >
                  {job?.hospitalDetails?.hospitalName}
                </Typography>
              </Box>
              <Chip
                sx={{
                  background: "#ecf3f6",
                }}
                label={
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontSize: {
                          xs: "10px",
                          sm: "11px",
                          md: "12px",
                          lg: "12px",
                          xl: "12px",
                        },
                        fontWeight: 400,
                        lineHeight: "12px",
                        textAlign: "left",
                        color: "#50647a",
                      }}
                    >
                      Start On:
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontSize: {
                          xs: "10px",
                          sm: "12px",
                          md: "14px",
                          lg: "16px",
                          xl: "16px",
                        },
                        fontWeight: {
                          xs: 600,
                          sm: 500,
                          md: 500,
                          lg: 500,
                          xl: 500,
                        },
                        lineHeight: "19.2px",
                        textAlign: "left",
                        color: "#0D2645",
                      }}
                    >
                      &nbsp;
                      {job?.startDate
                        ? format(new Date(job?.startDate), "dd MMM, yyyy")
                        : ""}
                    </Typography>
                  </Box>
                }
                variant="filled"
              />
            </Box>
            <Grid container>
              <Grid
                className="border-right-line"
                sx={{ position: "relative" }}
                xs={12}
                sm={6}
                lg={6}
                xl={4.5}
                md={4}
                item
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  sx={{
                    padding: {
                      xs: "5px",
                      sm: "10px 5px",
                      md: "15px 0",
                      lg: "15px 0",
                      xl: "15px 0",
                    },
                  }}
                >
                  <Typography sx={{ ...labelStyles }}>Shift:</Typography>
                  <Typography
                    sx={{
                      ...valueStyles,
                      whiteSpace: "nowrap",
                      width: "85%",
                      textOverflow: " ellipsis",
                      overflow: "hidden",
                    }}
                    color={"primary"}
                  >
                    &nbsp;
                    {job?.shifts?.map((shift, idx) => {
                      return (
                        getShiftNames(shift) +
                        (job?.shifts?.length !== idx + 1 ? ", " : "")
                      );
                    })}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={6} sm={6} lg={6} xl={4.5} md={4} item>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  sx={{
                    padding: {
                      xs: "5px",
                      sm: "10px 5px",
                      md: "15px 0",
                      lg: "15px 0",
                      xl: "15px 0",
                    },
                  }}
                >
                  <Typography sx={{ ...labelStyles }}>Duration:</Typography>
                  <Typography sx={{ ...valueStyles }} color={"primary"}>
                    &nbsp;{job?.duration} weeks
                  </Typography>
                </Box>
              </Grid>
              {/* <Grid md={4} item> */}
              {/* <Box display={"flex"} alignItems={"center"} padding={"15px 0"}>
                  <Typography sx={{ ...labelStyles }}>
                    Open Positions:
                  </Typography>
                  <Typography sx={{ ...valueStyles }} color={"primary"}>
                    &nbsp;{job?.numberOfOpenings}
                  </Typography>
                </Box> */}
              {/* </Grid> */}
              <Grid
                className="border-right-line"
                sx={{ position: "relative" }}
                xs={6}
                sm={6}
                lg={6}
                xl={4.5}
                md={4}
                item
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  sx={{
                    padding: {
                      xs: "5px",
                      sm: "10px 5px",
                      md: "15px 0",
                      lg: "15px 0",
                      xl: "15px 0",
                    },
                  }}
                >
                  <Typography sx={{ ...labelStyles }}>
                    Exp. Required:
                  </Typography>
                  <Typography sx={{ ...valueStyles }} color={"primary"}>
                    &nbsp;{job?.experience} yrs
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={6} sm={6} lg={6} xl={4.5} md={4} item>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  sx={{
                    padding: {
                      xs: "5px",
                      sm: "10px 5px",
                      md: "15px 0",
                      lg: "15px 0",
                      xl: "15px 0",
                    },
                  }}
                >
                  <Typography sx={{ ...labelStyles }}>
                    Available Positions:
                  </Typography>
                  <Typography sx={{ ...valueStyles }} color={"primary"}>
                    &nbsp;{job?.remainingOpening}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          sx={{
            marginBottom: {
              xs: "10px",
              sm: "10px",
              md: "15px",
              lg: "15px",
              xl: "15px",
            },
          }}
        />
        <Grid container alignItems={"center"}>
          <Grid xs={6} sm={6} lg={6} xl={6} md={6}>
            <Box display={"flex"} alignItems={"center"}>
              <Typography sx={{ ...labelStyles }}>Job Offer:</Typography>
              <Typography sx={{ ...valueStyles }}>
                &nbsp;
                {getFormattedValue(
                  job?.weeklyPay + job?.houseAllowance + job?.mealAllowance
                )}{" "}
                <span style={{ ...labelStyles }}>/wk</span>
              </Typography>
            </Box>
          </Grid>
          <Grid xs={6} sm={6} lg={6} xl={6} md={6} textAlign={"right"} item>
            <Button
              sx={{
                fontSize: {
                  xs: "12px",
                  sm: "14px",
                  md: "14px",
                  lg: "16px",
                  xl: "16px",
                },
                background: "#38879f",
                color: "#fff",
                fontWeight: {
                  xs: 500,
                  sm: 500,
                  md: 600,
                  lg: 600,
                  xl: 600,
                },
                padding: {
                  xs: "5px 20px",
                  sm: "5px 20px",
                  md: "5px 15px",
                  lg: "5px 15px",
                  xl: "5px 15px",
                },
                borderRadius: "50px",
                "&.Mui-disabled": {
                  background: "#adcdd7",
                  color: "#fff",
                },
                "&:hover": {
                  backgroundColor: "#38879f",
                },
              }}
              onClick={() => navigate(`/nurse/dashboard/jobs/${job?._id}`)}
            >
              Apply Job
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
