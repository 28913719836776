import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
export default function CommonModal(props) {
  const {
    open,
    icon,
    title,
    onClose,
    onSubmit,
    button1Text,
    button2Text,
    isSingleButton,
    singleBtnText,
  } = props;
  return (
    <Dialog
      open={open}
      sx={{
        textAlign: "center",
      }}
      PaperProps={{
        sx: {
          width: "360px",
          borderRadius: "25px",
        },
      }}
      onClose={onClose}
    >
      <DialogContent>
        <Box>
          <img src={icon} alt="icon" />
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: 600,
              lineHeight: "28.8px",
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            {title}
          </Typography>
          <Box display={"flex"} justifyContent={"center"}>
            {isSingleButton ? (
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#38879f",
                  color: "#fff",
                  width: "100%",
                  height: "60px",
                  //   marginLeft: "20px",
                  textTransform: "none",
                  fontSize: "18px",
                  fontWeight: 700,
                  borderRadius: "10px",
                  "&:hover": {
                    bgcolor: "#38879f",
                  },
                }}
                onClick={onClose}
              >
                {singleBtnText}
              </Button>
            ) : (
              <>
                <Button
                  // variant="outlined"
                  sx={{
                    color: "#38879f",
                    width: "120px",
                    border: "1px solid #38879f",
                    height: "60px",
                    textTransform: "none",
                    fontSize: "18px",
                    fontWeight: 700,
                    borderRadius: "10px",
                    background: "#fff",
                    "&:hover": {
                      color: "#38879f",
                    },
                  }}
                  onClick={onSubmit}
                >
                  {button1Text}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#38879f",
                    color: "#fff",
                    width: "164px",
                    height: "60px",
                    marginLeft: "20px",
                    textTransform: "none",
                    fontSize: "18px",
                    fontWeight: 700,
                    borderRadius: "10px",
                    "&:hover": {
                      bgcolor: "#38879f",
                    },
                  }}
                  onClick={onClose}
                >
                  {button2Text}
                </Button>
              </>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
