import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  ListItemIcon,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import BackNavigation from "../../../../assets/icons/back-profile.svg";
import BlankStar from "../../../../assets/icons/blank-star.svg";
import ClockIcon from "../../../../assets/icons/clock.svg";
import DeclineIcon from "../../../../assets/icons/decline-count.svg";
import EmptyIcon from "../../../../assets/icons/empty-candidates.svg";
import FilledStar from "../../../../assets/icons/filled-star.svg";
import HalfFilled from "../../../../assets/icons/half-filled.svg";
import ConfirmIcon from "../../../../assets/icons/modal-confirm.svg";
import OfferSentIcon from "../../../../assets/icons/offer-sent.svg";
import PendingIcon from "../../../../assets/icons/pending-count.svg";
import DocIcon from "../../../../assets/icons/policy.svg";
import StarIcon from "../../../../assets/icons/star.svg";
import TickCardIcon from "../../../../assets/icons/tick-card.svg";
import CallIcon from "../../../../assets/icons/user-call.svg";
import EmailIcon from "../../../../assets/icons/user-email.svg";
import { GetRatings } from "../../../../controller/services/common";
import {
  CompleteJob,
  DeclineJob,
  GetAllJobApplications,
  GetJobDetails,
  SendChekrRequest,
  SendJobOffer,
} from "../../../../controller/services/hospital/jobs";
import { setBreadcrumb } from "../../../../redux/slices/breadcrumb_slice";
import {
  setActiveSelectedTab,
  setLoading,
} from "../../../../redux/slices/flags_slice";
import { getFormattedValue, getShiftNames } from "../../../../utils/helper";
import InfiniteScrollWrapper from "../../../common/infinite-scroll";
import CommonModal from "../../../common/modals/commonModal";
import NoDataFound from "../../../common/no-data";
import StyledChip from "../../../common/styled/chip";

const labelStyles = {
  fontFamily: "Lato",
  fontSize: {
    xs: "16px",
    sm: "16px",
    md: "18px",
    lg: "18px",
    xl: "18px",
  },
  fontWeight: 700,
  lineHeight: "21.6px",
  color: "#0D2645",
  padding: "10px 0",
};

const valueStyles = {
  fontFamily: "Lato",
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "16px",
    lg: "16px",
    xl: "16px",
  },
  fontWeight: 400,
  lineHeight: "26px",
  color: "#8b97a6",
  padding: {
    xs: "0 0 10px",
    sm: "0 0 10px",
    md: "10px 0",
    lg: "10px 0",
    xl: "10px 0",
  },
};

const userMenus = [
  {
    index: 0,
    title: "Job Description",
    Icon: DocIcon,
  },
  {
    index: 1,
    title: "Pending",
    Icon: PendingIcon,
    key: "pendingCount",
  },
  {
    index: 2,
    title: "Declined",
    Icon: DeclineIcon,
    key: "declinedCount",
  },
  {
    index: 3,
    title: "Offer Sent",
    Icon: OfferSentIcon,
    key: "offerSentCount",
  },
];

const styles = {
  color: "#0d2645",
  fontSize: {
    xs: "13px",
    sm: "14px",
    md: "14px",
    lg: "15px",
    xl: "15px",
  },
  lineHeight: "18.5px",
  fontWeight: 500,
  margin: {
    xs: 0,
    sm: "15px 0 0",
    md: "15px 0 0",
    lg: "15px 0",
    xl: "15px",
  },
  padding: {
    xs: "0 10px",
    sm: "10px 20px",
    md: "10px",
    lg: "10px 20px",
    xl: "10px 20px",
  },
  borderBottom: {
    xs: "5px solid transparent",
    sm: 0,
    md: 0,
    lg: 0,
    xl: 0,
  },
  width: {
    xs: "48%",
    sm: "auto",
    md: "auto",
    lg: "auto",
    xl: "auto",
  },
  borderRadius: {
    xs: 0,
    sm: "10px",
    md: "10px",
    lg: "10px",
    xl: "10px",
  },
  float: {
    xs: "left",
    sm: "none",
    md: "none",
    lg: "none",
    xl: "none",
  },
};

export default function ViewActiveJob() {
  const params = useParams();
  const dispatch = useDispatch();
  const [jobData, setJobData] = useState(null);
  const [allData, setAllData] = useState(null);
  const selected = useSelector((state) => state.flags?.selectedActiveTab);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [showRatings, setShowRatings] = useState(false);
  useEffect(() => {
    fetchJobDetails();
  }, [selected]);
  useEffect(() => {
    const arr = [
      {
        title: "Active Jobs",
        route: "/hospital/active-jobs",
      },
      {
        title: jobData?.title,
        route: "",
      },
    ];
    if (jobData?.title) {
      dispatch(setBreadcrumb(arr));
    }
  }, [jobData?.title]);
  const fetchJobDetails = () => {
    dispatch(setLoading(true));
    GetJobDetails(params.id)
      .then((resp) => {
        setJobData(resp.data.job);
        setAllData(resp.data);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const handleClickMenu = (item) => {
    dispatch(setActiveSelectedTab(item.index));
  };

  const getStatusText = (type) => {
    let statusText;
    if (type === "offerSent") {
      statusText = "Offer Sent";
    } else if (type === "declined") {
      statusText = "Declined";
    } else {
      statusText = "Pending";
    }
    return statusText;
  };

  const getSelectedComponent = () => {
    if (selected === 0) {
      return <JobDescription jobData={jobData} />;
    } else if (selected === 1) {
      return (
        <PendingCandidates
          jobData={jobData}
          type="pending"
          handleShowUser={(data) => setShowUserDetails(data)}
          selected={selected}
        />
      );
    } else if (selected === 2) {
      return (
        <PendingCandidates
          jobData={jobData}
          type="declined"
          handleShowUser={(data) => setShowUserDetails(data)}
          selected={selected}
        />
      );
    } else {
      return (
        <PendingCandidates
          jobData={jobData}
          type="offerSent"
          handleShowUser={(data) => setShowUserDetails(data)}
          selected={selected}
        />
      );
    }
  };
  if (jobData) {
    return (
      <Box
        sx={{
          padding: {
            xs: "20px 15px",
            sm: "20px 15px",
            md: "20px",
            lg: "30px",
            xl: "30px",
          },
        }}
        maxHeight={"90vh"}
        overflow={"auto"}
      >
        {showRatings ? (
          <RatingsAndReviewsListHos
            newUserDetails={showUserDetails}
            handleHideRatings={() => {
              setShowRatings(false);
              const arr = [
                {
                  title: "Active Jobs",
                  route: "/hospital/active-jobs",
                },
                {
                  title: jobData?.title,
                  route: `/hospital/active-jobs/${jobData?._id}`,
                  func: () => {
                    setShowUserDetails(false);
                  },
                },
                {
                  title: getStatusText(selected),
                  route: `/hospital/active-jobs/${jobData?._id}`,
                  func: () => {
                    setShowUserDetails(false);
                  },
                },
                {
                  title:
                    showUserDetails?.userData?.firstName +
                    " " +
                    showUserDetails?.userData?.lastName,
                  route: "",
                },
              ];
              if (jobData?.title) {
                dispatch(setBreadcrumb(arr));
              }
            }}
            handleShowUser={() => setShowUserDetails(false)}
            jobData={jobData}
            type={selected}
          />
        ) : (
          <>
            <Box
              position={"relative"}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                sx={{
                  width: {
                    xs: "100%",
                    sm: "auto",
                    md: "auto",
                    lg: "auto",
                    xl: "auto",
                  },
                  marginBottom: {
                    xs: "10px",
                    sm: "20px",
                    md: "20px",
                    lg: "20px",
                    xl: "20px",
                  },
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                    md: "row",
                    lg: "row",
                    xl: "row",
                  },
                }}
              >
                <Typography
                  sx={{
                    textAlign: "left",
                    marginBottom: {
                      xs: "5px",
                      sm: "10px",
                      md: 0,
                      lg: 0,
                      xl: 0,
                    },
                    fontSize: {
                      xs: "16px",
                      sm: "18px",
                      md: "22px",
                      lg: "24px",
                      xl: "24px",
                    },
                    fontWeight: 700,
                    lineHeight: "28.8px",
                    color: "#0D2645",
                    marginRight: {
                      xs: 0,
                      sm: "10px",
                      md: "10px",
                      lg: "15px",
                      xl: "15px",
                    },
                  }}
                >
                  {jobData?.title}
                </Typography>
                <Chip
                  sx={{
                    background: "#ecf3f6",
                    width: {
                      xs: "165px",
                      sm: "180px",
                      md: "auto",
                      lg: "auto",
                      xl: "auto",
                    },
                  }}
                  label={
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "10px",
                            sm: "10px",
                            md: "11px",
                            lg: "12px",
                            xl: "12px",
                          },
                          fontWeight: 400,
                          lineHeight: "12px",
                          textAlign: "left",
                          color: "#50647a",
                        }}
                      >
                        Start Date:
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "14px",
                            sm: "15px",
                            md: "16px",
                            lg: "16px",
                            xl: "16px",
                          },
                          fontWeight: 600,
                          lineHeight: "19.2px",
                          textAlign: "left",
                          color: "#0D2645",
                        }}
                      >
                        &nbsp;
                        {jobData?.startDate
                          ? format(new Date(jobData?.startDate), "dd MMM, yyyy")
                          : ""}
                      </Typography>
                    </Box>
                  }
                  variant="filled"
                />
              </Box>
              <Box
                sx={{
                  position: {
                    xs: "absolute",
                    sm: "relative",
                    md: "relative",
                    lg: "relative",
                    xl: "relative",
                  },
                  right: {
                    xs: "-5px",
                    sm: 0,
                    md: 0,
                    lg: 0,
                    xl: 0,
                  },
                  top: {
                    xs: "-10px",
                    sm: 0,
                    md: 0,
                    lg: 0,
                    xl: 0,
                  },
                }}
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    background: "#F19000",
                    color: "#fff",
                    border: "1px solid #F19000",
                    padding: "3px",
                    borderRadius: {
                      xs: "6px",
                      sm: "8px",
                      md: "12px",
                      lg: "16px",
                      xl: "16px",
                    },
                  }}
                  label={"Available Positions:"}
                  value={jobData?.remainingOpening}
                />
              </Box>
            </Box>
            <Grid container>
              <Grid
                sx={{ padding: "5px 2px" }}
                xs={6}
                sm={4}
                md={4}
                lg={3}
                xl={2}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Job Offer:"}
                  value={`${getFormattedValue(
                    jobData?.weeklyPay +
                      jobData?.houseAllowance +
                      jobData?.mealAllowance
                  )} /wk`}
                />
              </Grid>
              <Grid
                sx={{
                  padding: "5px 2px",
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    xl: "block",
                    lg: "block",
                  },
                }}
                xs={12}
                sm={6}
                md={6}
                lg={5}
                xl={3}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Shift:"}
                  value={jobData?.shifts?.map((shift, idx) => {
                    return (
                      <Box
                        sx={{
                          display: "inline-block",
                          width: "auto !important",
                        }}
                        key={shift}
                      >
                        {getShiftNames(shift) +
                          (jobData?.shifts?.length !== idx + 1 ? ", " : "")}
                      </Box>
                    );
                  })}
                />
              </Grid>
              <Grid
                sx={{ padding: "5px 2px" }}
                xs={6}
                sm={6}
                md={6}
                lg={3}
                xl={2}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Duration:"}
                  value={`${jobData?.duration} Weeks`}
                />
              </Grid>
              <Grid
                sx={{
                  padding: "5px 2px",
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    xl: "none",
                    lg: "none",
                  },
                }}
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={3}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Shift:"}
                  value={jobData?.shifts?.map((shift, idx) => {
                    return (
                      <Box
                        sx={{
                          display: "inline-block",
                          width: "auto !important",
                        }}
                        key={shift}
                      >
                        {getShiftNames(shift) +
                          (jobData?.shifts?.length !== idx + 1 ? ", " : "")}
                      </Box>
                    );
                  })}
                />
              </Grid>
              <Grid
                sx={{ padding: "5px 2px" }}
                xs={6}
                sm={6}
                md={6}
                lg={3}
                xl={2}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Exp. Required:"}
                  value={`${jobData?.experience} Years`}
                />
              </Grid>
              <Grid
                sx={{ padding: "5px 2px" }}
                xs={6}
                sm={4}
                md={4}
                lg={3}
                xl={2}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Open Positions:"}
                  value={jobData?.numberOfOpenings}
                />
              </Grid>
              {/* <Grid
                sx={{ padding: "5px 2px" }}
                xs={6}
                sm={4}
                md={4}
                lg={3}
                xl={2}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Available Positions:"}
                  value={jobData?.remainingOpening}
                />
              </Grid> */}
            </Grid>
            <Divider
              sx={{
                margin: {
                  xs: "10px 0",
                  sm: "10px 0",
                  md: "20px 0",
                  lg: "20px 0",
                  xl: "20px 0",
                },
              }}
            />
            {showUserDetails ? (
              <Grid container item>
                <Grid md={12}>
                  <ViewApplicantDetails
                    jobData={jobData}
                    type={selected}
                    handleShowRatings={(flag) => setShowRatings(flag)}
                    userDetails={showUserDetails}
                    closeShowDetails={() => {
                      setShowUserDetails(null);
                      fetchJobDetails();
                      const arr = [
                        {
                          title: "Active Jobs",
                          route: "/hospital/active-jobs",
                        },
                        {
                          title: jobData?.title,
                          route: `/hospital/active-jobs/${jobData?._id}`,
                          func: () => setShowUserDetails(null),
                        },
                      ];
                      if (jobData?.title) {
                        dispatch(setBreadcrumb(arr));
                      }
                    }}
                    handleSelectTab={(num) => {
                      setShowUserDetails(null);
                      dispatch(setActiveSelectedTab(num));
                    }}
                    selected={selected}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container item columnSpacing={3}>
                <Grid xs={12} sm={4} md={4} lg={3} xl={3} item>
                  <MenuList
                    sx={{
                      padding: {
                        xs: "0 !important",
                        sm: "10px !important",
                        md: "10px !important",
                        lg: "10px !important",
                        xl: "10px !important",
                      },
                      display: {
                        xs: "flex",
                        sm: "block",
                        md: "block",
                        lg: "block",
                        xl: "block",
                      },
                      borderRadius: 0,
                      borderBottom: {
                        xs: "1px solid rgba(0, 0, 0, 0.12)",
                        sm: 0,
                        md: 0,
                        lg: 0,
                        xl: 0,
                      },
                      overflow: {
                        xs: "auto",
                        sm: "initial",
                        md: "initial",
                        lg: "initial",
                        xl: "initial",
                      },
                    }}
                    variant="selectedMenu"
                  >
                    {userMenus.map((item) => {
                      const isActive = item.index === selected;
                      return (
                        <MenuItem
                          key={item.index}
                          onClick={() => handleClickMenu(item)}
                          sx={{
                            ...styles,
                            ...(isActive && {
                              color: {
                                xs: "#38879F",
                                sm: "#fff",
                                md: "#fff",
                                lg: "#fff",
                                xl: "#fff",
                              },
                              background: {
                                xs: "transparent",
                                sm: "#38879f",
                                md: "#38879f",
                                lg: "#38879f",
                                xl: "#38879f",
                              },
                              padding: {
                                xs: "0 10px",
                                sm: "10px 20px",
                                md: "10px",
                                lg: "10px",
                                xl: "10px 20px",
                              },
                              borderBottom: {
                                xs: "5px solid #38879f",
                                sm: 0,
                                md: 0,
                                lg: 0,
                                xl: 0,
                              },
                              borderRadius: {
                                xs: 0,
                                sm: "10px",
                                md: "10px",
                                lg: "10px",
                                xl: "10px",
                              },
                              fontWeight: 700,
                            }),
                            "&:hover": {
                              color: "#0d2645",
                              padding: {
                                xs: "0 10px",
                                sm: "10px 20px",
                                md: "10px",
                                lg: "10px",
                                xl: "10px 20px",
                              },
                              borderBottom: {
                                xs: "5px solid #38879F",
                                sm: 0,
                                md: 0,
                                lg: 0,
                                xl: 0,
                              },
                            },
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              display: {
                                xs: "none !important",
                                sm: "inline-flex !important",
                                md: "inline-flex !important",
                                lg: "inline-flex !important",
                                xl: "inline-flex !important",
                              },
                            }}
                          >
                            <img src={item.Icon} alt="app-icon" />
                          </ListItemIcon>
                          <Typography variant="inherit">
                            {item.title +
                              `${
                                item.key ? ` (${allData?.[item.key] || 0})` : ""
                              }`}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </Grid>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{
                    mr: "-1px",
                    borderColor: "#dcefe7",
                    borderRadius: "12px",
                  }}
                />
                <Grid
                  xl={9}
                  lg={9}
                  md={8}
                  xs={12}
                  sm={8}
                  sx={{
                    height: {
                      xs: "auto",
                      sm: "auto",
                      md: "auto",
                      lg: "calc(100vh - 380px)",
                      xl: "calc(100vh - 380px)",
                    },
                    overflow: "auto",
                  }}
                  item
                >
                  {getSelectedComponent()}
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Box>
    );
  }
  return <></>;
}

const JobDescription = ({ jobData }) => {
  const userData = useSelector((state) => state.user.userData);
  return (
    <>
      {" "}
      <Box textAlign={"left"}>
        <Typography sx={{ ...labelStyles }}>Job Location</Typography>
        <Typography sx={{ ...valueStyles }}>
          {userData?.address}, {userData?.city}, {userData?.state}
        </Typography>
      </Box>
      <Box textAlign={"left"}>
        <Typography sx={{ ...labelStyles }}>Job Description</Typography>
        <Typography sx={{ ...valueStyles }}>{jobData?.description}</Typography>
      </Box>
      <Box textAlign={"left"} bgcolor={"#fbfdfd"}>
        <Typography sx={{ ...labelStyles, marginBottom: "10px" }}>
          Paycheck
        </Typography>
        <Box
          border={"1.5px solid #99c1cd"}
          padding={"20px"}
          borderRadius={"20px"}
        >
          <Box
            padding={"0 0 20px 0"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: "14px",
                  sm: "14px",
                  md: "16px",
                  lg: "16px",
                  xl: "16px",
                },
                fontWeight: 500,
                lineHeight: "19.2px",
                color: "#54677c",
              }}
            >
              Weekly Earnings
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "16px",
                  sm: "16px",
                  md: "18px",
                  lg: "18px",
                  xl: "18px",
                },
                fontWeight: 700,
                lineHeight: "21.6px",
                color: "#0D2645",
              }}
            >
              {getFormattedValue(jobData?.weeklyPay)}
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "16.8px",
                  color: "#8b97a6",
                }}
              >
                /wk
              </span>
            </Typography>
          </Box>
          <Divider />
          <Box>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              padding={"20px 0 5px 0"}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "14px",
                    sm: "14px",
                    md: "16px",
                    lg: "16px",
                    xl: "16px",
                  },
                  fontWeight: 500,
                  lineHeight: "19.2px",
                  color: "#54677c",
                }}
              >
                Travel Per Diems
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: "16px",
                    sm: "16px",
                    md: "18px",
                    lg: "18px",
                    xl: "18px",
                  },
                  fontWeight: 700,
                  lineHeight: "21.6px",
                  color: "#0D2645",
                }}
              >
                {getFormattedValue(
                  jobData?.houseAllowance + jobData?.mealAllowance
                )}
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "16.8px",
                    color: "#8b97a6",
                  }}
                >
                  /wk
                </span>
              </Typography>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              padding={"5 0"}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "19.2px",
                  color: "#54677c",
                }}
              >
                Meals
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "21.6px",
                  color: "#0D2645",
                }}
              >
                {getFormattedValue(jobData?.mealAllowance)}
              </Typography>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              padding={"5px 0 20px 0"}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "19.2px",
                  color: "#54677c",
                }}
              >
                Housing
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "21.6px",
                  color: "#0D2645",
                }}
              >
                {getFormattedValue(jobData?.houseAllowance)}
              </Typography>
            </Box>
            <Divider />
            <Box
              paddingTop={"20px"}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "14px",
                    sm: "14px",
                    md: "16px",
                    lg: "16px",
                    xl: "16px",
                  },
                  fontWeight: 500,
                  lineHeight: "19.2px",
                  color: "#54677c",
                }}
              >
                Total Weekly Payment
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: "16px",
                    sm: "16px",
                    md: "18px",
                    lg: "18px",
                    xl: "18px",
                  },
                  fontWeight: 700,
                  lineHeight: "21.6px",
                  color: "#0D2645",
                }}
              >
                {getFormattedValue(
                  jobData?.weeklyPay +
                    jobData?.houseAllowance +
                    jobData?.mealAllowance
                )}
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "16.8px",
                    color: "#8b97a6",
                  }}
                >
                  /wk
                </span>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const PendingCandidates = ({ jobData, type, handleShowUser, selected }) => {
  const dispatch = useDispatch();
  const [candidatesList, setCandidatesList] = useState([]);
  const [noDataTitle, setNoDataTitle] = useState("");
  const fetchApplicationList = () => {
    dispatch(setLoading(true));
    const payload = {
      jobId: jobData?._id,
      status: type, //'pending', 'declined', 'offerSent'
      page: 1,
      limit: 10,
    };
    GetAllJobApplications(payload)
      .then((resp) => {
        setCandidatesList(resp.data.list);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (jobData?._id) {
      fetchApplicationList();
    }
    if (type == "pending") {
      setNoDataTitle("No Pending Applications");
    } else if (type == "declined") {
      setNoDataTitle("No Declined Applications");
    } else {
      setNoDataTitle("No Offers Sent");
    }
  }, [jobData?._id, type]);

  useEffect(() => {
    const arr = [
      {
        title: "Active Jobs",
        route: "/hospital/active-jobs",
      },
      {
        title: jobData?.title,
        route: "",
      },
    ];
    if (jobData?.title) {
      dispatch(setBreadcrumb(arr));
    }
  }, [jobData?.title]);

  const getOfferStatusText = (candidate) => {
    let offerStatus = "";
    if (candidate?.offerStatus === "pending") {
      offerStatus = "Pending";
    } else if (candidate?.offerStatus === "accepted") {
      offerStatus = "Accepted";
    } else if (candidate?.offerStatus === "declined") {
      offerStatus = "Declined";
    }
    return offerStatus;
  };

  const statusColorMap = {
    pending: "#F19000",
    declined: "#da0000",
    accepted: "#00b81d",
  };
  return candidatesList?.length > 0 ? (
    <Box
      sx={{
        marginTop: {
          xs: "20px",
          sm: "20px",
          md: 0,
          lg: 0,
          xl: 0,
        },
      }}
    >
      {candidatesList?.map((candidate) => {
        return (
          <Box
            className={"card-adjust-according-device"}
            sx={{ width: "201px", margin: "0 10px 20px", float: "left" }}
            key={candidate?._id}
          >
            <Box
              key={candidate?._id}
              bgcolor={"#f5f9fa"}
              border={"1px solid #bdd7df"}
              padding={"10px"}
              borderRadius={"25px"}
              onClick={() => {
                const arr = [
                  {
                    title: "Active Jobs",
                    route: "/hospital/active-jobs",
                  },
                  {
                    title: jobData?.title,
                    route: `/hospital/active-jobs/${jobData?._id}`,
                    func: () => {
                      handleShowUser(false);
                    },
                  },
                  {
                    title:
                      type == "offerSent"
                        ? "Offer Sent"
                        : type == "declined"
                        ? "Declined"
                        : "Pending",
                    route: `/hospital/active-jobs/${jobData?._id}`,
                    func: () => {
                      handleShowUser(false);
                    },
                  },
                  {
                    title:
                      candidate?.userData?.firstName +
                      " " +
                      candidate?.userData?.lastName,
                    route: "",
                  },
                ];
                if (jobData?.title) {
                  dispatch(setBreadcrumb(arr));
                }
                handleShowUser(candidate);
              }}
              position={"relative"}
              sx={{ width: "100%", cursor: "pointer" }}
            >
              <Box paddingTop={"10px"}>
                {type === "offerSent" && (
                  <Chip
                    label={getOfferStatusText(candidate)}
                    sx={{
                      bgcolor: statusColorMap[candidate?.offerStatus],
                      color: "#fff",
                      marginTop: "5px",
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "19.2px",
                      position: "absolute",
                      top: 7,
                      left: 0,
                      right: 0,
                      width: "100px",
                      margin: "auto",
                      zIndex: 2,
                    }}
                  />
                )}
                <Box
                  sx={{
                    position: "relative",
                    zIndex: 1,
                    paddingBottom: "15px",
                  }}
                >
                  <img
                    src={candidate?.userData?.profilePicture}
                    alt="applicant-img"
                    height={161}
                    width={161}
                    style={{ borderRadius: "25px" }}
                    className="jobs-profile-candidate-img"
                  />
                  <Chip
                    sx={{
                      bgcolor: "#ffffff",
                      position: "absolute",
                      bottom: "10px",
                      left: 0,
                      right: 0,
                      margin: "auto",
                      width: {
                        xs: "50%",
                        sm: "50%",
                        md: "40%",
                        lg: "40%",
                        xl: "35%",
                      },
                    }}
                    label={
                      <Box display={"flex"} justifyContent={"space-evenly"}>
                        <img src={StarIcon} alt="star" />
                        <Typography
                          color="#0d2645"
                          fontSize={"14px"}
                          lineHeight={"21px"}
                          fontWeight={500}
                          marginLeft={"5px"}
                        >
                          {candidate?.userData?.rating || 0}
                        </Typography>
                      </Box>
                    }
                  />
                </Box>
                <Typography
                  fontSize={"17px"}
                  fontWeight={700}
                  lineHeight={"18px"}
                  color="#0d2645"
                  marginBottom={"5px"}
                >
                  {candidate?.userData?.firstName}{" "}
                  {candidate?.userData?.lastName}
                </Typography>
                <Typography
                  fontSize={"17px"}
                  fontWeight={700}
                  lineHeight={"18px"}
                  color="#0d2645"
                  marginBottom={"5px"}
                >
                  {candidate?.userData?.experience}{" "}
                  <span
                    style={{
                      fontSize: "16px",
                      lineHeight: "20.8px",
                      fontWeight: 500,
                      color: "#808f9f",
                    }}
                  >
                    yrs
                  </span>
                </Typography>
                <Typography
                  fontSize={"15px"}
                  fontWeight={700}
                  lineHeight={"18px"}
                  color="#0d2645"
                  marginBottom={"10px"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      lineHeight: "20.8px",
                      fontWeight: 500,
                    }}
                  >
                    Applied on:&nbsp;
                  </span>
                  {format(new Date(candidate?.createdAt), "MMM dd")}
                </Typography>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  ) : (
    <NoDataFound title={noDataTitle} icon={EmptyIcon} height={"50vh"} />
    // <Box
    //   width={"100%"}
    //   //   bgcolor={"#fff"}
    //   height={"50vh"}
    //   display={"flex"}
    //   flexDirection={"column"}
    //   justifyContent={"center"}
    //   alignItems={"center"}
    //   sx={{
    //     borderBottomRightRadius: "25px",
    //     borderBottomLeftRadius: "25px",
    //   }}
    // >
    //   <img src={EmptyIcon} alt="empty-icon" />
    //   <Typography
    //     textAlign={"center"}
    //     sx={{
    //       fontSize: "20px",
    //       fontWeight: 600,
    //       lineHeight: "24px",
    //       color: "#0D2645",
    //       marginTop: "20px",
    //     }}
    //   >
    //     {noDataTitle}
    //   </Typography>
    // </Box>
  );
};

const ViewApplicantDetails = ({
  jobData,
  type,
  closeShowDetails,
  handleShowRatings,
  userDetails,
  handleSelectTab,
  selected,
}) => {
  const dispatch = useDispatch();
  const [declineModalVisible, setDeclineModalVisible] = useState(false);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [completeModalVisible, setCompleteModalVisible] = useState(false);
  const handleSendOffer = () => {
    dispatch(setLoading(true));
    SendJobOffer(userDetails?._id)
      .then((resp) => {
        setSuccessModalVisible(true);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const handleDeclineOffer = () => {
    dispatch(setLoading(true));
    DeclineJob(userDetails?._id)
      .then((resp) => {
        setDeclineModalVisible(false);
        toast.success(resp.message);
        handleSelectTab(2);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const handleChekrRequest = () => {
    dispatch(setLoading(true));
    const payload = {
      jobId: jobData?._id,
      userId: userDetails?.userId,
    };
    SendChekrRequest(payload)
      .then((resp) => {
        toast.success(resp.message);
        handleSelectTab(3);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const handleCompleteJob = () => {
    dispatch(setLoading(true));
    CompleteJob(userDetails?._id)
      .then((resp) => {
        setCompleteModalVisible(false);
        toast.success(resp.message);
        handleSelectTab(0);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const getCheckrStatus = () => {
    const checkrStatus = userDetails?.checkrData?.checkrStatus;
    let onboardStatus = "";
    if (checkrStatus === "pending") {
      onboardStatus = "Onboard Pending";
    } else if (checkrStatus === "clear" || checkrStatus === "eligible") {
      onboardStatus = "Onboard Complete";
    } else if (checkrStatus === "rejected") {
      onboardStatus = "Onboard Failed";
    }
    return onboardStatus;
  };

  const getCheckrStatusBg = () => {
    const checkrStatus = userDetails?.checkrData?.checkrStatus;
    let color = "";
    if (checkrStatus === "pending") {
      color = "#F19000";
    } else if (checkrStatus === "rejected") {
      color = "#da0000";
    } else if (checkrStatus === "clear" || checkrStatus === "eligible") {
      color = "#00b81d";
    }
    return color;
  };

  const getChipStatusBg = () => {
    const status = userDetails?.[type === 3 ? "offerStatus" : "status"];
    let color = "";
    if (status === "pending") {
      color = "#F19000";
    } else if (status === "declined") {
      color = "#da0000";
    } else if (status === "accepted") {
      color = "#00b81d";
    }
    return color;
  };

  const getChipStatusLabel = () => {
    const status = userDetails?.[type === 3 ? "offerStatus" : "status"];
    let result = "";
    if (status === "pending") {
      result = "Pending";
    } else if (status === "accepted") {
      result = "Accepted";
    } else if (status === "declined") {
      result = "Declined";
    }
    return result;
  };
  return (
    <Box textAlign={"left"}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box>
          <Typography
            display={"flex"}
            alignItems={"center"}
            marginBottom={"20px"}
            onClick={closeShowDetails}
            sx={{
              cursor: "pointer",
            }}
          >
            <img src={BackNavigation} alt="back" />
            <span
              style={{
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "19.2px",
                color: "#38879F",
                marginLeft: "5px",
              }}
            >
              Back
            </span>
          </Typography>
        </Box>
        {userDetails?.offerStatus == "accepted" &&
          userDetails?.checkrData?.checkrStatus && (
            <Chip
              label={getCheckrStatus()}
              sx={{
                background: getCheckrStatusBg(),
                color: "#fff",
                marginTop: "5px",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "19.2px",
              }}
            />
          )}
      </Box>
      <Box
        sx={{
          height: "calc(100vh - 490px)",
          overflow: "auto",
        }}
      >
        <Box display={"flex"} alignItems={"center"}>
          <img
            alt={" "}
            src={userDetails?.userData?.profilePicture}
            width={132}
            style={{ borderRadius: "25px" }}
          />
          <Box marginLeft={"20px"}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "28.8px",
                color: "#0d2645",
              }}
            >
              {userDetails?.userData?.firstName}{" "}
              {userDetails?.userData?.lastName}
            </Typography>
            <Box
              display={"flex"}
              justifyContent={"space-evenly"}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                if (userDetails?.userData?.rating > 0) {
                  handleShowRatings(true);
                }
              }}
            >
              <img src={StarIcon} alt="star" />
              <Typography
                color="#0d2645"
                fontSize={"14px"}
                lineHeight={"21px"}
                fontWeight={500}
                marginLeft={"5px"}
              >
                {`${userDetails?.userData?.rating} (${
                  userDetails?.userData?.ratingCount
                } ${
                  userDetails?.userData?.ratingCount > 1 ? "reviews" : "review"
                })`}
              </Typography>
            </Box>
            <Chip
              label={getChipStatusLabel()}
              sx={{
                bgcolor: getChipStatusBg(),
                color: "#fff",
                marginTop: "5px",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "19.2px",
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: {
              xs: "block",
              sm: "block",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
            width: {
              xs: "100%",
              sm: "100%",
              md: "50%",
              lg: "50%",
              xl: "45%",
            },
          }}
          marginTop={"20px"}
          justifyContent={"space-between"}
        >
          <Chip
            sx={{
              // ...props.styles,
              height: "35px",
              marginBottom: {
                xs: "10px",
                sm: "10px",
                md: 0,
                lg: 0,
                xl: 0,
              },
              marginRight: {
                xs: "10px",
                sm: "10px",
                md: 0,
                lg: 0,
                xl: 0,
              },
            }}
            variant={"outlined"}
            label={
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <img alt="" src={ClockIcon} />
                <Typography
                  sx={{
                    fontFamily: "Lato",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "19.2px",
                    color: "#0D2645",
                    paddingLeft: "5px",
                  }}
                >
                  {userDetails?.userData?.experience} Years Experience
                </Typography>
              </Box>
            }
          />
          <Chip
            sx={{
              // ...props.styles,
              height: "35px",
              marginBottom: {
                xs: "10px",
                sm: "10px",
                md: 0,
                lg: 0,
                xl: 0,
              },
            }}
            variant={"outlined"}
            label={
              <Link
                to={`tel:${
                  userDetails?.userData?.countryCode +
                  userDetails?.userData?.phone
                }`}
                style={{ textDecoration: "none" }}
              >
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <img alt={" "} src={CallIcon} />
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "19.2px",
                      color: "#0D2645",
                      paddingLeft: "5px",
                    }}
                  >
                    +
                    {userDetails?.userData?.countryCode +
                      "-" +
                      userDetails?.userData?.phone}
                  </Typography>
                </Box>
              </Link>
            }
          />
          <Chip
            sx={{
              // ...props.styles,
              height: "35px",
              marginBottom: {
                xs: "10px",
                sm: "10px",
                md: 0,
                lg: 0,
                xl: 0,
              },
              marginRight: {
                xs: "10px",
                sm: "10px",
                md: 0,
                lg: 0,
                xl: 0,
              },
            }}
            variant={"outlined"}
            label={
              <Link
                to={`mailto:${userDetails?.userData?.email}`}
                style={{ textDecoration: "none" }}
              >
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <img src={EmailIcon} />
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "19.2px",
                      color: "#0D2645",
                      paddingLeft: "5px",
                    }}
                  >
                    {userDetails?.userData?.email}
                  </Typography>
                </Box>
              </Link>
            }
          />
        </Box>
        <Box margin={"20px 0"}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 400,
              lineHeight: "21.6px",
              color: "#0d2645",
              marginBottom: "10px",
            }}
          >
            Profile Description
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "26px",
              color: "#808f9f",
            }}
          >
            {userDetails?.userData?.bio}
          </Typography>
        </Box>
        {userDetails?.userData?.resume && (
          <Link to={userDetails?.userData?.resume} target="_blank">
            <Button
              sx={{
                height: "55px",
                width: {
                  xs: "100%",
                  sm: "70%",
                  md: "50%",
                  lg: "20%",
                  xl: "11%",
                },
                marginTop: "15px",
                background: "#38879f",
                color: "#fff",
                fontWeight: 600,
                borderRadius: "10px",
                "&.Mui-disabled": {
                  background: "#adcdd7",
                  color: "#fff",
                },
                "&:hover": {
                  backgroundColor: "#38879f",
                },
              }}
            >
              View Resume
            </Button>
          </Link>
        )}
      </Box>
      {userDetails?.status == "declined" && (
        <Box textAlign={"right"}>
          <Button
            sx={{
              width: "22%",
              height: "55px",
              marginTop: "15px",
              background: "#38879f",
              color: "#fff",
              fontWeight: 600,
              borderRadius: "10px",
              "&.Mui-disabled": {
                background: "#adcdd7",
                color: "#fff",
              },
              "&:hover": {
                backgroundColor: "#38879f",
              },
            }}
            disabled={jobData?.remainingOpening == 0}
            onClick={handleSendOffer}
          >
            Re-send Offer
          </Button>
        </Box>
      )}
      {selected == 3 && userDetails?.offerStatus == "pending" ? (
        <Box textAlign={"right"}>
          <Button
            sx={{
              width: "20%",
              height: "55px",
              marginTop: "15px",
              color: "red",
              border: "1px solid red",
              fontWeight: 600,
              borderRadius: "10px",
              "&.Mui-disabled": {
                background: "#adcdd7",
                color: "red",
              },
              "&:hover": {
                backgroundColor: "#fff",
                border: "1px solid red",
              },
            }}
            variant="outlined"
            onClick={() => setCancelModalVisible(true)}
          >
            Cancel Offer
          </Button>
        </Box>
      ) : selected == 3 &&
        userDetails?.offerStatus == "accepted" &&
        !userDetails?.isCompleted ? (
        !userDetails?.checkrData?._id ||
        userDetails?.checkrData?.checkrStatus == "expired" ||
        userDetails?.checkrData?.checkrStatus == "rejected" ? (
          <Box textAlign={"right"}>
            <Button
              sx={{
                height: "55px",
                marginTop: "15px",
                background: "#38879f",
                color: "#fff",
                fontWeight: 600,
                borderRadius: "10px",
                "&.Mui-disabled": {
                  background: "#adcdd7",
                  color: "#fff",
                },
                "&:hover": {
                  backgroundColor: "#38879f",
                },
              }}
              onClick={handleChekrRequest}
            >
              Send Verification
            </Button>
          </Box>
        ) : (
          (userDetails?.checkrData?.checkrStatus == "clear" ||
            userDetails?.checkrData?.checkrStatus == "eligible") && (
            <Box textAlign={"right"}>
              <Button
                sx={{
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  height: "55px",
                  marginTop: "15px",
                  background: "#38879f",
                  color: "#fff",
                  fontWeight: 600,
                  borderRadius: "10px",
                  "&.Mui-disabled": {
                    background: "#adcdd7",
                    color: "#fff",
                  },
                  "&:hover": {
                    backgroundColor: "#38879f",
                  },
                }}
                onClick={() => setCompleteModalVisible(true)}
              >
                Mark as Completed
              </Button>
            </Box>
          )
        )
      ) : selected == 1 ? (
        <Box textAlign={"right"}>
          <Button
            sx={{
              width: "22%",
              height: "55px",
              marginTop: "15px",
              color: "red",
              border: "1px solid red",
              fontWeight: 600,
              borderRadius: "10px",
              marginRight: "20px",
              "&.Mui-disabled": {
                background: "#adcdd7",
                color: "red",
              },
              "&:hover": {
                backgroundColor: "#fff",
                border: "1px solid red",
              },
            }}
            variant="outlined"
            onClick={() => setDeclineModalVisible(true)}
          >
            Decline
          </Button>
          <Button
            sx={{
              width: "22%",
              height: "55px",
              marginTop: "15px",
              background: "#38879f",
              color: "#fff",
              fontWeight: 600,
              borderRadius: "10px",
              "&.Mui-disabled": {
                background: "#adcdd7",
                color: "#fff",
              },
              "&:hover": {
                backgroundColor: "#38879f",
              },
            }}
            onClick={handleSendOffer}
            disabled={jobData?.remainingOpening == 0}
          >
            Send Offer
          </Button>
        </Box>
      ) : null}
      <CommonModal
        title="Are you sure you want to
    complete this Job?"
        open={completeModalVisible}
        icon={ConfirmIcon}
        onSubmit={handleCompleteJob}
        onClose={() => setCompleteModalVisible(false)}
        button1Text={"Yes"}
        button2Text={"No"}
      />
      <CommonModal
        title="Are you sure you want to
    decline this Application?"
        open={declineModalVisible}
        icon={ConfirmIcon}
        onSubmit={handleDeclineOffer}
        onClose={() => setDeclineModalVisible(false)}
        button1Text={"Yes"}
        button2Text={"No"}
      />
      <CommonModal
        title="Are you sure you want to
    cancel this Job offer?"
        open={cancelModalVisible}
        icon={ConfirmIcon}
        onSubmit={handleDeclineOffer}
        onClose={() => setCancelModalVisible(false)}
        button1Text={"Yes"}
        button2Text={"No"}
      />
      <CommonModal
        title="Offer sent
    successfully"
        open={successModalVisible}
        icon={TickCardIcon}
        onClose={() => {
          setSuccessModalVisible(false);
          handleSelectTab(3);
        }}
        isSingleButton
        singleBtnText={"Done"}
      />
    </Box>
  );
};

export const RatingsAndReviewsListHos = ({
  newUserDetails,
  handleHideRatings,
  type,
  jobData,
  handleShowUser,
  isNotActive,
  breadCrumb,
}) => {
  const [page, setPage] = useState(1);
  const [ratingsList, setRatingsList] = useState([]);
  const [hasNextResult, setHasNextResult] = useState(0);
  const dispatch = useDispatch();
  const userDetails = isNotActive
    ? { userData: newUserDetails }
    : newUserDetails;
  useEffect(() => {
    const arr = [
      {
        title: "Active Jobs",
        route: "/hospital/active-jobs",
      },
      {
        title: jobData?.title,
        route: `/hospital/active-jobs/${jobData?._id}`,
        func: () => {
          handleShowUser(false);
          handleHideRatings();
        },
      },
      {
        title:
          type == "offerSent"
            ? "Offer Sent"
            : type == "declined"
            ? "Declined"
            : "Pending",
        route: `/hospital/active-jobs/${jobData?._id}`,
        func: () => {
          handleShowUser(false);
          handleHideRatings();
        },
      },
      {
        title:
          userDetails?.userData?.firstName +
          " " +
          userDetails?.userData?.lastName,
        func: () => {
          handleHideRatings();
        },
      },
      {
        title: "Ratings & Reviews",
        route: "",
      },
    ];
    if (isNotActive) {
      dispatch(setBreadcrumb(breadCrumb));
    } else if (jobData?.title) {
      dispatch(setBreadcrumb(arr));
    }
  }, [breadCrumb?.length, isNotActive]);

  useEffect(() => {
    GetRatingsList();
  }, [page]);
  const GetRatingsList = () => {
    dispatch(setLoading(true));
    const payload = {
      userId: userDetails?.userData?._id,
      limit: 10,
      page: page,
    };
    GetRatings(payload)
      .then((resp) => {
        if (page == 1) {
          setRatingsList(resp.data.list);
        } else {
          setRatingsList((prev) => [...prev, ...resp.data.list]);
        }
        setHasNextResult(resp.data?.hasResult);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
  return (
    <Box textAlign={"left"}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box>
          <Typography
            display={"flex"}
            alignItems={"center"}
            marginBottom={"20px"}
            onClick={handleHideRatings}
            sx={{
              cursor: "pointer",
            }}
          >
            <img src={BackNavigation} alt="back" />
            <span
              style={{
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "19.2px",
                color: "#38879F",
                marginLeft: "5px",
              }}
            >
              Back
            </span>
          </Typography>
        </Box>
      </Box>
      <Box display={"flex"} alignItems={"center"}>
        <img
          src={userDetails?.userData?.profilePicture}
          width={132}
          style={{ borderRadius: "25px" }}
        />
        <Box marginLeft={"20px"}>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: 700,
              lineHeight: "28.8px",
              color: "#0d2645",
            }}
          >
            {userDetails?.userData?.firstName} {userDetails?.userData?.lastName}
          </Typography>
          <Box display={"flex"} justifyContent={"space-evenly"}>
            <img src={StarIcon} alt="star" />
            <Typography
              color="#0d2645"
              fontSize={"14px"}
              lineHeight={"21px"}
              fontWeight={500}
              marginLeft={"5px"}
            >
              {`${userDetails?.userData?.rating} (${
                userDetails?.userData?.ratingCount
              } ${
                userDetails?.userData?.ratingCount > 1 ? "reviews" : "review"
              })`}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: "20px",
        }}
      >
        <InfiniteScrollWrapper
          handleSetPage={() => setPage((prev) => prev + 1)}
          hasResult={hasNextResult}
        >
          {ratingsList?.map((item) => {
            return (
              <Box
                key={item?._id}
                sx={{
                  background: "#ecf3f6",
                  border: "1.5px solid #92bcca",
                  marginBottom: "20px",
                  padding: "20px",
                  borderRadius: "20px",
                }}
              >
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box
                    // textAlign={"center"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"15%"}
                    // margin={"auto"}
                  >
                    {[1, 2, 3, 4, 5].map((i) => {
                      if (item.rating < i && item.rating > i - 1) {
                        return (
                          <img
                            src={HalfFilled}
                            // onClick={() => handleSetRating(item.step, i)}
                            width={20}
                            style={{ marginRight: "10px" }}
                            key={i}
                          />
                        );
                      } else if (i <= item.rating) {
                        return (
                          <img
                            src={FilledStar}
                            // onClick={() => handleSetRating(item.step, i)}
                            width={20}
                            style={{ marginRight: "10px" }}
                            key={i}
                          />
                        );
                      }
                      return (
                        <img
                          src={BlankStar}
                          // onClick={() => handleSetRating(item.step, i)}
                          width={20}
                          style={{ marginRight: "10px" }}
                          key={i}
                        />
                      );
                    })}
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "14.2px",
                      }}
                      color="primary"
                    >
                      {item.rating}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "14.4px",
                      textAlign: "left",
                      color: "#8391a0",
                    }}
                  >
                    {item?.createdAt
                      ? format(new Date(item?.createdAt), "dd MMM, yyyy")
                      : ""}
                  </Typography>
                </Box>
                <Box
                  display={"flex"}
                  // justifyContent={"space-between"}
                  sx={{
                    alignItems: "center", // width: "20%",
                    marginTop: "20px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={item.reviewer.hospitalLogo}
                      alt="profile-pic"
                      width={40}
                      style={{ borderRadius: "50%" }}
                    />
                    <Typography
                      sx={{
                        marginLeft: "10px",
                        fontFamily: "Lato",
                        fontSize: "18px",
                        fontWeight: 500,
                        lineHeight: "19.2px",
                        textAlign: "left",
                        borderRight: "1px solid black",
                        paddingRight: "25px",
                      }}
                      color="primary"
                    >
                      {item.reviewer?.hospitalName}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      marginLeft: "20px",
                      fontFamily: "Lato",
                      fontSize: "18px",
                      fontWeight: 500,
                      lineHeight: "19.2px",
                      textAlign: "left",
                    }}
                    color="primary"
                  >
                    {item.reviewer?.city}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </InfiniteScrollWrapper>
      </Box>
    </Box>
  );
};
