import React, { useEffect } from "react";
import CustomHeader from "./CustomHeader";
import BannerSection from "./BannerSection";
import HowWeWork from "./HowWeWork";
import JoinAsHospital from "./JoinAsHospital";
import JoinAsNurse from "./JoinAsNurse";
import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions";
import ClientTestimonials from "./ClientTestimonials";
import Footer from "./Footer";
import WhoWeAre from "./WhoWeAre";
const LandingPage = () => {
  const homeID = "homeSec";
  const howWeWorkID = "workSec";
  const faqID = "faqSec";
  const whoWeID = "whoSec";

  useEffect(() => {
    localStorage.clear();
  }, []);
  return (
    <>
      <CustomHeader
        homeID={homeID}
        howWeWorkID={howWeWorkID}
        faqID={faqID}
        whoWeID={whoWeID}
      />
      <BannerSection homeID={homeID} />
      <WhoWeAre whoWeID={whoWeID} />
      <HowWeWork howWeWorkID={howWeWorkID} />
      <JoinAsHospital />
      <JoinAsNurse />
      <FrequentlyAskedQuestions faqID={faqID} />
      <ClientTestimonials />
      <Footer
        homeID={homeID}
        howWeWorkID={howWeWorkID}
        faqID={faqID}
        whoWeID={whoWeID}
      />
    </>
  );
};

export default LandingPage;
