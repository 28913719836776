import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import CloseIcon from "../../../assets/icons/filter-close.svg";
import BlankStar from "../../../assets/icons/blank-star.svg";
import FilledStar from "../../../assets/icons/filled-star.svg";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const NurseQuestionsArr = [
  {
    step: 0,
    title:
      "How satisfied were you with your overall experience at this hospital?",
  },
  {
    step: 1,
    title: "How reasonable or unreasonable was the workload for this role?",
  },
  {
    step: 2,
    title:
      "How accurately was the role described to you before you started the assignment?",
  },
  {
    step: 3,
    title:
      "How good or bad was the overall quality of the hospital/physical environment you were working in?",
  },
  {
    step: 4,
    title:
      "How fairly or unfairly do you think this hospital treats all of its employees?",
  },
];

const HospitalQuestionsArr = [
  {
    step: 0,
    title:
      "How would you rate the travel nurse's overall performance during their assignment?",
  },
  {
    step: 1,
    title:
      "Did they effectively adapt to our hospital's policies and procedures?",
  },
  {
    step: 2,
    title:
      "How did the travel nurse communicate and collaborate with the existing staff members?",
  },
  {
    step: 3,
    title:
      "How often did the they demonstrate exceptional skills or go above and beyond expectations during their assignment?",
  },
  {
    step: 4,
    title:
      "How effectively did the travel nurse respond to feedback and adapt to areas of improvement during their assignment?",
  },
];
export default function RatingModal(props) {
  const { open, handleClose, handleSubmit } = props;
  const [questionsArr, setQuestionsArr] = useState([]);
  const [selectedRating, setSelectedRating] = useState({});
  const userType = useSelector((state) => state.user.userData?.userType);
  console.log(selectedRating, "selectedRating");
  const handleSetRating = (step, num) => {
    const clonedObj = { ...selectedRating };
    clonedObj[step] = num;
    setSelectedRating(clonedObj);
  };

  useEffect(() => {
    if (userType === "hospital") {
      setQuestionsArr(HospitalQuestionsArr);
    } else {
      setQuestionsArr(NurseQuestionsArr);
    }
  }, [userType]);
  return (
    <Dialog
      open={open}
      sx={{
        textAlign: "center",
      }}
      PaperProps={{
        sx: {
          width: "460px",
          borderRadius: "25px",
        },
      }}
    >
      <DialogContent>
        <Box>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "20px",
                lineHeight: "24px",
                color: "#0D2645",
              }}
            >
              Review Survey
            </Typography>
            <img src={CloseIcon} alt="close" onClick={handleClose} />
          </Box>
          <Box
            overflow={"auto"}
            sx={{
              "::-webkit-scrollbar": {
                display: "block",
                background: "white",
                width: "5px",
              },
              "::-webkit-scrollbar-thumb": {
                background: "lightgrey",
                borderRadius: "50px",
              },
              height: "calc(100vh - 400px)",
            }}
          >
            {questionsArr.map((item) => {
              return (
                <>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "23px",
                      textAlign: "left",
                      color: "#000",
                      margin: "15px 0",
                    }}
                  >
                    {item.step + 1}. {item.title}
                  </Typography>
                  <Box
                    // textAlign={"center"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    width={"55%"}
                    // margin={"auto"}
                  >
                    {[1, 2, 3, 4, 5].map((i) => {
                      if (i <= selectedRating[item.step]) {
                        return (
                          <img
                            src={FilledStar}
                            onClick={() => handleSetRating(item.step, i)}
                            width={25}
                            style={{ marginRight: "10px" }}
                            key={i}
                          />
                        );
                      }
                      return (
                        <img
                          key={i}
                          src={BlankStar}
                          onClick={() => handleSetRating(item.step, i)}
                          width={25}
                          style={{ marginRight: "10px" }}
                        />
                      );
                    })}
                  </Box>
                </>
              );
            })}
          </Box>
          <Button
            sx={{
              width: "100%",
              height: "55px",
              marginTop: "20px",
              background: "#38879f",
              color: "#fff",
              fontWeight: 600,
              borderRadius: "10px",
              "&.Mui-disabled": {
                background: "#adcdd7",
                color: "#fff",
              },
              "&:hover": {
                backgroundColor: "#38879f",
              },
            }}
            onClick={() => {
              let totalRating = 0;
              Object.values(selectedRating).forEach((rating) => {
                totalRating += rating;
              });
              handleSubmit({ rating: totalRating / 5, review: " " });
            }}
          >
            Submit
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
