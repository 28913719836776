import { Box, Input, Typography } from "@mui/material";

export default function StyledInput(props) {
  const {
    name,
    value,
    placeholder,
    handleBlur,
    handleChange,
    errors,
    touched,
    style,
    disabled,
    type,
    endAdornment,
    startAdornment,
    isCurrency,
  } = props;
  return (
    <Box marginBottom={"20px"} sx={{ ...style }}>
      <Input
        name={name}
        type={type || "text"}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        startAdornment={startAdornment}
        inputProps={{
          style: {
            padding: isCurrency ? "10px 10px 10px 0" : "10px",
          },
        }}
        disabled={disabled}
        sx={{
          color: "#0d2645",
          padding: "10px 5px 10px 10px",
          background: "#eff6f8",
          width: "100%",
          height: "50px",
          fontWeight: 500,
          fontSize: {
            xs:'14px',
            sm:'15px',
            md:"16px",
            lg:"18px",
            xl:"18px"
          },
          lineHeight: "40px",
          borderRadius: "10px",
          "&.Mui-disabled:before": {
            borderBottom: "none",
          },
          "&:after": {
            borderBottom: "none",
          },
          "&:before": {
            borderBottom: "none",
          },
          "&:hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottom: "none",
          },
          "&.Mui-focused": {
            borderBottom: "none",
          },
        }}
        endAdornment={endAdornment}
      />
      {errors[name] && touched[name] && (
        <Typography textAlign={"left"} variant="body2" color="red">
          {errors[name]}
        </Typography>
      )}
    </Box>
  );
}
