import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { GetAllJobs } from "../../../../controller/services/hospital/jobs";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/slices/flags_slice";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { getFormattedValue, getShiftNames } from "../../../../utils/helper";
import NoDataFound from "../../../common/no-data";
import InfiniteScrollWrapper from "../../../common/infinite-scroll";
import { setBreadcrumb } from "../../../../redux/slices/breadcrumb_slice";
import EmptyIcon from "../../../../assets/icons/empty-board.svg";
export default function PendingJobs() {
  const [jobList, setJobList] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNextResult, setHasNextResult] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    if (page) {
      fetchAllJobs();
    }
  }, [page]);

  useEffect(() => {
    const arr = [
      {
        title: "Pending Jobs",
        route: "",
      },
    ];
    dispatch(setBreadcrumb(arr));
  }, []);

  const fetchAllJobs = () => {
    dispatch(setLoading(true));
    const payload = {
      page: page,
      limit: 10,
      status: "pending",
    };
    GetAllJobs(payload)
      .then((resp) => {
        if (page == 1) {
          setJobList(resp.data.list);
        } else {
          setJobList((prev) => [...prev, ...resp.data.list]);
        }
        setHasNextResult(resp.data?.hasResult);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
  return jobList?.length > 0 ? (
    <InfiniteScrollWrapper
      handleSetPage={() => setPage((prev) => prev + 1)}
      hasResult={hasNextResult}
    >
      <Grid container spacing={4}>
        {jobList.map((job) => {
          return <PendingJobCard job={job} key={job?._id} />;
        })}
      </Grid>
    </InfiniteScrollWrapper>
  ) : (
    <NoDataFound title={"No Pending Jobs Available"} icon={EmptyIcon} />
  );
}
const labelStyles = {
  fontFamily: "Lato",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "13.4px",
  textAlign: "left",
  color: "#8592a1",
};

const valueStyles = {
  fontFamily: "Lato",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "19.2px",
  textAlign: "left",
};
const PendingJobCard = ({ job }) => {
  const navigate = useNavigate();
  return (
    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
      <Box
        bgcolor={"#fff"}
        padding={"20px"}
        borderRadius={"25px"}
        boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
        height="100%"
      >
        <Typography
          sx={{
            fontSize: {
              xs: "18px",
              sm: "18px",
              md: "20px",
              lg: "20px",
              xl: "20px",
            },
          }}
          fontWeight={700}
          lineHeight={"24px"}
          textAlign={"left"}
          color={"primary"}
        >
          {job?.title}
        </Typography>
        <Grid container>
          <Grid
            className="border-right-line"
            sx={{ position: "relative" }}
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={4}
            item
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              sx={{
                padding: {
                  xs: "10px 0",
                  sm: "12px 0",
                  md: "15px 0",
                  lg: "15px 0",
                  xl: "15px 0",
                },
              }}
            >
              <Typography sx={{ ...labelStyles }}>Shift:</Typography>
              <Typography
                color={"primary"}
                sx={{
                  ...valueStyles,
                  width: "79%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                &nbsp;
                {job?.shifts?.map((shift, idx) => {
                  return (
                    getShiftNames(shift) +
                    (job?.shifts?.length !== idx + 1 ? ", " : "")
                  );
                })}
              </Typography>
            </Box>
          </Grid>
          <Grid
            className="border-right-line"
            sx={{ position: "relative" }}
            xs={6}
            sm={6}
            md={4}
            lg={4}
            xl={4}
            item
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              sx={{
                padding: {
                  xs: "10px 0",
                  sm: "12px 0",
                  md: "15px 0",
                  lg: "15px 0",
                  xl: "15px 0",
                },
              }}
            >
              <Typography sx={{ ...labelStyles }}>Duration:</Typography>
              <Typography sx={{ ...valueStyles }} color={"primary"}>
                &nbsp;{job?.duration} weeks
              </Typography>
            </Box>
          </Grid>
          <Grid xs={6} sm={6} md={4} lg={4} xl={4} item>
            <Box
              display={"flex"}
              alignItems={"center"}
              sx={{
                padding: {
                  xs: "10px 0",
                  sm: "12px 0",
                  md: "15px 0",
                  lg: "15px 0",
                  xl: "15px 0",
                },
              }}
            >
              <Typography sx={{ ...labelStyles }}>Open Positions:</Typography>
              <Typography sx={{ ...valueStyles }} color={"primary"}>
                &nbsp;{job?.numberOfOpenings}
              </Typography>
            </Box>
          </Grid>
          <Grid
            className="border-right-line"
            sx={{ position: "relative" }}
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={4}
            item
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              sx={{
                padding: {
                  xs: "10px 0",
                  sm: "12px 0",
                  md: "15px 0",
                  lg: "15px 0",
                  xl: "15px 0",
                },
              }}
            >
              <Typography sx={{ ...labelStyles }}>
                Experience Required:
              </Typography>
              <Typography sx={{ ...valueStyles }} color={"primary"}>
                &nbsp;{job?.experience} yrs
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} md={4} lg={4} xl={4} item>
            <Box display={"flex"} alignItems={"center"} padding={"15px 0"}>
              <Typography sx={{ ...labelStyles }}>Starts On:</Typography>
              <Typography sx={{ ...valueStyles }} color={"primary"}>
                &nbsp;{format(new Date(job?.startDate), "dd MMM, yyyy")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Divider
          sx={{
            marginBottom: {
              xs: "10px",
              sm: "10px",
              md: "15px",
              lg: "15px",
              xl: "15px",
            },
          }}
        />
        <Grid container alignItems={"center"}>
          <Grid xs={6} sm={6} md={6} lg={6} xl={6}>
            <Box display={"flex"} alignItems={"center"}>
              <Typography sx={{ ...labelStyles }}>Job Offer:</Typography>
              <Typography sx={{ ...valueStyles }}>
                &nbsp;
                {getFormattedValue(
                  job?.weeklyPay + job?.houseAllowance + job?.mealAllowance
                )}{" "}
                <span style={{ ...labelStyles }}>/wk</span>
              </Typography>
            </Box>
          </Grid>
          <Grid xs={6} sm={6} md={6} lg={6} xl={6} textAlign={"right"} item>
            <Button
              sx={{
                background: "#38879f",
                color: "#fff",
                fontWeight: {
                  xs: 500,
                  sm: 500,
                  md: 600,
                  lg: 600,
                  xl: 600,
                },
                padding: {
                  xs: "5px 20px",
                  sm: "5px 20px",
                  md: "5px 15px",
                  lg: "5px 15px",
                  xl: "5px 15px",
                },
                borderRadius: "50px",
                "&.Mui-disabled": {
                  background: "#adcdd7",
                  color: "#fff",
                },
                "&:hover": {
                  backgroundColor: "#38879f",
                },
              }}
              onClick={() => navigate(`/hospital/pending-jobs/${job?._id}`)}
            >
              Details
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
