import { Box, Chip, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../../redux/slices/breadcrumb_slice";
import { setLoading } from "../../../redux/slices/flags_slice";
import { GetHiredNurseList } from "../../../controller/services/hospital/hired-nurse";
import toast from "react-hot-toast";
import NoDataFound from "../../common/no-data";
import { format } from "date-fns";
import StarIcon from "../../../assets/icons/star.svg";
import { useNavigate } from "react-router-dom";
import EmptyIcon from "../../../assets/icons/empty-board.svg";
import InfiniteScrollWrapper from "../../common/infinite-scroll";

export default function HiredNurses() {
  const [candidateList, setCandidateList] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNextResult, setHasNextResult] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (page) {
      fetchCandidateList();
    }
  }, [page]);

  useEffect(() => {
    const arr = [
      {
        title: "Hired Nurses",
        route: "",
      },
    ];
    dispatch(setBreadcrumb(arr));
  }, []);
  const fetchCandidateList = () => {
    dispatch(setLoading(true));
    const payload = {
      page: page,
      limit: 10,
    };
    GetHiredNurseList(payload)
      .then((resp) => {
        console.log(resp.data.list, "sfsdfsdfs");
        if (page == 1) {
          setCandidateList(resp.data.list);
        } else {
          setCandidateList((prev) => [...prev, ...resp.data.list]);
        }
        setHasNextResult(resp.data?.hasResult);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
  return candidateList?.length > 0 ? (
    <InfiniteScrollWrapper
      handleSetPage={() => setPage((prev) => prev + 1)}
      hasResult={hasNextResult}
    >
      <Grid
        sx={{
          marginTop: {
            xs: "20px",
            sm: "20px",
            md: 0,
            lg: 0,
            xl: 0,
          },
        }}
      >
        {candidateList?.map((candidate) => {
          return (
            <Box
              className={"card-adjust-according-device"}
              sx={{ width: "201px", margin: "0 10px 20px", float: "left" }}
              key={candidate?._id}
            >
              <Grid
                bgcolor={"#f5f9fa"}
                border={"1px solid #bdd7df"}
                padding={"10px"}
                borderRadius={"25px"}
                sx={{ width: "100%", cursor: "pointer" }}
                onClick={() =>
                  navigate(`/hospital/hired-nurses/${candidate._id}`)
                }
              >
                <Box paddingTop={"10px"}>
                  <Box
                    sx={{
                      position: "relative",
                      zIndex: 1,
                    }}
                  >
                    <img
                      src={candidate?.userData?.profilePicture}
                      alt="applicant-img"
                      height={161}
                      width={161}
                      style={{ borderRadius: "25px" }}
                      className="jobs-profile-candidate-img"
                    />
                    <Chip
                      sx={{
                        bgcolor: "#ffffff",
                        position: "absolute",
                        bottom: "-8px",
                        right: 0,
                        left: 0,
                        margin: "auto",
                        width: "70px",
                      }}
                      label={
                        <Box display={"flex"} justifyContent={"space-evenly"}>
                          <img src={StarIcon} alt="star" />
                          <Typography
                            color="#0d2645"
                            fontSize={"14px"}
                            lineHeight={"21px"}
                            fontWeight={500}
                            marginLeft={"5px"}
                          >
                            {candidate?.rating || 0}
                          </Typography>
                        </Box>
                      }
                    />
                  </Box>
                  <Typography
                    fontWeight={700}
                    lineHeight={"18px"}
                    color="#0d2645"
                    marginBottom={"5px"}
                    sx={{
                      marginTop: {
                        xs: "30px",
                        sm: "25px",
                        md: "25px",
                        lg: "20px",
                        xl: "15px",
                      },
                      fontSize: {
                        xs: "18px",
                        sm: "16px",
                        md: "17px",
                        lg: "17px",
                        xl: "17px",
                      },
                    }}
                  >
                    {candidate?.userData?.firstName}{" "}
                    {candidate?.userData?.lastName}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "15px",
                        sm: "16px",
                        md: "17px",
                        lg: "17px",
                        xl: "17px",
                      },
                    }}
                    fontWeight={500}
                    lineHeight={"18px"}
                    color="#0d2645"
                    marginBottom={"10px"}
                    marginTop={"10px"}
                  >
                    {candidate?.jobData[0]?.title}
                    {candidate?.jobData?.length > 1 ? " + more" : ""}
                  </Typography>
                  <Typography
                    fontSize={"15px"}
                    fontWeight={700}
                    lineHeight={"18px"}
                    color="#0d2645"
                    marginBottom={"10px"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography
                      variant={"subtitle1"}
                      sx={{
                        fontSize: "14px",
                        lineHeight: "20.8px",
                        fontWeight: 500,
                        paddingRight: {
                          xs: "10px",
                          sm: "10px",
                          md: "10px",
                          lg: "5px",
                          xl: "5px",
                        },
                      }}
                    >
                      Job Started on:&nbsp;
                    </Typography>
                    {candidate?.jobData[0]?.startDate
                      ? format(
                          new Date(candidate?.jobData[0]?.startDate),
                          "MMM dd"
                        )
                      : ""}
                  </Typography>
                </Box>
              </Grid>
            </Box>
          );
        })}
      </Grid>
    </InfiniteScrollWrapper>
  ) : (
    <NoDataFound title={"No Hired Nurses"} height={"66vh"} icon={EmptyIcon} />
  );
}
