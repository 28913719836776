import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchQuery: "",
  //   filters: {
  state: "",
  city: "",
  experience: [],
  //   },
};

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setSearch(state, action) {
      state.searchQuery = action.payload;
    },
    setStateName(state, action) {
      state.state = action.payload;
    },
    setExperience(state, action) {
      state.experience = action.payload;
    },
    setCity(state, action) {
      state.city = action.payload;
    },
  },
});

export const { setSearch, setStateName, setExperience, setCity } =
  filtersSlice.actions;
export default filtersSlice.reducer;
