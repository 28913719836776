import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import { PureComponent, useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";

export default function DashboardChart({
  graphData,
  selectedDates,
  handleModifiedData,
}) {
  const [data, setData] = useState([]);
  const [datesArr, setDatesArr] = useState([]);
  const [maxYValue, setMaxYValue] = useState(10);
  useEffect(() => {
    if (graphData?.length > 0 && datesArr?.length > 0) {
      getModifiedData();
    } else {
      setData([]);
    }
  }, [graphData?.length, datesArr?.length]);

  useEffect(() => {
    if (selectedDates?.length > 0) {
      const months = getAllMonthsBetween(selectedDates[0], selectedDates[1]);
      setDatesArr(months);
    }
  }, [selectedDates?.length]);

  function getAllMonthsBetween(startDate, endDate) {
    const months = [];
    let currentMonth = startDate.startOf("month");

    while (
      currentMonth.isBefore(endDate) ||
      currentMonth.isSame(endDate, "month")
    ) {
      months.push(currentMonth.format("YYYY-MM"));
      currentMonth = currentMonth.add(1, "month");
    }

    return months;
  }
  const getModifiedData = () => {
    const dataArr = [...graphData];
    const arr = [];
    const filteredArr = [];
    dataArr.forEach((i) => {
      const formattedDate = format(new Date(i.createdAt), "yyyy-MM");
      const foundIdx =
        arr?.length > 0 ? arr?.findIndex((n) => n.date == formattedDate) : "-1";
      if (foundIdx != -1) {
        const obj = arr[foundIdx];
        if (obj) {
          obj.count = obj?.count + 1;
        }
      } else {
        arr.push({ date: formattedDate, count: 1 });
      }
    });
    datesArr.forEach((item) => {
      const foundItem = arr.find((i) => i.date === item);
      if (foundItem) {
        filteredArr.push(foundItem);
      } else {
        filteredArr.push({ date: item, count: 0 });
      }
    });
    const countsArr = arr.map((item) => item.count);
    const maxCount = Math.max(...countsArr);
    const domainVal =
      maxCount <= 1
        ? maxCount + Math.round((maxCount * 100) / 100)
        : maxCount + Math.round((maxCount * 15) / 100);
    setMaxYValue(domainVal);
    setData(filteredArr);
    handleModifiedData(filteredArr);
  };

  return (
    <AreaChart
      width={1300}
      height={400}
      data={data}
      margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
    >
      <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#adbccd" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#e0eaee" stopOpacity={0.2} />
        </linearGradient>
      </defs>
      <XAxis
        dataKey="date"
        tickLine={false}
        tickMargin={5}
        tick={<CustomTick />}
      />
      <YAxis
        tickLine={false}
        dataKey={"count"}
        tickMargin={15}
        domain={[0, maxYValue]}
      />
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip content={<CustomTooltip />} />
      <Area
        type="monotone"
        dataKey="count"
        stroke="#36819a"
        fillOpacity={1}
        fill="url(#colorUv)"
        strokeWidth={2}
      />
    </AreaChart>
  );
}

const CustomTooltip = ({ payload }) => {
  return (
    <Box
      sx={{
        background: "#fff",
        padding: "10px",
        borderRadius: "15px",
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      }}
    >
      <Typography
        sx={{
          fontSize: "11px",
          fontWeight: 400,
          lineHeight: "13.2px",
          color: "#8592a1",
          marginBottom: "5px",
        }}
      >
        {payload[0]?.payload?.date
          ? format(new Date(payload[0]?.payload?.date), "MMM yyyy")
          : ""}
      </Typography>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "16.8px",
          color: "#0D2645",
        }}
      >
        Hired Nurses: {payload[0]?.payload?.count}
      </Typography>
    </Box>
  );
};
class CustomTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={20} y={0} dy={16} textAnchor="end" fill="#666">
          {payload.value ? format(new Date(`${payload.value}-01`), "MMM") : ""}
        </text>
      </g>
    );
  }
}
