import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "transparent-care.firebaseapp.com",
  projectId: "transparent-care",
  storageBucket: "transparent-care.appspot.com",
  messagingSenderId: "941374231671",
  appId: "1:941374231671:web:ee50c6252cbbb69d796272",
  measurementId: "G-FKD8T878VG",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
export const analytics = getAnalytics(app);
export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey:
        "BAlXzOoDIvpbHy4cS2sFPdd09qfUoglJwUN0zgSGCMbEZbQsoAqcf2ytPmpKfyjGdPb3flo3cMdK0s2aHmHZNzg",
    });
    if (token) {
      localStorage.setItem("fcmToken", token);
    }
    console.log(token, "permission");
  }
};
