import { Typography, Box, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import PendingIcon from "../../../assets/icons/pending-approval.svg";
import RejectedIcon from "../../../assets/icons/rejected-profile.svg";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getProfile } from "../../../controller/services/hospital/profile";
import toast from "react-hot-toast";
import { setUserData } from "../../../redux/slices/user_slice";

export default function ApprovedScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const getProfileData = () => {
    getProfile()
      .then((resp) => {
        dispatch(setUserData(resp.data?.user));
        setStatus(resp.data?.user?.userStatus);
        if (resp.data?.user?.userStatus === "active") {
          navigate("/hospital/dashboard");
        }
      })
      .catch((Err) => {
        toast.error(Err.message);
      });
  };

  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <Box
      sx={{
        padding: {
          xs: "0 30px",
          sm: "0 30px",
          md: "0 70px",
          lg: "0 70px",
          xl: "0 70px",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: {
          xs: "100vh",
          sm: "100vh",
          md: "100vh",
          lg: "100vh",
          xl: "100vh",
        },
      }}
    >
      <Box
        border={2}
        borderColor={"#38879F"}
        borderRadius={5}
        sx={{
          position: "relative",
          width: "100%",
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          sx={{
            padding: {
              xs: "30px 20px",
              sm: "45px",
              md: "45px",
              lg: "45px",
              xl: "70px 45px",
            },
            width: {
              xs: "100%",
              sm: "100%",
              md: "70%",
              lg: "35%",
              xl: "30%",
            },
          }}
        >
          <img
            className="w-100-mobile"
            src={status == "blocked" ? RejectedIcon : PendingIcon}
            alt="logo"
          />
          <Typography
            variant="h3"
            color="#0d2645"
            fontWeight={600}
            margin={"20px 0 10px"}
            sx={{
              fontSize: {
                xs: 20,
                sm: 22,
                md: 24,
                lg: 26,
                xl: 26,
              },
              lineHeight: {
                xs: "27px",
                sm: "28px",
                md: "31.2px",
                lg: "31.2px",
                xl: "31.2px",
              },
            }}
          >
            {status == "blocked"
              ? "Your Profile Verification has been Rejected"
              : "Your Verification Request is in Process"}
          </Typography>
          <Typography
            variant="h6"
            color="#8391a0"
            fontWeight={400}
            lineHeight={"25px"}
            margin={"10px 0"}
            sx={{
              fontSize: {
                xs: 12,
                sm: 14,
                md: 15,
                lg: 16,
                xl: 16,
              },
              lineHeight: {
                xs: "20px",
                sm: "22px",
                md: "24px",
                lg: "25px",
                xl: "25px",
              },
            }}
          >
            {status == "blocked"
              ? "Please contact your admin for more information"
              : "Verification can take up to 24 hours. Thank you for your patience."}
          </Typography>
          {status == "blocked" && (
            <Button
              sx={{
                width: "100%",
                height: "55px",
                marginTop: "15px",
                background: "#38879f",
                color: "#fff",
                fontWeight: 600,
                borderRadius: "10px",
                "&.Mui-disabled": {
                  background: "#adcdd7",
                  color: "#fff",
                },
                "&:hover": {
                  backgroundColor: "#38879f",
                },
              }}
            >
              <Link
                to="mailto:contact@transparent-care.com"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                Email Us
              </Link>
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
