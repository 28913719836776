import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import StyledInput from "../../../common/styled/input";
import StyledDatePicker from "../../../common/styled/date-picker";
import tickCheckedCircle from "../../../../assets/icons/checked-circle.svg";
import tickCircle from "../../../../assets/icons/tick-circle.svg";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  setHospitalMenuSelected,
  setLoading,
} from "../../../../redux/slices/flags_slice";
import { useEffect, useState } from "react";
import {
  DeleteJob,
  GetJobDetails,
  PostJobAd,
  UpdateJobAd,
} from "../../../../controller/services/hospital/jobs";
import { useNavigate, useParams } from "react-router-dom";
import CommonModal from "../../../common/modals/commonModal";
import TickCardIcon from "../../../../assets/icons/tick-card.svg";
import DeleteIcon from "../../../../assets/icons/delete-job.svg";
import { number, object, string } from "yup";
import { format } from "date-fns";
import { setBreadcrumb } from "../../../../redux/slices/breadcrumb_slice";

export default function CreateJobAd() {
  const dispatch = useDispatch();
  const params = useParams();
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      title: "",
      startDate: "",
      experience: "",
      duration: "",
      description: "",
      numberOfOpenings: "",
      shifts: [],
      numberOfWorkingHours: "",
      numberOfWorkingDays: "",
      weeklyPay: "",
      mealAllowance: "",
      houseAllowance: "",
    },
    validationSchema: object({
      title: string()
        .required("This field is required")
        .matches(/^[A-Za-z0-9 ]*$/, "Please enter valid title")
        .min(3, "title must be at least 3 characters")
        .max(30, "title must be at most 30 characters"),
      experience: number().required("This field is required").max(50),
      description: string()
        .required("This field is required")
        .min(3, "description must be at least 3 characters")
        .max(255, "description must be at most 255 characters"),
      duration: number().required("This field is required").max(50).min(1),
      numberOfOpenings: number()
        .required("This field is required")
        .max(50)
        .min(1, "Number of openings must be greater than or equal to 1"),
      numberOfWorkingHours: number()
        .required("This field is required")
        .max(24)
        .min(1, "working hours must be greater than or equal to 1"),
      numberOfWorkingDays: number()
        .required("This field is required")
        .max(7)
        .min(1, "working days must be greater than or equal to 1"),
      weeklyPay: number().required("This field is required").min(1),
      mealAllowance: number().required("This field is required").min(1),
      houseAllowance: number().required("This field is required").min(1),
    }),
    onSubmit: (values) => {
      handleJobAdCreate(values);
    },
  });

  useEffect(() => {
    if (params.id) {
      const arr = [
        {
          title: "Edit a Job",
          route: "",
        },
      ];
      dispatch(setBreadcrumb(arr));
      fetchJobDetails();
    } else {
      const arr = [
        {
          title: "Post a Job",
          route: "",
        },
      ];
      dispatch(setBreadcrumb(arr));
      formik.setValues({
        title: "",
        startDate: "",
        experience: "",
        duration: "",
        description: "",
        numberOfOpenings: "",
        shifts: [],
        numberOfWorkingHours: "",
        numberOfWorkingDays: "",
        weeklyPay: "",
        mealAllowance: "",
        houseAllowance: "",
        totalWeeklyPay: "",
      });
    }
  }, [params.id]);

  useEffect(() => {
    if (formik.values?.weeklyPay) {
      const totalWeeklyPay =
        (Number(formik.values?.houseAllowance) || 0) +
        (Number(formik.values?.mealAllowance) || 0) +
        (Number(formik.values?.weeklyPay) || 0);
      formik.setFieldValue("totalWeeklyPay", totalWeeklyPay);
    }
  }, [
    formik.values?.weeklyPay,
    formik.values?.mealAllowance,
    formik.values?.houseAllowance,
  ]);

  const fetchJobDetails = () => {
    dispatch(setLoading(true));
    GetJobDetails(params.id)
      .then((resp) => {
        const { job } = resp.data;
        const obj = {
          title: job?.title,
          startDate: job?.startDate,
          experience: job?.experience,
          duration: job?.duration,
          description: job?.description,
          numberOfOpenings: job?.numberOfOpenings,
          shifts: job?.shifts,
          numberOfWorkingHours: job?.numberOfWorkingHours,
          numberOfWorkingDays: job?.numberOfWorkingDays,
          weeklyPay: job?.weeklyPay,
          mealAllowance: job?.mealAllowance,
          houseAllowance: job?.houseAllowance,
        };
        setSelectedDate(new Date(job?.startDate));
        formik.setValues({ ...obj });
        formik.setFieldValue("shifts", job?.shifts);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
  console.log(
    formik.isSubmitting,
    Object.entries(formik.errors).length > 0,
    Object.keys(formik.touched).length == 0,
    "fsdfsdf"
  );
  const handleJobAdCreate = (values) => {
    const payload = {
      ...values,
      startDate: selectedDate
        ? format(selectedDate, "yyyy-MM-dd") + "T00:00:00.000Z"
        : "",
      shifts: formik.values.shifts.filter((i) => i != "Select Shifts"),
    };
    dispatch(setLoading(true));
    delete payload.totalWeeklyPay;
    const func = params?.id
      ? UpdateJobAd(payload, params?.id)
      : PostJobAd(payload);
    func
      .then((resp) => {
        setSuccessModalVisible(true);
        formik.setSubmitting(false);
        dispatch(setHospitalMenuSelected(2));
      })
      .catch((Err) => {
        toast.error(Err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
        formik.setSubmitting(false);
      });
  };

  const handleDeleteUser = () => {
    dispatch(setLoading(true));
    DeleteJob(params.id)
      .then((resp) => {
        toast.success(resp.message);
        setDeleteModalVisible(false);
        navigate("/hospital/pending-jobs");
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  console.log(formik.values.shifts, "shifts");

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          columnSpacing={2}
          sx={{
            padding: {
              xs: "20px 15px",
              sm: "20px",
              md: "30px",
              lg: "40px",
              xl: "40px",
            },
          }}
          maxHeight={"85vh"}
          overflow={"auto"}
        >
          <Grid xs={12} sm={6} md={6} lg={6} xl={6} item>
            <InputLabel
              sx={{
                marginBottom: {
                  xs: "10px",
                  sm: "10px",
                  md: "20px",
                  lg: "20px",
                  xl: "20px",
                },
                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "16px",
                  xl: "18px",
                  lg: "18px",
                },
              }}
            >
              Job Title
            </InputLabel>
            <StyledInput
              name={"title"}
              placeholder={"Enter Job Title"}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.title}
            />
          </Grid>
          <Grid xs={12} sm={6} md={6} lg={6} xl={6} item>
            <InputLabel
              sx={{
                marginBottom: {
                  xs: "10px",
                  sm: "10px",
                  md: "20px",
                  lg: "20px",
                  xl: "20px",
                },
                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "16px",
                  xl: "18px",
                  lg: "18px",
                },
              }}
            >
              Job Start Date
            </InputLabel>
            {/* <StyledInput
          name={"jobTitle"}
          placeholder={"Enter Job Title"}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          errors={formik.errors}
          touched={formik.touched}
          value={formik.values.jobTitle}
        /> */}
            <Box
              sx={{
                marginBottom: {
                  xs: "20px",
                  sm: "20px",
                  md: 0,
                  lg: 0,
                  xl: 0,
                },
              }}
            >
              <StyledDatePicker
                name={"jobStartDate"}
                value={selectedDate}
                handleSelectDate={(date) => {
                  setSelectedDate(date);
                  formik.setTouched({ title: true });
                }}
              />
            </Box>
          </Grid>
          <Grid xs={12} sm={6} md={6} lg={6} xl={6} item>
            <InputLabel
              sx={{
                marginBottom: {
                  xs: "10px",
                  sm: "10px",
                  md: "20px",
                  lg: "20px",
                  xl: "20px",
                },
                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "16px",
                  xl: "18px",
                  lg: "18px",
                },
              }}
            >
              Job Experience Required
            </InputLabel>
            <StyledInput
              name={"experience"}
              type="number"
              placeholder={"Enter Experience"}
              handleChange={(e) => {
                if (!e.target.value || /^\d+(\.\d)?$/.test(e.target.value)) {
                  formik.handleChange(e);
                }
              }}
              handleBlur={formik.handleBlur}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.experience}
              endAdornment={
                <Typography paddingRight={"10px"}>
                  {formik.values.experience <= 1 ? "yr" : "yrs"}
                </Typography>
              }
            />
          </Grid>
          <Grid xs={12} sm={6} md={6} lg={6} xl={6} item>
            <InputLabel
              sx={{
                marginBottom: {
                  xs: "10px",
                  sm: "10px",
                  md: "20px",
                  lg: "20px",
                  xl: "20px",
                },
                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "16px",
                  xl: "18px",
                  lg: "18px",
                },
              }}
            >
              Job Duration
            </InputLabel>
            <StyledInput
              name={"duration"}
              type="number"
              placeholder={"Enter Duration"}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.duration}
              endAdornment={
                <Typography paddingRight={"10px"}>
                  {formik.values.duration <= 1 ? "week" : "weeks"}
                </Typography>
              }
            />
          </Grid>
          <Grid xs={12} sm={6} md={6} lg={6} xl={6} item>
            <InputLabel
              sx={{
                marginBottom: {
                  xs: "10px",
                  sm: "10px",
                  md: "20px",
                  lg: "20px",
                  xl: "20px",
                },
                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "16px",
                  xl: "18px",
                  lg: "18px",
                },
              }}
            >
              Open Positions
            </InputLabel>
            <StyledInput
              name={"numberOfOpenings"}
              placeholder={"Enter No. of Open Positions"}
              handleChange={formik.handleChange}
              type="number"
              handleBlur={formik.handleBlur}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.numberOfOpenings}
            />
          </Grid>
          <Grid xs={12} sm={6} md={6} lg={6} xl={6} item>
            <InputLabel
              sx={{
                marginBottom: {
                  xs: "10px",
                  sm: "10px",
                  md: "20px",
                  lg: "20px",
                  xl: "20px",
                },
                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "16px",
                  xl: "18px",
                  lg: "18px",
                },
              }}
            >
              Select Shifts
            </InputLabel>
            <Box
              sx={{
                marginBottom: {
                  xs: "20px",
                  sm: "20px",
                  md: 0,
                  lg: 0,
                  xl: 0,
                },
              }}
            >
              <Select
                name="shifts"
                placeholder="Select your level of care experience"
                inputProps={{
                  style: {
                    padding: "10px",
                  },
                }}
                multiple
                value={
                  formik.values.shifts?.length == 0
                    ? ["Select Shifts"]
                    : formik.values.shifts.filter((i) => i != "Select Shifts")
                }
                renderValue={(values) =>
                  values?.length == 1 && values?.includes("Select Shifts") ? (
                    <Typography sx={{ color: "#7b8c9d" }}>
                      Select Shifts
                    </Typography>
                  ) : (
                    <Typography>
                      {values
                        .filter((n) => n != "Select Shifts")
                        .map((i, index) => (
                          <span style={{ textTransform: "capitalize" }} key={1}>
                            {i + (index + 1 !== values?.length ? ", " : "")}
                          </span>
                        ))}
                    </Typography>
                  )
                }
                sx={{
                  padding: "10px 5px 10px 0",
                  background: "#eff6f8",
                  color: "#0d2645",
                  fontSize: "16px",
                  width: "100%",
                  height: "50px",
                  textAlign: "left",
                  borderRadius: "10px",
                  "&:after": {
                    borderBottom: "none",
                  },
                  "&:before": {
                    borderBottom: "none",
                  },
                  "&:hover:not(.Mui-disabled, .Mui-error):before": {
                    borderBottom: "none",
                  },
                  "&.Mui-focused": {
                    border: "none",
                    outline: "none",
                  },
                }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <MenuItem
                  value="Day shift"
                  sx={{
                    height: "50px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: "#0d2645",
                    padding: "0 8px",
                    "&.Mui-selected": {
                      background: "none",
                    },
                  }}
                >
                  <Typography fontWeight={600} fontSize={16}>
                    Day Shift
                  </Typography>
                  <Checkbox
                    sx={{
                      borderRadius: "50%",
                    }}
                    icon={<img src={tickCircle} alt="checked" />}
                    checkedIcon={<img src={tickCheckedCircle} alt="checked" />}
                    checked={formik.values.shifts.includes("Day shift")}
                  />
                </MenuItem>
                <Divider
                  sx={{
                    marginTop: "0 !important",
                    marginBottom: "0 !important",
                  }}
                />
                <MenuItem
                  value="Afternoon shift"
                  sx={{
                    height: "50px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: "#0d2645",
                    padding: "0 8px",
                    "&.Mui-selected": {
                      background: "none",
                    },
                  }}
                >
                  <Typography fontWeight={600} fontSize={16}>
                    Afternoon Shift
                  </Typography>
                  <Checkbox
                    sx={{
                      borderRadius: "50%",
                    }}
                    icon={<img src={tickCircle} alt="checked" />}
                    checkedIcon={<img src={tickCheckedCircle} alt="checked" />}
                    checked={formik.values.shifts.includes("Afternoon shift")}
                  />
                </MenuItem>
                <Divider
                  sx={{
                    marginTop: "0 !important",
                    marginBottom: "0 !important",
                  }}
                />
                <MenuItem
                  value="Evening shift"
                  sx={{
                    height: "50px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: "#0d2645",
                    padding: "0 8px",
                    "&.Mui-selected": {
                      background: "none",
                    },
                  }}
                >
                  <Typography fontWeight={600} fontSize={16}>
                    Evening Shift
                  </Typography>
                  <Checkbox
                    sx={{
                      borderRadius: "50%",
                    }}
                    icon={<img src={tickCircle} alt="checked" />}
                    checkedIcon={<img src={tickCheckedCircle} alt="checked" />}
                    checked={formik.values.shifts.includes("Evening shift")}
                  />
                </MenuItem>
                <Divider
                  sx={{
                    marginTop: "0 !important",
                    marginBottom: "0 !important",
                  }}
                />
                <MenuItem
                  value="Night shift"
                  sx={{
                    height: "50px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: "#0d2645",
                    padding: "0 8px",
                    "&.Mui-selected": {
                      background: "none",
                    },
                  }}
                >
                  <Typography fontWeight={600} fontSize={16}>
                    Night Shift
                  </Typography>
                  <Checkbox
                    sx={{
                      borderRadius: "50%",
                    }}
                    icon={<img src={tickCircle} alt="checked" />}
                    checkedIcon={<img src={tickCheckedCircle} alt="checked" />}
                    checked={formik.values.shifts.includes("Night shift")}
                  />
                </MenuItem>
                <Divider
                  sx={{
                    marginTop: "0 !important",
                    marginBottom: "0 !important",
                  }}
                />
                <MenuItem
                  value="Rotating shift"
                  sx={{
                    height: "50px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: "#0d2645",
                    padding: "0 8px",
                    "&.Mui-selected": {
                      background: "none",
                    },
                  }}
                >
                  <Typography fontWeight={600} fontSize={16}>
                    Rotating Shift
                  </Typography>
                  <Checkbox
                    sx={{
                      borderRadius: "50%",
                    }}
                    icon={<img src={tickCircle} alt="checked" />}
                    checkedIcon={<img src={tickCheckedCircle} alt="checked" />}
                    checked={formik.values.shifts.includes("Rotating shift")}
                  />
                </MenuItem>
              </Select>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} md={6} lg={6} xl={6} item>
            <InputLabel
              sx={{
                marginBottom: {
                  xs: "10px",
                  sm: "10px",
                  md: "20px",
                  lg: "20px",
                  xl: "20px",
                },
                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "16px",
                  xl: "18px",
                  lg: "18px",
                },
              }}
            >
              Number of working Hours/Day
            </InputLabel>
            <StyledInput
              name={"numberOfWorkingHours"}
              type="number"
              placeholder={"Enter No. of Working Hours"}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.numberOfWorkingHours}
              endAdornment={
                <Typography paddingRight={"10px"}>
                  {formik.values.numberOfWorkingHours <= 1 ? "hour" : "hours"}
                </Typography>
              }
            />
          </Grid>
          <Grid xs={12} sm={6} md={6} lg={6} xl={6} item>
            <InputLabel
              sx={{
                marginBottom: {
                  xs: "10px",
                  sm: "10px",
                  md: "20px",
                  lg: "20px",
                  xl: "20px",
                },
                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "16px",
                  xl: "18px",
                  lg: "18px",
                },
              }}
            >
              Number of Working Days/Week
            </InputLabel>
            <StyledInput
              name={"numberOfWorkingDays"}
              type="number"
              placeholder={"Enter No. of Working Days"}
              handleChange={(e) => {
                if (!e.target.value || /^\d+(\.\d)?$/.test(e.target.value)) {
                  formik.handleChange(e);
                }
              }}
              handleBlur={formik.handleBlur}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.numberOfWorkingDays}
              endAdornment={
                <Typography paddingRight={"10px"}>
                  {formik.values.numberOfWorkingDays <= 1 ? "day" : "days"}
                </Typography>
              }
            />
          </Grid>
          <Grid xs={12} sm={6} md={6} lg={6} xl={6} item>
            <InputLabel
              sx={{
                marginBottom: {
                  xs: "10px",
                  sm: "10px",
                  md: "20px",
                  lg: "20px",
                  xl: "20px",
                },
                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "16px",
                  xl: "18px",
                  lg: "18px",
                },
              }}
            >
              Weekly Pay
            </InputLabel>
            <StyledInput
              name={"weeklyPay"}
              placeholder={"Weekly Pay ($)"}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.weeklyPay}
              startAdornment={
                formik.values.weeklyPay ? (
                  <InputAdornment
                    position="start"
                    sx={{
                      "& .MuiTypography-root ": {
                        marginLeft: "5px",
                        color: "#0d2645",
                      },
                    }}
                  >
                    $
                  </InputAdornment>
                ) : (
                  ""
                )
              }
              isCurrency
            />
          </Grid>
          <Grid xs={12} sm={6} md={6} lg={6} xl={6} item>
            <InputLabel
              sx={{
                marginBottom: {
                  xs: "10px",
                  sm: "10px",
                  md: "20px",
                  lg: "20px",
                  xl: "20px",
                },
                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "16px",
                  xl: "18px",
                  lg: "18px",
                },
              }}
            >
              Meals Allowance
            </InputLabel>
            <StyledInput
              name={"mealAllowance"}
              type="number"
              placeholder={"Meals Allowance ($)"}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.mealAllowance}
              startAdornment={
                formik.values.mealAllowance ? (
                  <InputAdornment
                    position="start"
                    sx={{
                      "& .MuiTypography-root ": {
                        marginLeft: "5px",
                        color: "#0d2645",
                      },
                    }}
                  >
                    $
                  </InputAdornment>
                ) : (
                  ""
                )
              }
              isCurrency
            />
          </Grid>
          <Grid xs={12} sm={6} md={6} lg={6} xl={6} item>
            <InputLabel
              sx={{
                marginBottom: {
                  xs: "10px",
                  sm: "10px",
                  md: "20px",
                  lg: "20px",
                  xl: "20px",
                },
                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "16px",
                  xl: "18px",
                  lg: "18px",
                },
              }}
            >
              Housing Allowance
            </InputLabel>
            <StyledInput
              name={"houseAllowance"}
              type="number"
              placeholder={"Housing Allowance ($)"}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.houseAllowance}
              startAdornment={
                formik.values.houseAllowance ? (
                  <InputAdornment
                    position="start"
                    sx={{
                      "& .MuiTypography-root ": {
                        marginLeft: "5px",
                        color: "#0d2645",
                      },
                    }}
                  >
                    $
                  </InputAdornment>
                ) : (
                  ""
                )
              }
              isCurrency
            />
          </Grid>
          <Grid xs={12} sm={6} md={6} lg={6} xl={6} item>
            <InputLabel
              sx={{
                marginBottom: {
                  xs: "10px",
                  sm: "10px",
                  md: "20px",
                  lg: "20px",
                  xl: "20px",
                },
                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "16px",
                  xl: "18px",
                  lg: "18px",
                },
              }}
            >
              Total Weekly Pay
            </InputLabel>
            <StyledInput
              name={"totalWeeklyPay"}
              placeholder={"$0"}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.totalWeeklyPay}
              disabled
              startAdornment={
                formik.values.totalWeeklyPay ? (
                  <InputAdornment
                    position="start"
                    sx={{
                      "& .MuiTypography-root ": {
                        marginLeft: "5px",
                        color: "rgba(0, 0, 0, 0.38)",
                      },
                    }}
                  >
                    $
                  </InputAdornment>
                ) : (
                  ""
                )
              }
              isCurrency
            />
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            <InputLabel
              sx={{
                marginBottom: {
                  xs: "10px",
                  sm: "10px",
                  md: "20px",
                  lg: "20px",
                  xl: "20px",
                },
                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "16px",
                  xl: "18px",
                  lg: "18px",
                },
              }}
            >
              Job Description
            </InputLabel>
            <TextareaAutosize
              name="description"
              className="area-desc"
              placeholder="Enter description"
              minRows={4}
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{
                padding: "10px 5px 10px 20px",
                background: "#eff6f8",
                width: "100%",
                border: "none",
                height: "30px",
                borderRadius: "20px",
                margin: "10px 0",
                fontFamily: "Lato, sans-serif",
                fontSize: "15px",
              }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            {params?.id && (
              <Button
                onClick={() => setDeleteModalVisible(true)}
                sx={{
                  border: "1px solid #38879f",
                  width: {
                    xs: "50%",
                    sm: "25%",
                    md: "25%",
                    lg: "25%",
                    xl: "25%",
                  },
                  marginBottom: "20px",
                  textTransform: "none",
                  height: "50px",
                  fontSize: "15px",
                  color: "#38879f",
                  background: "#fff",
                  fontWeight: 600,
                  borderRadius: "10px",
                  marginRight: "10px",
                  "&.Mui-disabled": {
                    color: "#adcdd7",
                    background: "#fff",
                  },
                  "&:hover": {
                    color: "#38879f",
                  },
                }}
              >
                Delete
              </Button>
            )}
            <Button
              type="submit"
              disabled={
                formik.isSubmitting ||
                Object.entries(formik.errors).length > 0 ||
                Object.keys(formik.touched).length == 0
              }
              sx={{
                width: {
                  xs: "100%",
                  sm: "25%",
                  md: "25%",
                  lg: "25%",
                  xl: "25%",
                },
                marginBottom: "20px",
                textTransform: "none",
                height: "50px",
                fontSize: "15px",
                background: "#38879f",
                color: "#fff",
                fontWeight: 600,
                borderRadius: "10px",
                "&.Mui-disabled": {
                  background: "#adcdd7",
                  color: "#fff",
                },
                "&:hover": {
                  backgroundColor: "#38879f",
                },
              }}
            >
              {params?.id ? "Update" : "Post a Job"}
            </Button>
          </Grid>
        </Grid>
      </form>
      <CommonModal
        title={`Job post ${params?.id ? "updated" : "created"} successfully`}
        open={successModalVisible}
        icon={TickCardIcon}
        onClose={() => {
          navigate("/hospital/pending-jobs");
          setSuccessModalVisible(false);
        }}
        isSingleButton
        singleBtnText={"Done"}
      />
      <CommonModal
        title="Are you sure you want to
        delete this Job?"
        open={deleteModalVisible}
        icon={DeleteIcon}
        onSubmit={handleDeleteUser}
        onClose={() => setDeleteModalVisible(false)}
        button1Text={"Yes"}
        button2Text={"No"}
      />
    </>
  );
}
