import { Typography, Box, Button } from "@mui/material";
import AppLogo from "../../../../assets/icons/app-logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import {
  forgotPassword,
  verifyOtp,
} from "../../../../controller/services/auth";
import toast from "react-hot-toast";
import OTPInput from "react-otp-input";
import { useEffect, useState } from "react";
import BackIcon from "../../../../assets/icons/back-dark.svg";

export default function VerifyOtp() {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const params = useLocation();
  const [timer, setTimer] = useState(30);
  const [resendData, setResendData] = useState(null);
  const userType = localStorage.getItem("userType");

  const handleSubmit = () => {
    const payload = {
      token: resendData?.token || params.state.token,
      otp,
    };
    verifyOtp(payload)
      .then((resp) => {
        navigate("/reset-password", {
          state: {
            token: resendData?.token || params.state.token,
          },
        });
        toast.success(resp.message);
      })
      .catch((Err) => {
        toast.error(Err.message);
      });
  };

  useEffect(() => {
    const intervalID = setInterval(() => {
      if (timer != 0) {
        setTimer((prev) => prev - 1);
      } else setTimer(0);
    }, 1000);
    return () => {
      clearInterval(intervalID);
    };
  }, [timer]);

  const handleResend = (values) => {
    const payload = {
      userType: userType,
      email: params.state.email,
    };
    forgotPassword(payload)
      .then((resp) => {
        setResendData(resp.data);
        toast.success("Otp resent!");
      })
      .catch((Err) => {
        toast.error(Err.message);
      });
  };
  return (
    <Box
      sx={{
        padding: {
          xs: "30px",
          sm: "0 30px",
          md: "0 70px",
          lg: "0 70px",
          xl: "0 70px",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: {
          xs: "auto",
          sm: "auto",
          md: "100vh",
          lg: "100vh",
          xl: "100vh",
        },
      }}
    >
      <Box
        border={2}
        borderColor={"#38879F"}
        borderRadius={5}
        sx={{
          position: "relative",
          width: "100%",
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          sx={{
            padding: {
              xs: "30px 20px",
              sm: "45px",
              md: "45px",
              lg: "45px",
              xl: "70px 45px",
            },
            width: {
              xs: "100%",
              sm: "100%",
              md: "70%",
              lg: "35%",
              xl: "30%",
            },
          }}
        >
          <img
            src={BackIcon}
            alt="back"
            style={{
              position: "absolute",
              left: "10vw",
              top: "15vh",
              cursor: "pointer",
            }}
            className="back-icon"
            onClick={() => navigate(-1)}
          />
          <img className="app-logo-role" src={AppLogo} alt="logo" />
          <Typography
            variant="h3"
            color="#0d2645"
            fontWeight={600}
            margin={"20px 0 10px"}
            sx={{
              fontSize: {
                xs: 20,
                sm: 22,
                md: 24,
                lg: 26,
                xl: 26,
              },
              lineHeight: {
                xs: "27px",
                sm: "28px",
                md: "31.2px",
                lg: "31.2px",
                xl: "31.2px",
              },
            }}
          >
            Verify your email
          </Typography>
          <Typography
            variant="h6"
            color="#8391a0"
            fontWeight={400}
            sx={{
              padding: {
                xs: "0 0 30px",
                sm: "10px",
                md: "10px",
                lg: "10px",
                xl: "10px",
              },
              fontSize: {
                xs: "12px",
                sm: "14px",
                md: "16px",
                lg: "16px",
                xl: "16px",
              },
              lineHeight: {
                xs: "20px",
                sm: "24px",
                md: "25px",
                lg: "25px",
                xl: "25px",
              },
            }}
          >
            We have sent a verification code to your email. Please enter the
            code below
          </Typography>

          <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderInput={(props) => <input {...props} />}
            inputStyle={{
              background: "#ebf3f6",
              width: "50px",
              height: "50px",
              borderRadius: "15px",
              color: "#0d2645",
              textAlign: "center",
              border: "none",
              fontSize: "15px",
              outline: "none",
            }}
            placeholder="------"
            containerStyle={{
              justifyContent: "space-between",
              marginTop: "25px",
            }}
          />

          <Typography
            fontSize={"14px"}
            color="#8592a1"
            marginTop={"25px"}
            marginBottom={"25px"}
            sx={
              timer != 0
                ? {
                    opacity: 0.7,
                  }
                : {}
            }
          >
            Have you received the code? &nbsp;
            <span
              style={{
                fontWeight: 600,
                color: "#38879f",
                cursor: "pointer",
              }}
              onClick={() => {
                if (timer == 0) {
                  handleResend();
                }
              }}
            >
              Resend
            </span>
            <br />
            {timer != 0 && (
              <span style={{ color: "#0d2645", marginTop: "10px", opacity: 1 }}>
                00:{timer}
              </span>
            )}
          </Typography>
          <Button
            onClick={handleSubmit}
            sx={{
              width: "80%",
              height: "55px",
              marginTop: "15px",
              background: "#38879f",
              color: "#fff",
              fontWeight: 600,
              borderRadius: "10px",
              "&.Mui-disabled": {
                background: "#adcdd7",
                color: "#fff",
              },
              "&:hover": {
                backgroundColor: "#38879f",
              },
            }}
            disabled={otp?.length < 6}
          >
            Verify
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
