import axiosInstance from "../api/axios";

export const userSignUp = async (payload) => {
  const res = await axiosInstance.post("/api/v1/auth/register", payload);
  return res.data;
};

export const userSignIn = async (payload) => {
  const res = await axiosInstance.post("/api/v1/auth/login", payload);
  return res.data;
};

export const forgotPassword = async (payload) => {
  const res = await axiosInstance.post("/api/v1/auth/forgot-password", payload);
  return res.data;
};

export const verifyOtp = async (payload) => {
  const res = await axiosInstance.post("/api/v1/auth/verify-otp", payload);
  return res.data;
};

export const resetPassword = async (payload) => {
  const res = await axiosInstance.post("/api/v1/auth/reset-password", payload);
  return res.data;
};

export const changePassword = async (payload) => {
  const res = await axiosInstance.post("/api/v1/auth/change-password", payload);
  return res.data;
};
