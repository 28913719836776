import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import AppLayout from "./app-layout/AppLayout";

const ProtectedRoute = ({ children, withoutLayout, tspColor }) => {
  const userData = useSelector((state) => state.user.userData);
  const accessToken = JSON.parse(localStorage.getItem("userData"))?.token;
  if (userData?.userType == "hospital") {
    if (!accessToken) {
      return <Navigate to="/" />;
    } else if (
      userData?.isProfileCreated &&
      userData?.isPhoneVerified &&
      !userData?.isAccountVerified
    ) {
      return <Navigate to="/hospital/approval-status" />;
    }
  } else {
    if (!accessToken) {
      return <Navigate to="/" />;
    }
  }
  return withoutLayout ? (
    children
  ) : (
    <AppLayout tspColor={tspColor}>{children}</AppLayout>
  );
};

export default ProtectedRoute;
