import axiosInstance from "../../api/axios";

export const GetNurseJobList = async (payload) => {
  const res = await axiosInstance.post("/api/v1/job/list", payload);
  return res.data;
};

export const GetNurseJobDetails = async (id) => {
  const res = await axiosInstance.get(`/api/v1/job/view/${id}`);
  return res.data;
};

export const ApplyJobService = async (payload) => {
  const res = await axiosInstance.post(`/api/v1/nurse/apply-job`, payload);
  return res.data;
};

export const GetJobAppliedList = async (payload) => {
  const res = await axiosInstance.post(
    `/api/v1/nurse/list-job-applications`,
    payload
  );
  return res.data;
};

export const GetJobAppliedDetails = async (id) => {
  const res = await axiosInstance.post(
    `/api/v1/nurse/view-job-applicant/${id}`
  );
  return res.data;
};

export const JobOfferAction = async (id, payload) => {
  const res = await axiosInstance.post(
    `/api/v1/nurse/job-application/${id}`,
    payload
  );
  return res.data;
};
