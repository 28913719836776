import axiosInstance from "../../api/axios";

export const GetDashboardCounts = async () => {
  const res = await axiosInstance.get(`/api/v1/analytics/hospital-dashboard`);
  return res.data;
};

export const GetDashboardGraphData = async (payload) => {
  const res = await axiosInstance.post(
    `/api/v1/analytics/get-graph-data`,
    payload
  );
  return res.data;
};
