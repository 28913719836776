import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Input } from "@mui/material";
import "./index.css";
import CalendarIcon from "../../../../assets/icons/calendar-icon.svg";
export default function StyledDatePicker(props) {
  return (
    <DatePicker
      selected={props.value || null}
      placeholderText="Enter Date"
      onChange={(date) => props.handleSelectDate(date)}
      dateFormat={"dd/MM/yyyy"}
      minDate={new Date()}
      maxDate={
        new Date(
          new Date().getFullYear() + 1,
          new Date().getMonth(),
          new Date().getDate()
        )
      }
      customInput={
        <Input
          type="text"
          name={props.name}
          sx={{
            padding: "10px 5px 10px 20px",
            background: "#eff6f8",
            width: "100%",
            height: "50px",
            borderRadius: "10px",
            "&:after": {
              borderBottom: "none",
            },
            "&:before": {
              borderBottom: "none",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "none",
            },
            "&.Mui-focused": {
              borderBottom: "none",
            },
          }}
          endAdornment={
            <img
              src={CalendarIcon}
              alt="icon"
              style={{ marginRight: "10px" }}
            />
          }
        />
      }
    />
  );
}
