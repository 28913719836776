import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  type: "pending",
  selectedActiveTab: 0,
  hospitalMenuSelected: 0,
  nurseMenuSelected: 0,
  notificationDot: false,
};

const flagsSlice = createSlice({
  name: "flags",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setSelectedType(state, action) {
      state.type = action.payload;
    },
    setActiveSelectedTab(state, action) {
      state.selectedActiveTab = action.payload;
    },
    setHospitalMenuSelected(state, action) {
      state.hospitalMenuSelected = action.payload;
    },
    setNurseMenuSelected(state, action) {
      state.nurseMenuSelected = action.payload;
    },
    setNotificationDot(state, action) {
      state.notificationDot = action.payload;
    },
  },
});

export const {
  setLoading,
  setSelectedType,
  setActiveSelectedTab,
  setHospitalMenuSelected,
  setNurseMenuSelected,
  setNotificationDot,
} = flagsSlice.actions;
export default flagsSlice.reducer;
