import {
  Box,
  Button,
  ListItemIcon,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import DashboardIcon from "../../../assets/icons/dashboard.svg";
import MenuLogo from "../../../assets/icons/menu-logo.svg";
import JobIcon from "../../../assets/icons/jobs.svg";
import EditIcon from "../../../assets/icons/edit-profile.svg";
import clockIcon from "../../../assets/icons/pending.svg";
import NurseIcon from "../../../assets/icons/nurse.svg";
import CompletedIcon from "../../../assets/icons/completed.svg";
import LogoutIcon from "../../../assets/icons/logout-modal-icon.svg";
import LogoutBtnIcon from "../../../assets/icons/logout.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonModal from "../modals/commonModal";
import { useDispatch, useSelector } from "react-redux";
import {
  setHospitalMenuSelected,
  setNurseMenuSelected,
} from "../../../redux/slices/flags_slice";

const hospitalMenus = [
  {
    index: 0,
    title: "Dashboard",
    Icon: DashboardIcon,
    to: "/hospital/dashboard",
  },
  {
    index: 1,
    title: "Active Jobs",
    Icon: JobIcon,
    to: "/hospital/active-jobs",
  },
  {
    index: 2,
    title: "Pending Jobs",
    Icon: clockIcon,
    to: "/hospital/pending-jobs",
  },
  {
    index: 3,
    title: "Hired Nurses",
    Icon: NurseIcon,
    to: "/hospital/hired-nurses",
  },
  {
    index: 4,
    title: "Completed Jobs",
    Icon: CompletedIcon,
    to: "/hospital/completed-jobs",
  },
];

const NurseMenus = [
  {
    index: 0,
    title: "Job Board",
    Icon: JobIcon,
    to: "/nurse/dashboard",
  },
  {
    index: 1,
    title: "Jobs Applied",
    Icon: EditIcon,
    to: "/nurse/applied-jobs",
  },
  {
    index: 2,
    title: "Completed Jobs",
    Icon: CompletedIcon,
    to: "/nurse/completed-jobs",
  },
];

export default function Sidebar(props) {
  const hospitalActiveMenu = useSelector(
    (state) => state.flags.hospitalMenuSelected
  );
  const nurseActiveMenu = useSelector(
    (state) => state.flags?.nurseMenuSelected
  );
  const [menuList, setMenuList] = useState([]);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const userType = useSelector((state) => state.user.userData?.userType);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const styles = {
    color: "#0d2645",
    fontSize: "16px",
    lineHeight: "18.5px",
    fontWeight: 500,
    margin: {
      xs: "15px 0",
      sm: "15px 0",
      md: "15px 0",
      lg: "15px",
      xl: "15px",
    },
    padding: "10px 20px",
    borderRadius: "10px",
    // marginLeft: "10px",
  };

  useEffect(() => {
    if (userType == "nurse") {
      setMenuList(NurseMenus);
    } else {
      setMenuList(hospitalMenus);
    }
  }, [userType]);

  const handleClickMenu = (item) => {
    if (userType == "hospital") {
      dispatch(setHospitalMenuSelected(item.index));
    } else {
      dispatch(setNurseMenuSelected(item.index));
    }
    navigate(item.to);
    props.setToggleMenu(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      sx={{
        alignItems: {
          xs: "flex-start",
          sm: "flex-start",
          md: "flex-start",
          lg: "flex-start",
          xl: "flex-start",
        },
        justifyContent: {
          xs: "center",
          sm: "center",
          md: "flex-start",
          lg: "flex-start",
          xl: "flex-start",
        },
      }}
      flexDirection={"column"}
    >
      <img
        className="mobile-iPad-d-none"
        src={MenuLogo}
        alt="app-logo"
        style={{ margin: "0 auto 30px" }}
      />
      <Box padding={0}>
        <MenuList variant="selectedMenu">
          {menuList.map((item) => {
            const selectedMenuActive =
              userType == "hospital" ? hospitalActiveMenu : nurseActiveMenu;
            const isActive = item.index === selectedMenuActive;
            return (
              <MenuItem
                key={item.title}
                onClick={() => handleClickMenu(item)}
                sx={{
                  ...styles,
                  ...(isActive && {
                    color: "#fff",
                    background: "#38879f",
                    padding: "10px 20px",
                    borderRadius: "10px",
                  }),
                  "&:hover": {
                    color: "#0d2645",
                    padding: "10px 20px",
                    borderRadius: "10px",
                  },
                }}
              >
                <ListItemIcon>
                  <img src={item.Icon} alt="app-icon" />
                </ListItemIcon>
                <Typography variant="inherit">{item.title}</Typography>
              </MenuItem>
            );
          })}
        </MenuList>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: "60px",
          left: "25px",
        }}
      >
        <Button
          onClick={() => setOpenLogoutModal(true)}
          sx={{
            color: "#0d2645",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "18.53px",
            textAlign: "left",
            display: "flex",
            justifyContent: "left",
            width: "194px",
            border: "2px solid #eff6f8",
            padding: "10px",
            borderRadius: "15px",
          }}
        >
          <img src={LogoutBtnIcon} alt="logout" />
          <Typography marginLeft={2}>Logout</Typography>
        </Button>
      </Box>
      <CommonModal
        title={
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: {
                xs: "18px",
                sm: "20px",
                md: "24px",
                lg: "28px",
                xl: "28px",
              },
            }}
            variant={"h5"}
          >
            Are you sure you want to logout from this account?
          </Typography>
        }
        open={openLogoutModal}
        icon={LogoutIcon}
        onSubmit={handleLogout}
        onClose={() => setOpenLogoutModal(false)}
        button1Text={"Yes"}
        button2Text={"No"}
      />
    </Box>
  );
}
