import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBreadcrumb } from "../../../redux/slices/breadcrumb_slice";
import ActiveCardIcon from "../../../assets/icons/active-card.svg";
import PendingCardIcon from "../../../assets/icons/pending-card.svg";
import HiredCardIcon from "../../../assets/icons/hired-card.svg";
import CompletedCardIcon from "../../../assets/icons/completed-card.svg";
import DashboardChart from "./DashboardChart";
import {
  setHospitalMenuSelected,
  setLoading,
} from "../../../redux/slices/flags_slice";
import {
  GetDashboardCounts,
  GetDashboardGraphData,
} from "../../../controller/services/hospital/dashboard";
import toast from "react-hot-toast";
import { sub, format } from "date-fns";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../common/no-data";
import EmptyIcon from "../../../assets/icons/empty-candidates.svg";
import ExportIcon from "../../../assets/icons/export.svg";
import * as XLSX from "xlsx";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
const cardsArr = [
  {
    title: "Active Jobs",
    icon: ActiveCardIcon,
    key: "activeJobs",
    route: "/hospital/active-jobs",
  },
  {
    title: "Pending Jobs",
    icon: PendingCardIcon,
    key: "pendingJobs",
    route: "/hospital/pending-jobs",
  },
  {
    title: "Hired Nurses",
    icon: HiredCardIcon,
    key: "hiredNurses",
    route: "/hospital/hired-nurses",
  },
  {
    title: "Completed Jobs",
    icon: CompletedCardIcon,
    key: "completedJobs",
    route: "/hospital/completed-jobs",
  },
];
const { RangePicker } = DatePicker;
export default function HospitalDashboard() {
  const dispatch = useDispatch();
  const [counts, setCounts] = useState(null);
  const navigate = useNavigate();
  const [graphData, setGraphData] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [modifiedChartData, setModifiedChartData] = useState([]);
  const states = useSelector((state) => state);
  console.log(states, "sfsfs");
  useEffect(() => {
    const arr = [
      {
        title: "Dashboard",
        route: "",
      },
    ];
    dispatch(setBreadcrumb(arr));
    getDashboardCounts();

    let startDate = sub(new Date(), { months: 6 });
    startDate = dayjs(startDate);
    const endDate = dayjs(new Date());
    getDashboardGraphData(startDate, endDate);
    setSelectedDates([startDate, endDate]);
  }, []);

  const getDashboardCounts = () => {
    dispatch(setLoading(true));
    GetDashboardCounts()
      .then((resp) => {
        setCounts(resp.data);
      })
      .catch((Err) => {
        toast.error(Err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const getDashboardGraphData = (startDate, endDate) => {
    const payload = {
      startDate: dayjs(startDate).hour(0).minute(0).utc().format(),
      endDate: dayjs(endDate).hour(23).minute(59).utc().format(),
    };
    dispatch(setLoading(true));
    GetDashboardGraphData(payload)
      .then((resp) => {
        setGraphData(resp.data.list);
      })
      .catch((Err) => {
        toast.error(Err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  const getData = () => {
    const data = [["Date", "Hired Nurses"]];
    modifiedChartData.forEach((i) => {
      const mappedArr = Object.values(i)?.map((item, idx) => {
        if (idx == 0) {
          return format(new Date(item), "MMM yyyy");
        }
        return item;
      });
      data.push(mappedArr);
    });
    console.log(data, "sfsfsfs");

    const ws = XLSX.utils.aoa_to_sheet(data);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const wbout = XLSX.write(wb, { type: "binary", bookType: "xlsx" });

    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Transparent-Care Hired Nurse Report from ${selectedDates[0].format(
      "DD MMM YYYY"
    )} to ${selectedDates[1].format("DD MMM YYYY")}.xlsx`;

    document.body.appendChild(link);
    link.click();
  };
  return (
    <Box>
      <Grid container spacing={2}>
        {cardsArr.map((i, idx) => {
          return (
            <Grid xs={6} sm={6} md={6} lg={4} xl={3} item key={i.route}>
              <Box
                textAlign={"left"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{
                  borderRadius: {
                    xs: "12px",
                    sm: "12px",
                    md: "18px",
                    lg: "25px",
                    xl: "25px",
                  },
                  background: "#fff",
                  padding: {
                    xs: "10px",
                    sm: "15px",
                    md: "20px",
                    lg: "20px",
                    xl: "25px",
                  },
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(i.route);
                  dispatch(setHospitalMenuSelected(idx + 1));
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "26px",
                        sm: "28px",
                        md: "30px",
                        lg: "38px",
                        xl: "38px",
                      },
                      fontWeight: 500,
                      lineHeight: {
                        xs: "35px",
                        sm: "38px",
                        md: "40px",
                        lg: "45.6px",
                        xl: "45.6px",
                      },
                      letterSpacing: "0.04em",
                      color: "#0D2645",
                    }}
                  >
                    {counts?.[i.key] || 0}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "16px",
                        lg: "18px",
                        xl: "18px",
                      },
                      fontWeight: 500,
                      lineHeight: "21.6px",
                      color: "#8592a1",
                      marginTop: {
                        xs: 0,
                        sm: 0,
                        md: "10px",
                        lg: "10px",
                        xl: "10px",
                      },
                    }}
                  >
                    {i.title}
                  </Typography>
                </Box>
                <img className="dashboard-icon-img" alt="" src={i.icon} />
              </Box>
            </Grid>
          );
        })}
        <Grid item xs={12} sm={12} lg={12} xl={12} md={12}>
          <Box
            bgcolor={"#fff"}
            marginTop={"20px"}
            padding={"20px"}
            borderRadius={"25px"}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              sx={{
                flexDirection: {
                  xs: "column",
                  sm: "row",
                  md: "row",
                  lg: "row",
                  xl: "row",
                },
                alignItems: {
                  xs: "flex-start",
                  sm: "flex-start",
                  md: "flex-start",
                  lg: "center",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "18px",
                    sm: "20px",
                    md: "22px",
                    lg: "24px",
                  },
                  fontWeight: 500,
                  lineHeight: "28.8px",
                  color: "#0D2645",
                  margin: {
                    xs: "10px 0",
                    sm: "10px 0 20px 10px",
                    md: "10px 0 20px 10px",
                    lg: "10px 0 20px 10px",
                    xl: "10px 0 20px 10px",
                  },
                }}
              >
                Hired Nurses
              </Typography>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <RangePicker
                  maxDate={dayjs(new Date())}
                  popupClassName="custom-range"
                  value={selectedDates}
                  allowClear={false}
                  onChange={(date, datestring) => {
                    const duration = date[1].diff(date[0], "days");
                    console.log(duration, "fsdfsdf");
                    if (duration <= 365) {
                      getDashboardGraphData(date[0], date[1]);
                      setSelectedDates(date);
                    } else {
                      toast.error("maximum duration can be selected as 1 year");
                    }
                  }}
                />
                <img
                  src={ExportIcon}
                  alt="export to csv"
                  style={{ marginLeft: "20px", cursor: "pointer" }}
                  onClick={getData}
                />
              </Box>
            </Box>
            <Box marginTop={"10px"}>
              {graphData?.length > 0 ? (
                <DashboardChart
                  graphData={graphData}
                  selectedDates={selectedDates}
                  handleModifiedData={(data) => setModifiedChartData(data)}
                />
              ) : (
                <NoDataFound
                  title="No Data Found"
                  height={"50vh"}
                  icon={EmptyIcon}
                />
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
