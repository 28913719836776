import axiosInstance from "../../api/axios";

export const GetHiredNurseList = async (payload) => {
  const res = await axiosInstance.post(`/api/v1/job/hired-nurse`, payload);
  return res.data;
};

export const GetHiredNurseDetails = async (id) => {
  const res = await axiosInstance.get(`/api/v1/job/view-hired-nurse/${id}`);
  return res.data;
};
