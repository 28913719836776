import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../redux/slices/flags_slice";
import { GetNotifications } from "../../../controller/services/common";
import toast from "react-hot-toast";
import { format, sub } from "date-fns";
import InfiniteScrollWrapper from "../infinite-scroll";
import EmptyIcon from "../../../assets/icons/empty-board.svg";
import NoDataFound from "../no-data";
import { setBreadcrumb } from "../../../redux/slices/breadcrumb_slice";
export default function Notifications() {
  const dispatch = useDispatch();
  const [notificationsList, setNotifications] = useState([]);
  const userData = useSelector((state) => state.user.userData);
  const [hasNextResult, setHasNextResult] = useState(0);
  const [page, setPage] = useState(1);
  useEffect(() => {
    getNotifications();
  }, [page]);

  useEffect(() => {
    const arr = [
      {
        title: "Notifications",
        route: "",
      },
    ];
    dispatch(setBreadcrumb(arr));
  }, []);
  const getNotifications = () => {
    const payload = {
      page: page,
      limit: 10,
    };
    dispatch(setLoading(true));
    GetNotifications(payload)
      .then((resp) => {
        if (page == 1) {
          setNotifications(resp.data.list);
        } else {
          setNotifications((prev) => [...prev, ...resp.data.list]);
        }
        setHasNextResult(resp.data?.hasResult);
      })
      .catch((Err) => {
        toast.error(Err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const getDateText = (date) => {
    if (
      format(new Date(date), "MMM dd, yyyy") ===
      format(new Date(), "MMM dd, yyyy")
    ) {
      return "Today";
    } else if (
      format(new Date(date), "MMM dd, yyyy") ===
      format(sub(new Date(), { days: 1 }), "MMM dd, yyyy")
    ) {
      return "Yesterday";
    } else {
      return format(new Date(date), "MMM dd, yyyy");
    }
  };
  return notificationsList?.length > 0 ? (
    <Box margin={"20px 15px"}>
      <InfiniteScrollWrapper
        handleSetPage={() => setPage((prev) => prev + 1)}
        hasResult={hasNextResult}
      >
        {notificationsList.map((item) => {
          const profilePic =
            userData?.userType == "nurse"
              ? item?.hospitalDetails?.hospitalLogo
              : item.nurseDetails?.profilePicture;
          return (
            <Box
              key={item._id}
              display={"flex"}
              justifyContent={"space-between"}
              margin={"15px"}
              padding={"15px"}
              sx={
                item?.isRead
                  ? {
                      background: "#f5f9fa",
                      borderRadius: "25px",
                    }
                  : {
                      background: "#ecf3f6",
                      border: "1.5px solid #92bcca",
                      borderRadius: "25px",
                    }
              }
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <img
                  src={profilePic}
                  alt="profile-img"
                  width={50}
                  style={{
                    borderRadius: "15px",
                  }}
                />
                <Typography
                  sx={{
                    marginLeft: "20px",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                  }}
                >
                  {item?.message}
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "16.8px",
                  color: "#76797b",
                }}
              >
                {getDateText(item?.createdAt)}
              </Typography>
            </Box>
          );
        })}
      </InfiniteScrollWrapper>
    </Box>
  ) : (
    <NoDataFound title={"No Notifications Available"} icon={EmptyIcon} />
  );
}
