import React, { useState } from "react";
import {Box, Button, Container, Typography} from "@mui/material";
import logoHorizontal from "../../assets/landing/logo-horizontal.svg";
import toggleMenu from "../../assets/icons/toggle-menu.svg";
import { Link, useNavigate } from "react-router-dom";

const CustomHeader = (props) => {
  const { homeID, howWeWorkID, faqID, whoWeID } = props;
  const [toggle, setToggle] = useState(false);
  const [activeLink, setActiveLink] = useState("home");
  const navigate = useNavigate();
  return (
    <>
      {/*SIDEBAR FOR MOBILE*/}
      <Box
        sx={{
          position: "fixed",
          left: {
            xs: toggle ? 0 : "-100%",
            sm: toggle ? 0 : "-100%",
            md: toggle ? 0 : "-100%",
            lg: 0,
            xl: 0,
          },
          top: "85px",
          background: "#fff",
          height: {
            xs: "calc(100vh - 85px)",
          },
          transition: "0.4s ease all",
          overflow: "auto",
          padding: "30px",
          width: "100%",
          zIndex: 10,
          display: {
            xs: "block",
            sm: "block",
            md: "block",
            lg: "none",
            xl: "none",
          },
        }}
      >
        <Box>
          <Box
            sx={{
              marginBottom: "30px",
              textAlign: "left",
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "20px",
                lineHeight: "24px",
                color: activeLink === "home" ? "#38879F" : "#000000",
                cursor: "pointer",
              }}
              variant="a"
              onClick={() => {
                setActiveLink("home");
                const elem = document.getElementById(homeID);
                elem.scrollIntoView({ behavior: "smooth" });
                setToggle(false);
              }}
            >
              Home
            </Typography>
          </Box>
          <Box
            sx={{
              marginBottom: "30px",
              textAlign: "left",
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "20px",
                lineHeight: "24px",
                color: activeLink === "whoweare" ? "#38879F" : "#000000",
                cursor: "pointer",
              }}
              variant="a"
              onClick={() => {
                setActiveLink("whoweare");
                const elem = document.getElementById(whoWeID);
                elem.scrollIntoView({ behavior: "smooth" });
                setToggle(false);
              }}
            >
              Who We Are
            </Typography>
          </Box>
          <Box
            sx={{
              marginBottom: "30px",
              textAlign: "left",
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "20px",
                lineHeight: "24px",
                color: activeLink === "howWeWork" ? "#38879F" : "#000000",
                cursor: "pointer",
              }}
              variant="a"
              onClick={() => {
                setActiveLink("howWeWork");
                const elem = document.getElementById(howWeWorkID);
                elem.scrollIntoView({ behavior: "smooth" });
                setToggle(false);
              }}
            >
              How We Work
            </Typography>
          </Box>
          <Box
            sx={{
              marginBottom: "30px",
              textAlign: "left",
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "20px",
                lineHeight: "24px",
                color: activeLink === "faq" ? "#38879F" : "#000000",
                cursor: "pointer",
              }}
              variant="a"
              onClick={() => {
                setActiveLink("faq");
                const elem = document.getElementById(faqID);
                elem.scrollIntoView({ behavior: "smooth" });
                setToggle(false);
              }}
            >
              FAQs
            </Typography>
          </Box>
          <Box
            sx={{
              marginBottom: "30px",
              textAlign: "left",
            }}
          >
            <Link
              to="mailto:transparentcare2024@gmail.com"
              style={{ color: "transparent" }}
              onClick={() => {
                setActiveLink("email");
                setToggle(false);
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: activeLink === "email" ? "#38879F" : "#000000",
                  border: "none",
                }}
                variant="a"
              >
                Email Us
              </Typography>
            </Link>
          </Box>
          <Box
            sx={{
              marginBottom: "30px",
            }}
          >
            <Button
              sx={{
                height: "54px",
                width: "154px",
                borderRadius: "10px",
                border: "1px solid #38879F",
                background: "transparent",
                fontSize: "18px",
                lineHeight: "21.6px",
                fontWeight: 700,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#38879F",
                boxShadow: " 0 4px 16px 0 #00000026",
              }}
              onClick={() => {
                navigate("/role-selection", {
                  state: {
                    type: "signin",
                  },
                });
              }}
            >
              Sign In
            </Button>
          </Box>
          <Box
            sx={{
              marginBottom: "30px",
            }}
          >
            <Button
              sx={{
                height: "54px",
                width: "154px",
                borderRadius: "10px",
                border: "1px solid #38879F",
                background: "#38879F",
                fontSize: "18px",
                lineHeight: "21.6px",
                fontWeight: 700,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                boxShadow: " 0 4px 16px 0 #00000026",
                "&:hover": {
                  border: "1px solid #38879F",
                  background: "#38879F",
                },
              }}
              onClick={() => {
                navigate("/role-selection", {
                  state: {
                    type: "signup",
                  },
                });
              }}
            >
              Sign Up
            </Button>
          </Box>
        </Box>
      </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              background: "#fff",
              width: "100%",
              height: {
                xs: "85px",
                sm: "100px",
                md: "100px",
                lg: "120px",
                xl: "120px",
              },
              position: "fixed",
              left: 0,
              top: 0,
              zIndex: 9,
            }}
          >
              <Container maxWidth="xl">
                <Box sx={{
                    display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
                }}>
                    <Box>
              <img className="logo-custom" src={logoHorizontal} alt="" />
            </Box>
                    <Box
                      onClick={() => setToggle((prev) => !prev)}
                      sx={{
                        display: {
                          xs: "flex",
                          sm: "flex",
                          md: "flex",
                          lg: "none",
                          xl: "none",
                        },
                        cursor: "pointer",
                      }}
                    >
                      <img src={toggleMenu} alt="" className="menu-toggle" />
                    </Box>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "none",
                          lg: "flex",
                          xl: "flex",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: {
                              lg: "15px",
                              xl: "20px",
                            },
                            lineHeight: "24px",
                            color: activeLink === "home" ? "#38879F" : "#000000",
                            marginRight: {
                              lg: "20px",
                              xl: "50px",
                            },
                            cursor: "pointer",
                          }}
                          variant="a"
                          onClick={() => {
                            setActiveLink("home");
                            const elem = document.getElementById(homeID);
                            elem.scrollIntoView({ behavior: "smooth" });
                          }}
                        >
                          Home
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: {
                              lg: "15px",
                              xl: "20px",
                            },
                            lineHeight: "24px",
                            color: activeLink === "whoweare" ? "#38879F" : "#000000",
                            marginRight: {
                              lg: "20px",
                              xl: "50px",
                            },
                            cursor: "pointer",
                          }}
                          variant="a"
                          onClick={() => {
                            setActiveLink("whoweare");
                            const elem = document.getElementById(whoWeID);
                            elem.scrollIntoView({ behavior: "smooth" });
                          }}
                        >
                          Who We Are
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: {
                              lg: "15px",
                              xl: "20px",
                            },
                            lineHeight: "24px",
                            color: activeLink === "howWeWork" ? "#38879F" : "#000000",
                            marginRight: {
                              lg: "20px",
                              xl: "50px",
                            },
                            cursor: "pointer",
                          }}
                          variant="a"
                          onClick={() => {
                            setActiveLink("howWeWork");
                            const elem = document.getElementById(howWeWorkID);
                            elem.scrollIntoView({ behavior: "smooth" });
                          }}
                        >
                          How We Work
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: {
                              lg: "15px",
                              xl: "20px",
                            },
                            lineHeight: "24px",
                            color: activeLink === "faq" ? "#38879F" : "#000000",
                            marginRight: {
                              lg: "20px",
                              xl: "50px",
                            },
                            cursor: "pointer",
                          }}
                          variant="a"
                          onClick={() => {
                            setActiveLink("faq");
                            const elem = document.getElementById(faqID);
                            elem.scrollIntoView({ behavior: "smooth" });
                          }}
                        >
                          FAQs
                        </Typography>
                        <Link
                          to="mailto:contact@transparent-care.com"
                          style={{ color: "transparent", marginRight: "20px" }}
                          onClick={() => {
                            setActiveLink("email");
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontSize: {
                                lg: "15px",
                                xl: "20px",
                              },
                              lineHeight: "24px",
                              color: activeLink === "email" ? "#38879F" : "#000000",
                              border: "none",
                            }}
                            variant="a"
                          >
                            Email Us
                          </Typography>
                        </Link>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          sx={{
                            marginRight: "20px",
                            height: "54px",
                            width: "154px",
                            borderRadius: "10px",
                            border: "1px solid #38879F",
                            background: "transparent",
                            fontSize: "18px",
                            lineHeight: "21.6px",
                            fontWeight: 700,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#38879F",
                            boxShadow: " 0 4px 16px 0 #00000026",
                          }}
                          onClick={() => {
                            navigate("/role-selection", {
                              state: {
                                type: "signin",
                              },
                            });
                          }}
                        >
                          Sign In
                        </Button>
                        <Button
                          sx={{
                            height: "54px",
                            width: "154px",
                            borderRadius: "10px",
                            border: "1px solid #38879F",
                            background: "#38879F",
                            fontSize: "18px",
                            lineHeight: "21.6px",
                            fontWeight: 700,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#fff",
                            boxShadow: " 0 4px 16px 0 #00000026",
                            "&:hover": {
                              border: "1px solid #38879F",
                              background: "#38879F",
                            },
                          }}
                          onClick={() => {
                            navigate("/role-selection", {
                              state: {
                                type: "signup",
                              },
                            });
                          }}
                        >
                          Sign Up
                        </Button>
                      </Box>
                    </Box>
                </Box>
              </Container>
          </Box>
    </>
  );
};

export default CustomHeader;
