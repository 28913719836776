import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  ListItem,
  ListItemButton,
  ListItemText,
  MobileStepper,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import AppLogo from "../../../assets/icons/app-logo.svg";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../../assets/icons/back-dark.svg";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import StyledInput from "../../common/styled/input";
import { object, string } from "yup";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import toast from "react-hot-toast";
import {
  createProfile,
  getProfile,
  sendPhoneVerification,
  verifyPhoneOtp,
} from "../../../controller/services/hospital/profile";
import OTPInput from "react-otp-input";
import "./index.css";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { getAddressObject } from "../../../utils/helper";
import ContactIcon from "../../../assets/icons/contact.svg";
import LocationIcon from "../../../assets/icons/location.svg";
import CameraIcon from "../../../assets/icons/camera.svg";
import { FileUpload } from "../../../controller/services/common";
import { useDispatch } from "react-redux";
import { setKey, geocode, RequestType } from "react-geocode";
import { setUserData } from "../../../redux/slices/user_slice";
import { setNotificationDot } from "../../../redux/slices/flags_slice";

export default function ProfileLayout() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [currentValues, setCurrentValues] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [showList, setShowList] = useState(false);
  const stepper = [
    {
      step: 0,
      component: InputName,
      title: "Profile owners first and last name",
    },
    {
      step: 1,
      component: PhoneInputComp,
      title: "Enter your phone number",
    },
    {
      step: 2,
      component: OtpVerification,
      title: "Verify your Phone Number",
      desc: "We have sent a verification code to your phone number. Please enter the code below",
    },
    {
      step: 3,
      component: HospitalName,
      title: "Institution Name",
    },
    {
      step: 4,
      component: LogoUpload,
      title: "Hospital Logo",
      desc: "",
    },
    {
      step: 5,
      component: PlacesInputs,
      title: "Hospital Address",
    },
    {
      step: 6,
      component: HospitalDescription,
      title: "Description of the Institution",
    },
    {
      step: 7,
      component: SocialInputs,
      title: "Social Media Links",
      desc: "At least one social media link is necessary",
    },
  ];
  const Item = stepper.find((i) => i.step === currentStep);
  const handleCurrentStep = (values, IS_FINAL) => {
    setCurrentValues((prev) => {
      return { ...prev, ...values };
    });
    if (IS_FINAL) {
      setLoading(true);
      const payload = {
        firstName: currentValues.firstName,
        lastName: currentValues.lastName,
        address: currentValues.address,
        city: currentValues.city,
        hospitalName: currentValues.hospitalName,
        description: currentValues.description,
        hospitalLogo: currentValues.logoLink,
        state: currentValues.province,
        zip: currentValues.postalCode,
        socialMediaLink: {
          linkedin: values?.linkedin || "",
          instagram: values?.instagram || "",
          website: values?.website || "",
        },
      };
      createProfile(payload)
        .then((resp) => {
          getProfileData();
          navigate("/hospital/approval-status");
          toast.success(resp.message);
        })
        .catch((Err) => {
          toast.error(Err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setCurrentStep((prev) => prev + 1);
  };

  const getProfileData = () => {
    getProfile()
      .then((resp) => {
        dispatch(setUserData(resp.data?.user));
        dispatch(setNotificationDot(resp.data?.unreadNotificationCount > 0));
      })
      .catch((Err) => {
        toast.error(Err.message);
      });
  };

  return (
    <Box
      className="custom-stepper-div"
      sx={{
        padding: {
          xs: "30px 15px",
          sm: "0 15px",
          md: "0 60px",
          lg: "0 60px",
          xl: "0 70px",
        },
        height: {
          xs: "auto",
          sm: "100vh",
          md: "100vh",
          lg: "100vh",
          xl: "100vh",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <Box
          border={2}
          borderColor={"#38879F"}
          borderRadius={5}
          position="relative"
          width={"100%"}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => setShowList(false)}
        >
          <Box
            sx={{
              padding: {
                xs: "30px 20px",
                sm: "30px",
                md: "30px",
                lg: "30px",
                xl: "45px",
              },
              width: {
                xs: "100%",
                sm: "100%",
                md: "70%",
                lg: "35%",
                xl: "35%",
              },
            }}
          >
            {currentStep != 0 && (
              <img
                src={BackIcon}
                alt="back"
                style={{
                  position: "absolute",
                  left: "8vw",
                  top: "8vh",
                  cursor: "pointer",
                }}
                className="back-icon"
                onClick={() => setCurrentStep((prev) => prev - 1)}
              />
            )}
            <img className="app-logo-role" src={AppLogo} alt="logo" />
            <MobileStepper
              color="#38879f"
              variant="progress"
              steps={8}
              position="static"
              activeStep={currentStep}
              LinearProgressProps={{
                style: {
                  background: "#eaf5f2",
                  borderRadius: "25px",
                  height: "6px",
                },
              }}
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "100%",
                  xl: "100%",
                },
                marginTop: "10px",
                padding: "20px 0",
                borderRadius: "25px",
                height: "6px",
                "& .MuiLinearProgress-bar": {
                  background: "#38879f",
                  borderRadius: "25px",
                },
              }}
              // sx={{ width: 750, flexGrow: 1, color: "#38879f" }}
            />

            {currentStep <= 7 && (
              <>
                <Typography
                  variant="h3"
                  color="#0d2645"
                  fontWeight={600}
                  lineHeight={"31.2px"}
                  padding={"10px 0"}
                  sx={{
                    fontSize: {
                      xs: "20px",
                      sm: "24px",
                      md: "24px",
                      lg: "24px",
                      xl: "24px",
                    },
                  }}
                >
                  {Item.title}
                </Typography>
                <Typography
                  variant="h6"
                  color="#8391a0"
                  fontSize={16}
                  fontWeight={400}
                  lineHeight={"25px"}
                  padding={currentStep == 7 ? "10px" : 0}
                  sx={{
                    fontSize: {
                      xs: "12px",
                    },
                  }}
                >
                  {Item?.desc}
                </Typography>
                <Item.component
                  handleCurrentStep={handleCurrentStep}
                  currentValues={currentValues}
                  showList={showList}
                  setShowList={(val) => setShowList(val)}
                />
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

const InputName = ({ handleCurrentStep, currentValues }) => {
  const formik = useFormik({
    initialValues: {
      firstName: currentValues?.firstName,
      lastName: currentValues?.lastName,
    },
    validationSchema: object({
      firstName: string()
        .required("This field is required")
        .matches(/^[A-Za-z ]*$/, "Please enter valid name")
        .min(3, "First Name must be at least 3 characters")
        .max(15, "First Name must be at most 15 characters"),
      lastName: string()
        .required("This field is required")
        .matches(/^[A-Za-z ]*$/, "Please enter valid name")
        .min(3, "First Name must be at least 3 characters")
        .max(15, "First Name must be at most 15 characters"),
    }),
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = (values) => {
    handleCurrentStep(values);
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <StyledInput
        name={"firstName"}
        placeholder={"Enter First Name"}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        errors={formik.errors}
        touched={formik.touched}
        value={formik.values.firstName}
      />
      <StyledInput
        name={"lastName"}
        placeholder={"Enter Last Name"}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        errors={formik.errors}
        touched={formik.touched}
        value={formik.values.lastName}
      />
      <Button
        type="submit"
        disabled={
          formik.isSubmitting ||
          Object.entries(formik.errors).length > 0 ||
          Object.values(formik.values).every((i) => !i)
        }
        sx={{
          width: "100%",
          height: "55px",
          marginTop: "15px",
          background: "#38879f",
          color: "#fff",
          fontWeight: 600,
          borderRadius: "10px",
          "&.Mui-disabled": {
            background: "#adcdd7",
            color: "#fff",
          },
          "&:hover": {
            backgroundColor: "#38879f",
          },
        }}
      >
        Next
      </Button>
    </form>
  );
};

const PhoneInputComp = ({ handleCurrentStep, currentValues }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");

  useEffect(() => {
    if (currentValues?.phoneNumber) {
      setPhoneNumber(currentValues?.phoneNumber);
      setCountryCode(currentValues?.countryCode);
    }
  }, [currentValues?.phoneNumber]);

  const handleSubmit = () => {
    const payload = {
      countryCode,
      phone: phoneNumber,
    };
    sendPhoneVerification(payload)
      .then((resp) => {
        handleCurrentStep({ phoneNumber, countryCode });
        toast.success(resp.message);
      })
      .catch((Err) => {
        toast.error(Err.message);
      });
  };
  return (
    <Box>
      <PhoneInput
        inputExtraProps={{
          placeholder: "Enter phone number",
          searchPlaceholder: "Enter phone number",
        }}
        inputProps={{
          name: "mobileNo",
        }}
        value={countryCode + phoneNumber}
        renderStringAsFlag=""
        placeholder="Enter phone number"
        searchPlaceholder="Enter phone number"
        country="ca"
        enableAreaCodes={false}
        copyNumbersOnly={false}
        buttonStyle={{ background: "none", border: "none" }}
        inputStyle={{
          padding: "10px 5px 10px 40px",
          background: "#eff6f8",
          color: "#0d2645",
          width: "100%",
          height: "50px",
          borderRadius: "10px",
        }}
        onChange={(value, country, e, formattedValue) => {
          setCountryCode(country?.dialCode);
          setPhoneNumber(value.slice(country?.dialCode?.length));
        }}
      />
      <Button
        // type="submit"
        onClick={handleSubmit}
        disabled={phoneNumber?.length < 10}
        sx={{
          width: "100%",
          height: "55px",
          marginTop: "15px",
          background: "#38879f",
          color: "#fff",
          fontWeight: 600,
          borderRadius: "10px",
          "&.Mui-disabled": {
            background: "#adcdd7",
            color: "#fff",
          },
          "&:hover": {
            backgroundColor: "#38879f",
          },
        }}
      >
        Next
      </Button>
    </Box>
  );
};

const OtpVerification = ({ handleCurrentStep, currentValues }) => {
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(30);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (otp?.length === 6) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [otp?.length]);
  const handleSubmit = () => {
    setDisabled(true);
    const payload = { otp };
    verifyPhoneOtp(payload)
      .then((resp) => {
        handleCurrentStep({});
        toast.success(resp.message);
      })
      .catch((Err) => {
        setDisabled(true);
        toast.error(Err.message);
      });
  };

  const handleResend = () => {
    const payload = {
      countryCode: currentValues?.countryCode,
      phone: currentValues?.phoneNumber,
    };
    sendPhoneVerification(payload)
      .then((resp) => {
        setTimer(30);
        toast.success(resp.message);
      })
      .catch((Err) => {
        toast.error(Err.message);
      });
  };

  useEffect(() => {
    const intervalID = setInterval(() => {
      if (timer != 0) {
        setTimer((prev) => prev - 1);
      } else setTimer(0);
    }, 1000);
    return () => {
      clearInterval(intervalID);
    };
  }, [timer]);

  return (
    <Box className="custom-otp-input">
      <OTPInput
        value={otp}
        onChange={setOtp}
        numInputs={6}
        renderInput={(props) => <input {...props} />}
        inputStyle={{
          background: "#ebf3f6",
          width: "50px",
          height: "50px",
          borderRadius: "15px",
          color: "#0d2645",
          textAlign: "center",
          border: "none",
          fontSize: "15px",
          outline: "none",
          margin: "0 5px",
        }}
        placeholder="------"
        containerStyle={{
          marginTop: "25px",
          justifyContent: "center",
        }}
      />

      <Typography
        fontSize={"14px"}
        color="#8592a1"
        marginTop={"15px"}
        marginBottom={"10px"}
        sx={
          timer != 0
            ? {
                opacity: 0.7,
              }
            : {}
        }
      >
        Have you received the code? &nbsp;
        <span
          style={{
            fontWeight: 600,
            color: "#38879f",
            cursor: timer == 0 ? "pointer" : "default",
          }}
          onClick={() => {
            if (timer == 0) {
              handleResend();
            }
          }}
        >
          Resend
        </span>
        <br />
        {timer != 0 && (
          <span style={{ color: "#0d2645", marginTop: "10px", opacity: 1 }}>
            00:{timer}
          </span>
        )}
      </Typography>
      <Button
        onClick={handleSubmit}
        sx={{
          width: "80%",
          height: "55px",
          marginTop: "15px",
          background: "#38879f",
          color: "#fff",
          fontWeight: 600,
          borderRadius: "10px",
          "&.Mui-disabled": {
            background: "#adcdd7",
            color: "#fff",
          },
          "&:hover": {
            backgroundColor: "#38879f",
          },
        }}
        disabled={disabled}
      >
        Verify
      </Button>
    </Box>
  );
};

const HospitalName = ({ handleCurrentStep, currentValues }) => {
  const formik = useFormik({
    initialValues: {
      hospitalName: currentValues?.hospitalName,
    },
    validationSchema: object({
      hospitalName: string()
        .required("This field is required")
        .matches(/^[A-Za-z0-9 ]*$/, "Please enter valid name")
        .min(3, "Hospital Name must be at least 3 characters")
        .max(55, "Hospital Name must be at most 55 characters"),
    }),
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = (values) => {
    handleCurrentStep(values);
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <StyledInput
        name={"hospitalName"}
        placeholder={"Enter Hospital Name"}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        errors={formik.errors}
        touched={formik.touched}
        value={formik.values.hospitalName}
      />
      <Button
        type="submit"
        disabled={
          formik.isSubmitting ||
          Object.entries(formik.errors).length > 0 ||
          !formik.values.hospitalName
        }
        sx={{
          width: "100%",
          height: "55px",
          marginTop: "15px",
          background: "#38879f",
          color: "#fff",
          fontWeight: 600,
          borderRadius: "10px",
          "&.Mui-disabled": {
            background: "#adcdd7",
            color: "#fff",
          },
          "&:hover": {
            backgroundColor: "#38879f",
          },
        }}
      >
        Next
      </Button>
    </form>
  );
};

const SocialInputs = ({ handleCurrentStep, currentValues }) => {
  const formik = useFormik({
    initialValues: {
      website: currentValues?.website,
      linkedin: currentValues?.linkedin,
      instagram: currentValues?.instagram,
    },
    validationSchema: object({
      website: string().url(),
      linkedin: string().url(),
      instagram: string().url(),
    }),
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = (values) => {
    handleCurrentStep(values, true);
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <StyledInput
        name={"linkedin"}
        placeholder={"Enter Linkedin Link"}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        errors={formik.errors}
        touched={formik.touched}
        value={formik.values.linkedin}
      />
      <StyledInput
        name={"website"}
        placeholder={"Enter Website Link"}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        errors={formik.errors}
        touched={formik.touched}
        value={formik.values.website}
      />
      <StyledInput
        name={"instagram"}
        placeholder={"Enter Instagram Link"}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        errors={formik.errors}
        touched={formik.touched}
        value={formik.values.instagram}
      />
      <Button
        type="submit"
        disabled={
          formik.isSubmitting ||
          Object.entries(formik.errors).length > 0 ||
          (!formik.values?.website &&
          !formik.values?.linkedin &&
          !formik.values?.instagram
            ? true
            : false)
        }
        sx={{
          width: "100%",
          height: "55px",
          marginTop: "15px",
          background: "#38879f",
          color: "#fff",
          fontWeight: 600,
          borderRadius: "10px",
          "&.Mui-disabled": {
            background: "#adcdd7",
            color: "#fff",
          },
          "&:hover": {
            backgroundColor: "#38879f",
          },
        }}
      >
        Next
      </Button>
    </form>
  );
};

const HospitalDescription = ({ handleCurrentStep, currentValues }) => {
  const formik = useFormik({
    initialValues: {
      description: currentValues?.description,
    },
    validationSchema: object({
      description: string()
        .required("This field is required")
        .min(3, "Hospital Name must be at least 3 characters")
        .max(255, "Hospital Name must be at most 255 characters"),
    }),
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = (values) => {
    handleCurrentStep(values);
  };
  return (
    <form className="hospital-descripation" onSubmit={formik.handleSubmit}>
      <TextareaAutosize
        name="description"
        className="area-desc"
        placeholder="Tell us what makes you great !"
        minRows={4}
        onChange={formik.handleChange}
        style={{
          padding: "10px 5px 10px 20px",
          background: "#eff6f8",
          width: "94%",
          border: "none",
          height: "30px",
          borderRadius: "20px",
          margin: "10px 0",
          fontFamily: "Lato, sans-serif",
          fontSize: "15px",
        }}
      />
      {formik.errors["description"] && formik.touched["description"] && (
        <Typography textAlign={"left"} variant="body2" color="red">
          {formik.errors["description"]}
        </Typography>
      )}
      <Button
        type="submit"
        disabled={
          formik.isSubmitting ||
          Object.entries(formik.errors).length > 0 ||
          !formik.values.description
        }
        sx={{
          width: "100%",
          height: "55px",
          marginTop: "15px",
          background: "#38879f",
          color: "#fff",
          fontWeight: 600,
          borderRadius: "10px",
          "&.Mui-disabled": {
            background: "#adcdd7",
            color: "#fff",
          },
          "&:hover": {
            backgroundColor: "#38879f",
          },
        }}
      >
        Next
      </Button>
    </form>
  );
};

const PlacesInputs = ({
  handleCurrentStep,
  currentValues,
  showList,
  setShowList,
}) => {
  setKey(process.env.REACT_APP_PLACES_KEY);
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      address: currentValues?.address || "",
      city: currentValues.city || "",
      province: currentValues.province || "",
      postalCode: currentValues.postalCode || "",
    },
    validationSchema: object({
      address: string().required("This field is required"),
      city: string().required("This field is required"),
      province: string().required("This field is required"),
      postalCode: string().required("This field is required"),
    }),
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = (values) => {
    handleCurrentStep(values);
  };

  const fetchLocation = () => {
    try {
      setIsLoading(true);
      navigator.geolocation.getCurrentPosition((position) => {
        const coords = `${position.coords.latitude},${position.coords.longitude}`;
        getCurrentAddress(coords);
      });
    } catch {
      setIsLoading(false);
      toast.error("Something went wrong!");
    }
  };

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: process.env.REACT_APP_PLACES_KEY,
    // options: {
    //   componentRestrictions: { country: "IN" },
    // },
  });

  const getCurrentAddress = (coords) => {
    geocode(RequestType.LATLNG, coords, {
      location_type: "ROOFTOP", // Override location type filter for this request.
      enable_address_descriptor: true, // Include address descriptor in response.
    })
      .then(({ results }) => {
        const { city, state, postal_code, street_address } =
          results[0].address_components.reduce((acc, component) => {
            if (component.types.includes("locality"))
              acc.city = component.long_name;
            else if (component.types.includes("administrative_area_level_1"))
              acc.state = component.long_name;
            else if (component.types.includes("country"))
              acc.country = component.long_name;
            else if (component.types.includes("postal_code")) {
              acc.postal_code = component.long_name;
            } else if (component.types.includes("route")) {
              acc.street_address = component.long_name;
            }
            return acc;
          }, {});
        formik.setValues({
          city,
          province: state,
          postalCode: postal_code,
          address: street_address,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getDetails = (place_id) => {
    setShowList(false);
    placesService?.getDetails(
      {
        placeId: place_id,
      },
      (placeDetails) => {
        const address = getAddressObject(placeDetails?.address_components);
        formik.setValues({
          address: placeDetails?.name,
          city: address.city,
          province: address.region,
          postalCode: address.postal_code,
        });
      }
    );
  };

  return (
    <Box>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: 10,
          borderRadius: "50px",
          background: "transparent",
        }}
        open={isLoading}
      >
        <CircularProgress />
      </Backdrop>
      <form onSubmit={formik.handleSubmit}>
        <Box position={"relative"}>
          <StyledInput
            name={"address"}
            placeholder={"Enter Address"}
            handleChange={(e) => {
              formik.handleChange(e);
              getPlacePredictions({ input: e.target.value });
              setShowList(true);
            }}
            handleBlur={formik.handleBlur}
            errors={formik.errors}
            touched={formik.touched}
            value={formik.values.address}
            endAdornment={
              <InputAdornment
                position="end"
                sx={{ paddingRight: "10px", cursor: "pointer" }}
              >
                <img
                  src={LocationIcon}
                  alt="location"
                  onClick={fetchLocation}
                  style={{ cursor: "pointer" }}
                />
              </InputAdornment>
            }
          />
          {!isPlacePredictionsLoading &&
            showList &&
            formik.values.address &&
            placePredictions?.length > 0 && (
              <Box
                sx={{
                  width: "100%",
                  height: 200,
                  // maxWidth: 360,
                  overflow: "auto",
                  border: "1px solid #e9e9e9",
                  borderRadius: "0 0 10px 10px",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  bgcolor: "#fff",
                  margin: "auto",
                  zIndex: 9,
                }}
                position={"absolute"}
                top={50}
                left={0}
                right={0}
              >
                {placePredictions.map((item) => {
                  return (
                    <ListItem
                      onClick={() => getDetails(item?.place_id)}
                      component="div"
                      disablePadding
                      key={item.place_id}
                    >
                      <ListItemButton>
                        <ListItemText primary={item.description} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </Box>
            )}
        </Box>
        <StyledInput
          name={"city"}
          placeholder={"Enter City"}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          errors={formik.errors}
          touched={formik.touched}
          value={formik.values.city}
        />
        <Box display={"flex"}>
          <StyledInput
            name={"province"}
            placeholder={"Enter Province"}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            errors={formik.errors}
            touched={formik.touched}
            value={formik.values.province}
            style={{ width: "50%", marginRight: "10px" }}
          />
          <StyledInput
            name={"postalCode"}
            placeholder={"Enter Postal Code"}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            errors={formik.errors}
            touched={formik.touched}
            value={formik.values.postalCode}
            style={{ width: "50%", marginLeft: "10px" }}
          />
        </Box>
        <Button
          type="submit"
          disabled={
            formik.isSubmitting ||
            Object.entries(formik.errors).length > 0 ||
            Object.values(formik.values).every((i) => !i)
          }
          sx={{
            width: "100%",
            height: "55px",
            marginTop: "15px",
            background: "#38879f",
            color: "#fff",
            fontWeight: 600,
            borderRadius: "10px",
            "&.Mui-disabled": {
              background: "#adcdd7",
              color: "#fff",
            },
            "&:hover": {
              backgroundColor: "#38879f",
            },
          }}
        >
          Next
        </Button>
      </form>
    </Box>
  );
};

const LogoUpload = ({ handleCurrentStep, currentValues }) => {
  const [fileLink, setFileLink] = useState(currentValues?.logoLink);
  const handleFileUpload = (e) => {
    if (e.target.files[0]?.size > 2000000) {
      toast.error("File size should be less than or equal to 2MB.");
    } else {
      const formData = new FormData();
      if (e.target.files[0]) {
        formData.append("file", e.target.files[0]);
        formData.append("folder", "profile");
        FileUpload(formData)
          .then((resp) => {
            setFileLink(resp.data.data?.Location);
          })
          .catch((Err) => {
            toast.error(Err.message);
          });
      }
    }
  };

  const handleSubmit = () => {
    handleCurrentStep({ logoLink: fileLink });
  };
  return (
    <Box>
      <label
        style={{ position: "relative" }}
        className="ulpoad-label"
        for="file-upload"
      >
        <img
          className="flie-upload-img"
          src={fileLink ? fileLink : ContactIcon}
          alt="upload-logo"
          style={{
            width: 140,
            height: "140px",
            // padding: "20px 0",
            borderRadius: "50%",
          }}
        />
        <img
          className="camera-imgg"
          src={CameraIcon}
          alt="upload-logo"
          style={{ position: "absolute", right: "-15px", bottom: "15px" }}
        />
      </label>
      <input
        type="file"
        id="file-upload"
        itemType=""
        style={{
          display: "none",
        }}
        accept="image/*"
        onChange={handleFileUpload}
        // inputComponent={<img src={ContactIcon} alt="upload-logo" />}
      />
      <Button
        onClick={handleSubmit}
        disabled={!fileLink}
        sx={{
          width: "80%",
          height: "55px",
          marginTop: "15px",
          background: "#38879f",
          color: "#fff",
          fontWeight: 600,
          borderRadius: "10px",
          "&.Mui-disabled": {
            background: "#adcdd7",
            color: "#fff",
          },
          "&:hover": {
            backgroundColor: "#38879f",
          },
        }}
      >
        Next
      </Button>
    </Box>
  );
};
