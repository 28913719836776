import React from "react";
import {Box, Container, styled, Typography} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import plusIcon from "../../assets/landing/plus-icon.svg";
import minusIcon from "../../assets/landing/minus-icon.svg";

const CustomAccordion = styled(Accordion)(({ theme }) => {
  return {
    boxShadow: "none", // this styles directly apply to accordion
    borderBottom: `1px solid #38879F`,
    background: "transparent",
    borderRadius: "0 !important",
    margin: "0 !important",
    "&:before": {
      display: "none",
    },
    ".MuiAccordionDetails-root": {
      padding: "10px 50px 30px",
    },
    ".MuiAccordionSummary-root": {
      padding: "30px 10px 30px 45px",
      ".MuiAccordionSummary-content": {
        margin: 0,
      },
    },
    ".MuiAccordionSummary-expandIconWrapper": {
      position: "absolute",
      left: 0,
    },
  };
});

const FrequentlyAskedQuestions = (props) => {
  const { faqID } = props;
  const [expanded, setExpanded] = React.useState([]);

  const handleExpansion = (key) => {
    let keysArr = [...expanded];
    if (keysArr.includes(key)) {
      keysArr = keysArr.filter((i) => i != key);
    } else {
      keysArr.push(key);
    }
    setExpanded(keysArr);
  };
  return (
    <>
      <Box id={faqID} sx={{
         scrollMarginTop: "90px",
          background: "#38879F0A",
      }}>
          <Container maxWidth="xl">
              <Box
                sx={{
                  padding: {
                    xs: "30px 0",
                    sm: "30px 0",
                    md: "30px 0",
                    lg: "80px 0",
                    xl: "80px 0",
                  },
                }}
              >
                <Box
                  sx={{
                    paddingBottom: "50px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "18px",
                        sm: "22px",
                        md: "24px",
                        lg: "35px",
                        xl: "35px",
                      },
                      fontWeight: 600,
                      lineHeight: {
                        xs: "28px",
                        sm: "36px",
                        md: "38px",
                        lg: "42px",
                        xl: "42px",
                      },
                      color: "#0D2645",
                    }}
                    variant="h3"
                  >
                    Frequently Asked
                    <Typography
                      sx={{
                        color: "#38879F",
                      }}
                      variant="span"
                    >
                      {" "}
                      Questions
                    </Typography>
                  </Typography>
                </Box>
                <Box>
                  <CustomAccordion
                    expanded={expanded.includes(1)}
                    onChange={() => handleExpansion(1)}
                    slotProps={{ transition: { timeout: 400 } }}
                    sx={{
                      "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
                      "& .MuiAccordionDetails-root": {
                        display: expanded.includes(1) ? "block" : "none",
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded.includes(1) ? (
                          <img src={minusIcon} alt="" />
                        ) : (
                          <img src={plusIcon} alt="" />
                        )
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "20px",
                            sm: "20px",
                            md: "26px",
                            lg: "26px",
                            xl: "26px",
                          },
                          lineHeight: {
                            xs: "25px",
                            sm: "25px",
                            md: "30px",
                            lg: "30px",
                            xl: "30px",
                          },
                          fontWeight: 600,
                          color: "#0F172A",
                          textAlign: "left",
                        }}
                      >
                        How does Transparent Care ensure the quality of nurses?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        sx={{
                          paddingLeft: {
                            xs: "10px",
                            sm: "10px",
                            md: "20px",
                            lg: "20px",
                            xl: "20px",
                          },
                          color: "rgba(56, 135, 159, 0.75)",
                          fontSize: {
                            xs: "16px",
                            sm: "16px",
                            md: "18px",
                            lg: "18px",
                            xl: "18px",
                          },
                          lineHeight: {
                            xs: "26px",
                            sm: "26px",
                            md: "34px",
                            lg: "34px",
                            xl: "34px",
                          },
                          fontWeight: 400,
                          borderLeft: "2px solid #38879F",
                          textAlign: "left",
                        }}
                      >
                        Both hospitals and candidates must maintain a certain cumulative
                        star rating to retain access to our services. This requirement
                        ensures that only the highest quality interactions occur through
                        Transparent Care, fostering a network of excellence and
                        reliability. This rigorous standard distinguishes us from our
                        competition, demonstrating our commitment to quality and
                        effective healthcare staffing solutions.
                      </Typography>
                    </AccordionDetails>
                  </CustomAccordion>
                  <CustomAccordion
                    expanded={expanded.includes(2)}
                    onChange={() => handleExpansion(2)}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded.includes(2) ? (
                          <img src={minusIcon} alt="" />
                        ) : (
                          <img src={plusIcon} alt="" />
                        )
                      }
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "20px",
                            sm: "20px",
                            md: "26px",
                            lg: "26px",
                            xl: "26px",
                          },
                          lineHeight: {
                            xs: "25px",
                            sm: "25px",
                            md: "30px",
                            lg: "30px",
                            xl: "30px",
                          },
                          fontWeight: 600,
                          color: "#0F172A",
                          textAlign: "left",
                        }}
                      >
                        Are job openings on the platform updated in real-time?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        sx={{
                          paddingLeft: {
                            xs: "10px",
                            sm: "10px",
                            md: "20px",
                            lg: "20px",
                            xl: "20px",
                          },
                          color: "rgba(56, 135, 159, 0.75)",
                          fontSize: {
                            xs: "16px",
                            sm: "16px",
                            md: "18px",
                            lg: "18px",
                            xl: "18px",
                          },
                          lineHeight: {
                            xs: "26px",
                            sm: "26px",
                            md: "34px",
                            lg: "34px",
                            xl: "34px",
                          },
                          fontWeight: 400,
                          borderLeft: "2px solid #38879F",
                          textAlign: "left",
                        }}
                      >
                        Yes, all job openings are updated in real-time, providing
                        accurate listings without clickbait tactics.
                      </Typography>
                    </AccordionDetails>
                  </CustomAccordion>
                  <CustomAccordion
                    expanded={expanded.includes(3)}
                    onChange={() => handleExpansion(3)}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded.includes(3) ? (
                          <img src={minusIcon} alt="" />
                        ) : (
                          <img src={plusIcon} alt="" />
                        )
                      }
                      aria-controls="panel3-content"
                      id="panel3-header"
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "20px",
                            sm: "20px",
                            md: "26px",
                            lg: "26px",
                            xl: "26px",
                          },
                          lineHeight: {
                            xs: "25px",
                            sm: "25px",
                            md: "30px",
                            lg: "30px",
                            xl: "30px",
                          },
                          fontWeight: 600,
                          color: "#0F172A",
                          textAlign: "left",
                        }}
                      >
                        How can hospitals benefit from using Transparent Care?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        sx={{
                          paddingLeft: {
                            xs: "10px",
                            sm: "10px",
                            md: "20px",
                            lg: "20px",
                            xl: "20px",
                          },
                          color: "rgba(56, 135, 159, 0.75)",
                          fontSize: {
                            xs: "16px",
                            sm: "16px",
                            md: "18px",
                            lg: "18px",
                            xl: "18px",
                          },
                          lineHeight: {
                            xs: "26px",
                            sm: "26px",
                            md: "34px",
                            lg: "34px",
                            xl: "34px",
                          },
                          fontWeight: 400,
                          borderLeft: "2px solid #38879F",
                          textAlign: "left",
                        }}
                      >
                        Hospitals benefit from cost-effective staffing solutions,
                        reduced overhead, and access to a pool of reliable healthcare
                        providers.
                      </Typography>
                    </AccordionDetails>
                  </CustomAccordion>
                  <CustomAccordion
                    expanded={expanded.includes(4)}
                    onChange={() => handleExpansion(4)}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded.includes(4) ? (
                          <img src={minusIcon} alt="" />
                        ) : (
                          <img src={plusIcon} alt="" />
                        )
                      }
                      aria-controls="panel4-content"
                      id="panel4-header"
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "20px",
                            sm: "20px",
                            md: "26px",
                            lg: "26px",
                            xl: "26px",
                          },
                          lineHeight: {
                            xs: "25px",
                            sm: "25px",
                            md: "30px",
                            lg: "30px",
                            xl: "30px",
                          },
                          fontWeight: 600,
                          color: "#0F172A",
                          textAlign: "left",
                        }}
                      >
                        What support does Transparent Care offer during the staffing
                        process?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        sx={{
                          paddingLeft: {
                            xs: "10px",
                            sm: "10px",
                            md: "20px",
                            lg: "20px",
                            xl: "20px",
                          },
                          color: "rgba(56, 135, 159, 0.75)",
                          fontSize: {
                            xs: "16px",
                            sm: "16px",
                            md: "18px",
                            lg: "18px",
                            xl: "18px",
                          },
                          lineHeight: {
                            xs: "26px",
                            sm: "26px",
                            md: "34px",
                            lg: "34px",
                            xl: "34px",
                          },
                          fontWeight: 400,
                          borderLeft: "2px solid #38879F",
                          textAlign: "left",
                        }}
                      >
                        Transparent Care provides unparalleled support by leveraging our
                        platform to reduce overhead and streamline staffing. Unlike
                        other agencies, we don’t have investors, allowing us to focus
                        solely on your needs without external pressures. Our platform
                        ensures a seamless integration of nurses into hospital teams,
                        offering benefits that include cost transparency to all parties,
                        integrated background checks, individualized compliance modules
                        that you can earn CME credits on and much more.
                      </Typography>
                    </AccordionDetails>
                  </CustomAccordion>
                  <CustomAccordion
                    expanded={expanded.includes(5)}
                    onChange={() => handleExpansion(5)}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded.includes(5) ? (
                          <img src={minusIcon} alt="" />
                        ) : (
                          <img src={plusIcon} alt="" />
                        )
                      }
                      aria-controls="panel5-content"
                      id="panel5-header"
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "20px",
                            sm: "20px",
                            md: "26px",
                            lg: "26px",
                            xl: "26px",
                          },
                          lineHeight: {
                            xs: "25px",
                            sm: "25px",
                            md: "30px",
                            lg: "30px",
                            xl: "30px",
                          },
                          fontWeight: 600,
                          color: "#0F172A",
                          textAlign: "left",
                        }}
                      >
                        Can nurses apply for jobs directly through the platform?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        sx={{
                          paddingLeft: {
                            xs: "10px",
                            sm: "10px",
                            md: "20px",
                            lg: "20px",
                            xl: "20px",
                          },
                          color: "rgba(56, 135, 159, 0.75)",
                          fontSize: {
                            xs: "16px",
                            sm: "16px",
                            md: "18px",
                            lg: "18px",
                            xl: "18px",
                          },
                          lineHeight: {
                            xs: "26px",
                            sm: "26px",
                            md: "34px",
                            lg: "34px",
                            xl: "34px",
                          },
                          fontWeight: 400,
                          borderLeft: "2px solid #38879F",
                          textAlign: "left",
                        }}
                      >
                        Yes, this is encouraged! We want YOU to have control over your
                        future. The satisfaction of our clients, from hospital systems
                        to healthcare providers, makes it a win for everyone involved.
                      </Typography>
                    </AccordionDetails>
                  </CustomAccordion>
                </Box>
              </Box>
          </Container>
      </Box>
    </>
  );
};

export default FrequentlyAskedQuestions;
