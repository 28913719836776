import { Box, Button, Chip, Divider, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../redux/slices/flags_slice";
import { format } from "date-fns";
import StyledChip from "../../common/styled/chip";
import { getFormattedValue, getShiftNames } from "../../../utils/helper";
import StarIcon from "../../../assets/icons/star.svg";
import BackNavigation from "../../../assets/icons/back-profile.svg";
import ConfirmIcon from "../../../assets/icons/modal-confirm.svg";
import TickCardIcon from "../../../assets/icons/tick-card.svg";
import {
  GetJobAppliedDetails,
  JobOfferAction,
} from "../../../controller/services/nurse/jobs";
import CommonModal from "../../common/modals/commonModal";
import { setBreadcrumb } from "../../../redux/slices/breadcrumb_slice";
import RatingModal from "../../common/modals/ratingModal";
import { SendReview } from "../../../controller/services/common";
import { RatingsAndReviewsList } from "../dashboard/apply-job";
const labelStyles = {
  fontFamily: "Lato",
  fontSize: {
    xs: "16px",
    sm: "16px",
    md: "18px",
    lg: "18px",
    xl: "18px",
  },
  fontWeight: 700,
  lineHeight: "21.6px",
  color: "#0D2645",
  padding: "10px 0",
};

const valueStyles = {
  fontFamily: "Lato",
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "16px",
    lg: "16px",
    xl: "16px",
  },
  fontWeight: 400,
  lineHeight: "26px",
  color: "#8b97a6",
  padding: {
    xs: "0 0 10px",
    sm: "10px 0",
    md: "10px 0",
    lg: "10px 0",
    xl: "10px 0",
  },
};

const styles = {
  color: "#0d2645",
  fontSize: {
    xs: "12px",
    sm: "13px",
    md: "14px",
    lg: "15px",
    xl: "16px",
  },
  lineHeight: "18.5px",
  fontWeight: 500,
  margin: {
    xs: "15px 0 0",
    sm: "15px 0 0",
    md: "15px 0 0",
    lg: "15px 0",
    xl: "15px",
  },
  padding: {
    xs: "5px 20px",
    sm: "5px 20px",
    md: "5px 20px",
    lg: "10px 20px",
    xl: "10px 20px",
  },
  width: {
    xs: "48%",
    sm: "auto",
    md: "auto",
    lg: "auto",
    xl: "auto",
  },
  borderRadius: "10px",
  float: {
    xs: "left",
    sm: "none",
    md: "none",
    lg: "none",
    xl: "none",
  },
};

const offerStatusColors = {
  pending: "#F19000",
  declined: "#da0000",
  offerReceived: "#0788ff",
  accepted: "#00b81d",
};

const offerStatusLabels = {
  pending: "Pending",
  offerReceived: "Offer Received",
  declined: "Declined",
  accepted: "Accepted",
};

export default function CompletedJobDetails() {
  const params = useParams();
  const dispatch = useDispatch();
  const [jobData, setJobData] = useState(null);
  const [showHospitalDesc, setShowHospitalDesc] = useState(false);
  const [showRatings, setShowRatings] = useState(false);

  useEffect(() => {
    fetchJobDetails();
  }, []);
  useEffect(() => {
    const arr = [
      {
        title: "Completed Jobs",
        route: "/nurse/completed-jobs",
      },
      {
        title: jobData?.jobDetails?.title,
        route: "",
      },
    ];
    if (jobData?.jobDetails?.title) {
      dispatch(setBreadcrumb(arr));
    }
  }, [jobData?.jobDetails?.title]);
  const fetchJobDetails = () => {
    dispatch(setLoading(true));
    GetJobAppliedDetails(params.id)
      .then((resp) => {
        setJobData(resp.data);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
  if (jobData) {
    return (
      <Box padding={"30px"} maxHeight={"90vh"} overflow={"auto"}>
        {showRatings ? (
          <RatingsAndReviewsList
            jobData={jobData}
            handleHideRatings={() => {
              setShowRatings(false);
              const arr = [
                {
                  title: "Completed Jobs",
                  route: "/nurse/completed-jobs",
                },
                {
                  title: jobData?.jobDetails?.title,
                  route: `/nurse/completed-jobs/${jobData?._id}`,
                  func: () => {
                    const arr = [
                      {
                        title: "Completed Jobs",
                        route: "/nurse/completed-jobs",
                      },
                      {
                        title: jobData?.jobDetails?.title,
                        route: "",
                      },
                    ];
                    if (jobData?.jobDetails?.title) {
                      dispatch(setBreadcrumb(arr));
                    }
                    setShowHospitalDesc(false);
                    setShowRatings(false);
                  },
                },
                {
                  title: jobData?.hospitalDetails?.hospitalName,
                  route: "",
                },
              ];
              if (jobData?.jobDetails?.title) {
                dispatch(setBreadcrumb(arr));
              }
            }}
          />
        ) : (
          <>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box
                justifyContent={"space-between"}
                sx={{
                  width: "100%",
                  marginBottom: "20px",
                  display: {
                    xs: "block",
                    sm: "flex",
                    md: "flex",
                    xl: "flex",
                    lg: "flex",
                  },
                }}
              >
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: {
                        xs: "18px",
                        sm: "20px",
                        md: "24px",
                        lg: "24px",
                        xl: "24px",
                      },
                      fontWeight: 700,
                      lineHeight: "28.8px",
                      color: "#0D2645",
                      marginRight: "10px",
                    }}
                  >
                    {jobData?.jobDetails?.title}
                  </Typography>
                  <Chip
                    sx={{
                      background: "#ecf3f6",
                      display: {
                        xs: "none",
                        sm: "flex",
                        md: "flex",
                        lg: "flex",
                        xl: "flex",
                      },
                    }}
                    label={
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Lato",
                            fontSize: "12px",
                            fontWeight: 400,
                            lineHeight: "12px",
                            textAlign: "left",
                            color: "#50647a",
                          }}
                        >
                          Start Date:
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Lato",
                            fontSize: "16px",
                            fontWeight: 500,
                            lineHeight: "19.2px",
                            textAlign: "left",
                            color: "#0D2645",
                          }}
                        >
                          &nbsp;
                          {jobData?.jobDetails?.startDate
                            ? format(
                                new Date(jobData?.jobDetails?.startDate),
                                "dd MMM, yyyy"
                              )
                            : ""}
                        </Typography>
                      </Box>
                    }
                    variant="filled"
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingTop: {
                      xs: "15px",
                      sm: 0,
                      md: 0,
                      lg: 0,
                      xl: 0,
                    },
                  }}
                >
                  <Chip
                    sx={{
                      background: "#ecf3f6",
                      display: {
                        xs: "flex",
                        sm: "none",
                        md: "none",
                        lg: "none",
                        xl: "none",
                      },
                    }}
                    label={
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Lato",
                            fontSize: {
                              xs: "10px",
                              sm: "10px",
                              md: "12px",
                              lg: "12px",
                              xl: "12px",
                            },
                            fontWeight: 400,
                            lineHeight: "12px",
                            textAlign: "left",
                            color: "#50647a",
                          }}
                        >
                          Start Date:
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Lato",
                            fontSize: {
                              xs: "14px",
                              sm: "14px",
                              md: "16px",
                              lg: "16px",
                              xl: "16px",
                            },
                            fontWeight: 500,
                            lineHeight: "19.2px",
                            textAlign: "left",
                            color: "#0D2645",
                          }}
                        >
                          &nbsp;
                          {jobData?.jobDetails?.startDate
                            ? format(
                                new Date(jobData?.jobDetails?.startDate),
                                "dd MMM, yyyy"
                              )
                            : ""}
                        </Typography>
                      </Box>
                    }
                    variant="filled"
                  />
                  <Chip
                    label={offerStatusLabels[jobData?.offerStatus] || ""}
                    sx={{
                      bgcolor: offerStatusColors[jobData?.offerStatus] || "",
                      color: "#fff",
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "19.2px",
                    }}
                  />
                </Box>
              </Box>
              <Box>
                {/* <img
                  src={EditJobIcon}
                  alt="edit job"
                  onClick={() => navigate(`/hospital/edit-job/${jobData?._id}`)}
                /> */}
              </Box>
            </Box>
            <Grid container>
              <Grid
                sx={{ padding: "5px 2px" }}
                xs={6}
                sm={4}
                md={4}
                lg={2}
                xl={2}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Job Offer:"}
                  value={`${getFormattedValue(
                    jobData?.jobDetails?.weeklyPay +
                      jobData?.jobDetails?.houseAllowance +
                      jobData?.jobDetails?.mealAllowance
                  )} /wk`}
                />
              </Grid>
              <Grid
                sx={{
                  padding: "5px 2px",
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    xl: "block",
                    lg: "block",
                  },
                }}
                xs={12}
                sm={6}
                md={6}
                lg={3}
                xl={3}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Shift:"}
                  value={jobData?.jobDetails?.shifts?.map((shift, idx) => {
                    return (
                      getShiftNames(shift) +
                      (jobData?.jobDetails?.shifts?.length !== idx + 1
                        ? ", "
                        : "")
                    );
                  })}
                />
              </Grid>
              <Grid
                sx={{ padding: "5px 2px" }}
                xs={6}
                sm={4}
                md={4}
                lg={2}
                xl={2}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Duration:"}
                  value={`${jobData?.jobDetails?.duration} Weeks`}
                />
              </Grid>
              <Grid
                sx={{
                  padding: "5px 2px",
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    xl: "none",
                    lg: "none",
                  },
                }}
                xs={12}
                sm={6}
                md={6}
                lg={3}
                xl={3}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Shift:"}
                  value={jobData?.jobDetails?.shifts?.map((shift, idx) => {
                    return (
                      getShiftNames(shift) +
                      (jobData?.jobDetails?.shifts?.length !== idx + 1
                        ? ", "
                        : "")
                    );
                  })}
                />
              </Grid>
              <Grid
                sx={{ padding: "5px 2px" }}
                xs={6}
                sm={4}
                md={4}
                lg={2}
                xl={2}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Exp. Required:"}
                  value={`${jobData?.jobDetails?.experience} Years`}
                />
              </Grid>
              <Grid
                sx={{ padding: "5px 2px" }}
                xs={6}
                sm={4}
                md={4}
                lg={2}
                xl={2}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Open Positions:"}
                  value={jobData?.jobDetails?.numberOfOpenings}
                />
              </Grid>
            </Grid>
            <Divider
              sx={{
                margin: {
                  xs: "10px 0",
                  sm: "10px 0",
                  md: "15px 0",
                  lg: "20px 0",
                  xl: "20px 0",
                },
              }}
            />
            <Grid container item columnSpacing={3}>
              <Grid
                xl={12}
                lg={12}
                md={12}
                xs={12}
                sm={12}
                sx={{
                  height: {
                    xs: "auto",
                    sm: "auto",
                    md: "auto",
                    lg: "auto",
                    xl: "auto",
                  },
                }}
                item
              >
                {showHospitalDesc ? (
                  <HospitalDescription
                    jobData={jobData}
                    handleShowRatings={() => setShowRatings(true)}
                    handleShowHospitalDesc={() => {
                      setShowHospitalDesc(false);
                      const arr = [
                        {
                          title: "Completed Jobs",
                          route: "/nurse/completed-jobs",
                        },
                        {
                          title: jobData?.jobDetails?.title,
                          route: "",
                        },
                      ];
                      if (jobData?.jobDetails?.title) {
                        dispatch(setBreadcrumb(arr));
                      }
                    }}
                  />
                ) : (
                  <JobDescription
                    jobData={jobData}
                    handleShowHospitalDesc={() => {
                      setShowHospitalDesc(true);
                      const arr = [
                        {
                          title: "Completed Jobs",
                          route: "/nurse/completed-jobs",
                        },
                        {
                          title: jobData?.jobDetails?.title,
                          route: `/nurse/completed-jobs/${jobData?._id}`,
                          func: () => {
                            const arr = [
                              {
                                title: "Completed Jobs",
                                route: "/nurse/completed-jobs",
                              },
                              {
                                title: jobData?.jobDetails?.title,
                                route: "",
                              },
                            ];
                            if (jobData?.jobDetails?.title) {
                              dispatch(setBreadcrumb(arr));
                            }
                            setShowHospitalDesc(false);
                            setShowRatings(false);
                          },
                        },
                        {
                          title: jobData?.hospitalDetails?.hospitalName,
                          route: "",
                        },
                      ];
                      if (jobData?.jobDetails?.title) {
                        dispatch(setBreadcrumb(arr));
                      }
                    }}
                  />
                )}
                {/* <PendingCandidates jobData={jobData} /> */}
                {/* <ViewApplicantDetails /> */}
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    );
  }
  return <></>;
}

const HospitalDescription = ({
  jobData,
  handleShowHospitalDesc,
  handleShowRatings,
}) => {
  return (
    <>
      <Box maxHeight={"60vh"} overflow={"auto"}>
        <Box textAlign={"left"}>
          <Typography
            display={"flex"}
            alignItems={"center"}
            marginBottom={"10px"}
            onClick={handleShowHospitalDesc}
            sx={{
              cursor: "pointer",
            }}
          >
            <img src={BackNavigation} alt="back" />
            <span
              style={{
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "19.2px",
                color: "#38879F",
                marginLeft: "5px",
              }}
            >
              Back
            </span>
          </Typography>
          <Typography sx={{ ...labelStyles }}>Posted By</Typography>
          <Box display={"flex"} alignItems={"center"}>
            <img
              className="hospital-log-img"
              src={jobData?.hospitalDetails?.hospitalLogo}
              width={132}
              height={132}
              style={{ borderRadius: "50%" }}
            />
            <Box
              sx={{
                marginLeft: {
                  xs: "10px",
                  sm: "10px",
                  md: "15px",
                  lg: "20px",
                  xl: "20px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "18px",
                    sm: "18px",
                    md: "20px",
                    lg: "24px",
                    xl: "24px",
                  },
                  fontWeight: 700,
                  lineHeight: {
                    xs: "21px",
                    sm: "21px",
                    md: "25px",
                    lg: "28.8px",
                    xl: "28.8px",
                  },
                  color: "#0d2645",
                }}
              >
                {jobData?.hospitalDetails?.hospitalName}
              </Typography>
              <Box
                display={"flex"}
                alignItems={"center"}
                margin={"5px 0"}
                onClick={handleShowRatings}
                sx={{ cursor: "pointer" }}
              >
                <img src={StarIcon} alt="star" />
                <Typography
                  color="#0d2645"
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "14px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                  lineHeight={"21px"}
                  fontWeight={500}
                  marginLeft={"5px"}
                >
                  {jobData?.hospitalDetails?.rating} (
                  {jobData?.hospitalDetails?.ratingCount}{" "}
                  {jobData?.hospitalDetails?.ratingCount > 1
                    ? "reviews"
                    : "review"}
                  )
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box textAlign={"left"}>
          <Typography sx={{ ...labelStyles }}>Job Location</Typography>
          <Typography sx={{ ...valueStyles }}>
            {jobData?.hospitalDetails?.address},{" "}
            {jobData?.hospitalDetails?.city}, {jobData?.hospitalDetails?.state}
          </Typography>
        </Box>
        <Box textAlign={"left"}>
          <Typography sx={{ ...labelStyles }}>Job Description</Typography>
          <Typography sx={{ ...valueStyles }}>
            {jobData?.jobDetails?.description}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
const JobDescription = ({ jobData, handleShowHospitalDesc }) => {
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [declineModalVisible, setDeclineModalVisible] = useState(false);
  const [reviewModalVisible, setReviewModalVisible] = useState(false);
  const userData = useSelector((state) => state.user.userData);
  console.log(userData, "userData");
  const params = useParams();
  const dispatch = useDispatch();

  const handleJobAction = (type) => {
    dispatch(setLoading(true));
    const payload = {
      status: type,
    };
    JobOfferAction(params?.id, payload)
      .then((resp) => {
        if (type == "decline") {
          setDeclineModalVisible(false);
        } else {
          setSuccessModalVisible(true);
        }
        toast.success(resp.message);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
  const handleReviewSubmit = (values) => {
    const payload = {
      userId: jobData?.hospitalDetails?._id,
      jobId: jobData?.jobDetails?._id,
      rating: values?.rating,
      review: values?.review || " ",
    };
    SendReview(payload)
      .then((resp) => {
        setReviewModalVisible(false);
        setSuccessModalVisible(true);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
  return (
    <>
      <Box maxHeight={"60vh"} overflow={"auto"}>
        {" "}
        <Box textAlign={"left"}>
          <Typography sx={{ ...labelStyles }}>Posted By</Typography>
          <Box display={"flex"} alignItems={"center"}>
            <img
              className="hospital-log-img"
              src={jobData?.hospitalDetails?.hospitalLogo}
              width={132}
              height={132}
              style={{ borderRadius: "50%" }}
            />
            <Box
              sx={{
                marginLeft: {
                  xs: "10px",
                  sm: "10px",
                  md: "15px",
                  lg: "20px",
                  xl: "20px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "18px",
                    sm: "18px",
                    md: "20px",
                    lg: "24px",
                    xl: "24px",
                  },
                  fontWeight: 700,
                  lineHeight: {
                    xs: "21px",
                    sm: "21px",
                    md: "25px",
                    lg: "28.8px",
                    xl: "28.8px",
                  },
                  color: "#0d2645",
                }}
              >
                {jobData?.hospitalDetails?.hospitalName}
              </Typography>
              <Box display={"flex"} alignItems={"center"} margin={"5px 0"}>
                <img src={StarIcon} alt="star" />
                <Typography
                  color="#0d2645"
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "14px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                  lineHeight={"21px"}
                  fontWeight={500}
                  marginLeft={"5px"}
                >
                  {jobData?.hospitalDetails?.rating} (
                  {jobData?.hospitalDetails?.ratingCount}{" "}
                  {jobData?.hospitalDetails?.ratingCount > 1
                    ? "reviews"
                    : "review"}
                  )
                </Typography>
              </Box>
              <Link
                to=""
                style={{ color: "#38879F" }}
                onClick={handleShowHospitalDesc}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "16px",
                      lg: "18px",
                      xl: "18px",
                    },
                    fontWeight: 700,
                    lineHeight: "21.6px",
                  }}
                >
                  {"View Hospital Profile >"}
                </Typography>{" "}
              </Link>
            </Box>
          </Box>
        </Box>
        <Box textAlign={"left"}>
          <Typography sx={{ ...labelStyles }}>Job Location</Typography>
          <Typography sx={{ ...valueStyles }}>
            {jobData?.hospitalDetails?.address},{" "}
            {jobData?.hospitalDetails?.city}, {jobData?.hospitalDetails?.state}
          </Typography>
        </Box>
        <Box textAlign={"left"}>
          <Typography sx={{ ...labelStyles }}>Job Description</Typography>
          <Typography sx={{ ...valueStyles }}>
            {jobData?.jobDetails?.description || "-"}
          </Typography>
        </Box>
        <Box textAlign={"left"}>
          <Typography
            sx={{
              ...labelStyles,
              marginBottom: {
                xs: 0,
                sm: 0,
                md: "10px",
                lg: "10px",
                xl: "10px",
              },
            }}
          >
            Paycheck
          </Typography>
          <Box
            border={"1.5px solid #99c1cd"}
            padding={"20px"}
            borderRadius={"20px"}
          >
            <Box
              padding={"0 0 20px 0"}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "14px",
                    sm: "15px",
                    md: "16px",
                    lg: "16px",
                    xl: "16px",
                  },
                  fontWeight: 500,
                  lineHeight: "19.2px",
                  color: "#54677c",
                }}
              >
                Weekly Earnings
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: "16px",
                    sm: "16px",
                    md: "18px",
                    lg: "18px",
                    xl: "18px",
                  },
                  fontWeight: 700,
                  lineHeight: "21.6px",
                  color: "#0D2645",
                }}
              >
                {getFormattedValue(jobData?.jobDetails?.weeklyPay)}
                <Typography
                  variant="span"
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "14px",
                      lg: "14px",
                      xl: "14px",
                    },
                    fontWeight: 500,
                    lineHeight: "16.8px",
                    color: "#8b97a6",
                  }}
                >
                  /wk
                </Typography>
              </Typography>
            </Box>
            <Divider />
            <Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                padding={"20px 0 5px 0"}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                    fontWeight: 500,
                    lineHeight: "19.2px",
                    color: "#54677c",
                  }}
                >
                  Travel Per Diems
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      sm: "16px",
                      md: "18px",
                      lg: "18px",
                      xl: "18px",
                    },
                    fontWeight: 700,
                    lineHeight: "21.6px",
                    color: "#0D2645",
                  }}
                >
                  {getFormattedValue(
                    jobData?.jobDetails?.houseAllowance +
                      jobData?.jobDetails?.mealAllowance
                  )}
                  <Typography
                    variant="span"
                    style={{
                      fontSize: {
                        xs: "12px",
                        sm: "12px",
                        md: "14px",
                        lg: "14px",
                        xl: "14px",
                      },
                      fontWeight: 500,
                      lineHeight: "16.8px",
                      color: "#8b97a6",
                    }}
                  >
                    /wk
                  </Typography>
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                padding={"5 0"}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "14px",
                      lg: "14px",
                      xl: "14px",
                    },
                    fontWeight: 500,
                    lineHeight: "19.2px",
                    color: "#54677c",
                  }}
                >
                  Meals
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "14px",
                      lg: "14px",
                      xl: "14px",
                    },
                    fontWeight: 500,
                    lineHeight: "21.6px",
                    color: "#0D2645",
                  }}
                >
                  {getFormattedValue(jobData?.jobDetails?.mealAllowance)}
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                padding={"5px 0 20px 0"}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "14px",
                      lg: "14px",
                      xl: "14px",
                    },
                    fontWeight: 500,
                    lineHeight: "19.2px",
                    color: "#54677c",
                  }}
                >
                  Housing
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "14px",
                      lg: "14px",
                      xl: "14px",
                    },
                    fontWeight: 500,
                    lineHeight: "21.6px",
                    color: "#0D2645",
                  }}
                >
                  {getFormattedValue(jobData?.jobDetails?.houseAllowance)}
                </Typography>
              </Box>
              <Divider />
              <Box
                paddingTop={"20px"}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                    fontWeight: 500,
                    lineHeight: "19.2px",
                    color: "#54677c",
                  }}
                >
                  Total Weekly Payment
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      sm: "16px",
                      md: "18px",
                      lg: "18px",
                      xl: "18px",
                    },
                    fontWeight: 700,
                    lineHeight: "21.6px",
                    color: "#0D2645",
                  }}
                >
                  {getFormattedValue(
                    jobData?.jobDetails?.weeklyPay +
                      jobData?.jobDetails?.houseAllowance +
                      jobData?.jobDetails?.mealAllowance
                  )}
                  <Typography
                    variant="span"
                    style={{
                      fontSize: {
                        xs: "12px",
                        sm: "12px",
                        md: "14px",
                        lg: "14px",
                        xl: "14px",
                      },
                      fontWeight: 500,
                      lineHeight: "16.8px",
                      color: "#8b97a6",
                    }}
                  >
                    /wk
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box textAlign={"right"}>
        <Button
          sx={{
            width: "20%",
            height: "55px",
            marginTop: "15px",
            background: "#38879f",
            color: "#fff",
            fontWeight: 600,
            borderRadius: "10px",
            position: "relative",
            bottom: "20px",
            "&.Mui-disabled": {
              background: "#adcdd7",
              color: "#fff",
            },
            "&:hover": {
              backgroundColor: "#38879f",
            },
          }}
          onClick={() => setReviewModalVisible(true)}
        >
          Write a Review
        </Button>
      </Box>
      <RatingModal
        open={reviewModalVisible}
        handleClose={() => setReviewModalVisible(false)}
        handleSubmit={handleReviewSubmit}
      />
      <CommonModal
        title="Are you sure you want to
    decline this Offer?"
        open={declineModalVisible}
        icon={ConfirmIcon}
        onSubmit={() => handleJobAction("decline")}
        onClose={() => setDeclineModalVisible(false)}
        button1Text={"Yes"}
        button2Text={"No"}
      />
      <CommonModal
        title="Review submitted
        successfully"
        open={successModalVisible}
        icon={TickCardIcon}
        onClose={() => setSuccessModalVisible(false)}
        isSingleButton
        singleBtnText={"Done"}
      />
    </>
  );
};
