import { Box, Button, Radio, RadioGroup, Typography } from "@mui/material";
import AppLogo from "../../../assets/icons/app-logo.svg";
import HospitalLogo from "../../../assets/icons/hospital-logo.svg";
import NurseLogo from "../../../assets/icons/nurse-logo.svg";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function RoleSelection() {
  const [selectedRole, setSelectedRole] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const handleRoleChange = (ev) => {
    setSelectedRole(ev.target.value);
    localStorage.setItem("userType", ev.target.value);
  };

  const handleNavigation = () => {
    if (selectedRole === "hospital") {
      if (location.state?.type === "signin") {
        navigate("/hospital/signin");
      } else {
        navigate("/hospital/signup");
      }
    } else {
      if (location.state?.type === "signin") {
        navigate("/nurse/signin");
      } else {
        navigate("/nurse/signup");
      }
    }
  };
  return (
    <Box
      sx={{
        padding: {
          xs: "30px",
          sm: "0 60px",
          md: "0 70px",
          lg: "0 70px",
          xl: "0 70px",
        },
        height: {
          xs: "auto",
          sm: "100vh",
          md: "100vh",
          lg: "100vh",
          xl: "100vh",
        },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        border={2}
        borderColor={"#38879F"}
        borderRadius={5}
        sx={{
          padding: {
            xs: "0 15px",
          },
          width: "100%",
        }}
      >
        <Box
          sx={{
            paddingTop: {
              xs: "30px",
              sm: "30px",
              md: "40px",
              lg: "40px",
              xl: "40px",
            },
          }}
        >
          <img className="app-logo-role" src={AppLogo} alt="logo" />
          <Typography
            variant="h3"
            color="#0d2645"
            fontSize={26}
            fontWeight={600}
            lineHeight={"31.2px"}
            padding={"10px"}
          >
            Please select your role to continue
          </Typography>
          <Typography
            variant="h6"
            color="#8391a0"
            fontWeight={400}
            sx={{
              padding: {
                xs: 0,
                sm: "10px",
                md: "10px",
                lg: "10px",
                xl: "10px",
              },
              fontSize: {
                xs: "12px",
                sm: "14px",
                md: "16px",
                lg: "16px",
                xl: "16px",
              },
              lineHeight: {
                xs: "20px",
                sm: "24px",
                md: "25px",
                lg: "25px",
                xl: "25px",
              },
            }}
          >
            Get Started to Elevate Your Career and Transform Patient Care
          </Typography>
        </Box>
        <Box paddingTop="15px" display={"flex"} justifyContent={"center"}>
          <RadioGroup
            sx={{
              justifyContent: {
                xs: "center",
              },
            }}
            row
            onChange={handleRoleChange}
            value={selectedRole}
          >
            <Radio
              value="hospital"
              icon={
                <Box
                  display={"inline-block"}
                  border={"1.5px solid #9bc2ce"}
                  padding={"10px"}
                  borderRadius={4}
                  width={"270px"}
                >
                  <img src={HospitalLogo} alt="hospital" />
                  <Box
                    color={"#0d2645"}
                    bgcolor="#ecf3f6"
                    height={"50px"}
                    width={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    fontSize={"18px"}
                    lineHeight={"21.6px"}
                    fontWeight={600}
                    borderRadius={4}
                    marginTop={"20px"}
                  >
                    Hospital
                  </Box>
                </Box>
              }
              disableRipple
              checkedIcon={
                <Box
                  display={"inline-block"}
                  border={"1.5px solid #38879f"}
                  padding={"10px"}
                  borderRadius={4}
                  width={"270px"}
                >
                  <img src={HospitalLogo} alt="hospital" />
                  <Box
                    color={"#fff"}
                    bgcolor="#38879f"
                    height={"50px"}
                    width={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    fontSize={"18px"}
                    lineHeight={"21.6px"}
                    fontWeight={600}
                    borderRadius={4}
                    marginTop={"20px"}
                  >
                    Hospital
                  </Box>
                </Box>
              }
            />
            <Radio
              value="nurse"
              disableRipple
              icon={
                <Box
                  display={"inline-block"}
                  border={"1.5px solid #9bc2ce"}
                  padding={"10px"}
                  borderRadius={4}
                  width={"270px"}
                >
                  <img src={NurseLogo} alt="hospital" />
                  <Box
                    color={"#0d2645"}
                    bgcolor="#ecf3f6"
                    height={"50px"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    fontSize={"18px"}
                    lineHeight={"21.6px"}
                    fontWeight={600}
                    borderRadius={4}
                    marginTop={"20px"}
                    width={"100%"}
                  >
                    Nurse
                  </Box>
                </Box>
              }
              checkedIcon={
                <Box
                  display={"inline-block"}
                  border={"1.5px solid #38879f"}
                  padding={"10px"}
                  borderRadius={4}
                  width={"270px"}
                >
                  <img src={NurseLogo} alt="hospital" />
                  <Box
                    color={"#fff"}
                    bgcolor="#38879f"
                    height={"50px"}
                    width={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    fontSize={"18px"}
                    lineHeight={"21.6px"}
                    fontWeight={600}
                    borderRadius={4}
                    marginTop={"20px"}
                  >
                    Nurse
                  </Box>
                </Box>
              }
            />
          </RadioGroup>
        </Box>
        <Button
          sx={{
            width: {
              xs: "100%",
              sm: "440px",
              md: "440px",
              lg: "440px",
              xl: "440px",
            },
            height: "65px",
            background: "#38879f",
            color: "#fff",
            margin: {
              xs: "20px 0 30px",
              sm: "20px 0",
              md: "20px 0",
              lg: "20px 0 30px",
              xl: "20px 0 30px",
            },
            fontWeight: 600,
            borderRadius: "10px",
            "&.Mui-disabled": {
              background: "#adcdd7",
              color: "#fff",
            },
            "&:hover": {
              backgroundColor: "#38879f",
            },
          }}
          disabled={!selectedRole}
          onClick={handleNavigation}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
}
