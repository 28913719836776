import { combineReducers } from "@reduxjs/toolkit";
import flags_slice from "./slices/flags_slice";
import user_slice from "./slices/user_slice";
import filters_slice from "./slices/filters_slice";
import breadcrumb_slice from "./slices/breadcrumb_slice";

const rootReducer = combineReducers({
  flags: flags_slice,
  user: user_slice,
  filters: filters_slice,
  breadcrumb: breadcrumb_slice,
});

export default rootReducer;
