import axiosInstance from "../../api/axios";

export const sendPhoneVerification = async (payload) => {
  const res = await axiosInstance.post(
    "/api/v1/verification/send-phone-verification",
    payload
  );
  return res.data;
};

export const verifyPhoneOtp = async (payload) => {
  const res = await axiosInstance.post(
    "/api/v1/verification/verify-phone",
    payload
  );
  return res.data;
};

export const createProfile = async (payload) => {
  const res = await axiosInstance.post("/api/v1/user/", payload);
  return res.data;
};

export const getProfile = async () => {
  const res = await axiosInstance.get("/api/v1/user/");
  return res.data;
};

export const updateProfile = async (payload) => {
  const res = await axiosInstance.put("/api/v1/user/", payload);
  return res.data;
};
