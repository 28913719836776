import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

export default function NoDataFound({ title, icon, height }) {
  const isLoading = useSelector((state) => state.flags.isLoading);
  if (!isLoading) {
    return (
      <Box
        width={"100%"}
        //   bgcolor={"#fff"}
        height={height || "80vh"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          borderBottomRightRadius: "25px",
          borderBottomLeftRadius: "25px",
        }}
      >
        <img src={icon} alt="empty-icon" />
        <Typography
          textAlign={"center"}
          sx={{
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "24px",
            color: "#0D2645",
            marginTop: "20px",
          }}
        >
          {title}
        </Typography>
      </Box>
    );
  }
  return <></>;
}
