import {
  Backdrop,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  SliderThumb,
  Typography,
} from "@mui/material";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import NotificationIcon from "../../../assets/icons/notification.svg";
import { Link, useNavigate } from "react-router-dom";
import ContactIcon from "../../../assets/icons/contact.svg";
import toggleMenu from "../../../assets/icons/toggle-menu.svg";
import FilterIcon from "../../../assets/icons/filter.svg";
import CloseIcon from "../../../assets/icons/filter-close.svg";
import { useEffect, useState } from "react";
import tickCircle from "../../../assets/icons/tick-circle.svg";
import tickCheckedCircle from "../../../assets/icons/checked-circle.svg";
import SearchIcon from "../../../assets/icons/search.svg";
import ThumbIcon from "../../../assets/icons/slider-thumb.svg";
import {
  setCity,
  setExperience,
  setSearch,
  setStateName,
} from "../../../redux/slices/filters_slice";
import {
  setLoading,
  setNotificationDot,
  setSelectedType,
} from "../../../redux/slices/flags_slice";
import { GetStateCityData } from "../../../controller/services/common";
import toast from "react-hot-toast";
export default function AppLayout({ children, tspColor }) {
  const userData = useSelector((state) => state.user.userData);
  const isLoading = useSelector((state) => state.flags.isLoading);
  const [toggle, setToggle] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [logoUrl, setLogoUrl] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const breadcrumb = useSelector((state) => state.breadcrumb?.breadCrumbArr);
  const type = useSelector((state) => state.flags.type);
  const filtersVal = useSelector((state) => state.filters);
  const redDotFlag = useSelector((state) => state.flags.notificationDot);

  const citySelected = filtersVal?.city && filtersVal?.city !== "Select";
  const stateSelected = filtersVal?.state && filtersVal?.state !== "Select";
  const experiencesSelected =
    filtersVal?.experience?.length > 0 &&
    filtersVal?.experience.every((i) => i !== 0);

  const shouldShowFilterIcon =
    citySelected || stateSelected || experiencesSelected;

  const iconClassName = shouldShowFilterIcon ? "filter-icon" : "";
  useEffect(() => {
    if (userData?.userType == "hospital") {
      setLogoUrl(userData?.hospitalLogo);
    } else {
      setLogoUrl(userData?.profilePicture);
    }
  }, [userData?.hospitalLogo, userData?.profilePicture]);
  return (
    <Box
      sx={{
        height: {
          xs: "auto",
          sm: "auto",
          md: "auto",
          lg: "100vh",
          xl: "100vh",
        },
        display: "flex",
      }}
    >
      <Box
        sx={{
          width: {
            xs: "100%",
            sm: "254px",
            md: "254px",
            lg: "254px",
            xl: "254px",
          },
          height: {
            xs: "calc(100vh - 70px)",
            sm: "calc(100vh - 70px)",
            md: "100vh",
            lg: "100vh",
            xl: "100vh",
          },
          position: {
            xs: "fixed",
            sm: "fixed",
            md: "fixed",
            lg: "fixed",
            xl: "fixed",
          },
          left: {
            xs: toggle ? 0 : "-100%",
            sm: toggle ? 0 : "-100%",
            md: 0,
            lg: 0,
            xl: 0,
          },
          transition: "0.4s ease all",
          zIndex: 9,
          background: "#ffffff",
          padding: {
            xs: "0 15px",
            sm: "0 15px",
            md: "0 15px",
            lg: 0,
            xl: 0,
          },
          top: {
            xs: "70px",
            sm: "70px",
            md: "30px",
            lg: "30px",
            xl: "30px",
          },
        }}
      >
        <Sidebar setToggleMenu={() => setToggle(false)} />
      </Box>
      <Box
        sx={{
          width: {
            xs: "100%",
            sm: "100%",
            md: "calc(100% - 254px)",
            lg: "calc(100% - 254px)",
            xl: "calc(100% - 254px)",
          },
          marginLeft: {
            xs: 0,
            sm: 0,
            md: "254px",
            lg: "254px",
            xl: "254px",
          },
          height: "100vh",
          overflow: "auto",
          borderRadius: {
            xs: 0,
            sm: 0,
            md: 0,
            lg: "50px 0 0 50px",
            xl: "50px 0 0 50px",
          },
          padding: {
            xs: "15px 15px 15px",
            sm: "20px 30px 30px",
            md: "20px 30px 30px",
            lg: "20px 30px 30px",
            xl: "20px 30px 30px",
          },
        }}
        bgcolor={"#eff6f8"}
      >
        <Box
          display="flex"
          justifyContent={"space-between"}
          marginBottom={"20px"}
          alignItems={"center"}
          sx={{
            position: {
              xs: "fixed",
              sm: "fixed",
              md: "fixed",
              lg: "relative",
              xl: "relative",
            },
            top: 0,
            left: 0,
            height: {
              xs: "70px",
              sm: "70px",
              md: "80px",
              lg: "auto",
              xl: "auto",
            },
            zIndex: 8,
            background: {
              xs: "#fff",
              sm: "#fff",
              md: "#fff",
              lg: "transparent",
              xl: "transparent",
            },
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "auto",
              xl: "auto",
            },
            padding: {
              xs: "0 15px",
              sm: "0 15px",
              md: "0 15px",
              lg: 0,
              xl: 0,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "60%",
            }}
          >
            <Box
              onClick={() => setToggle((prev) => !prev)}
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                  xl: "none",
                },
                marginRight: "10px",
                position: "relative",
                top: "4px",
                cursor: "pointer",
              }}
            >
              <img src={toggleMenu} alt="" />
            </Box>
            <Box className="web-breadcrumb">
              {breadcrumb?.length == 1 ? (
                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      sm: "18px",
                      md: "24px",
                      lg: "26px",
                      xl: "28px",
                    },
                    textAlign: "left",
                  }}
                  fontWeight={700}
                  color="#0D2645"
                >
                  {breadcrumb[0]?.title}
                </Typography>
              ) : breadcrumb?.length > 0 ? (
                <Breadcrumbs aria-label="breadcrumb" separator="›">
                  {breadcrumb.map((item, index) => {
                    if (index + 1 !== breadcrumb.length) {
                      return (
                        <Link
                          key={item.route}
                          to={item.route}
                          color="inherit"
                          style={{
                            textDecoration: "none",
                            color: "#94a3b1",
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "19.2px",
                          }}
                          onClick={() => {
                            if (item.isTypeFunc) {
                              dispatch(setSelectedType(type));
                            } else if (item.func) {
                              item.func();
                            }
                          }}
                        >
                          {item.title}
                        </Link>
                      );
                    } else {
                      return (
                        <Typography
                          key={item.title}
                          sx={{
                            fontSize: {
                              xs: "10px",
                              sm: "12px",
                              md: "14px",
                              lg: "16px",
                              xl: "18px",
                            },
                          }}
                          fontWeight={700}
                          color="#0D2645"
                        >
                          {item.title}
                        </Typography>
                      );
                    }
                  })}
                </Breadcrumbs>
              ) : (
                ""
              )}
            </Box>
            {window.location.pathname === "/nurse/dashboard" && (
              <Input
                placeholder="Search"
                startAdornment={<img src={SearchIcon} alt="search" />}
                inputProps={{
                  style: {
                    paddingLeft: "10px",
                  },
                }}
                onChange={(e) => dispatch(setSearch(e.target.value))}
                sx={{
                  color: "#0d2645",
                  marginLeft: "20px",
                  padding: "10px 5px 10px 15px",
                  background: "#fff",
                  width: "64%",
                  height: "50px",
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "40px",
                  borderRadius: "15px",
                  "&.Mui-disabled:before": {
                    borderBottom: "none",
                  },
                  "&:after": {
                    borderBottom: "none",
                  },
                  "&:before": {
                    borderBottom: "none",
                  },
                  "&:hover:not(.Mui-disabled, .Mui-error):before": {
                    borderBottom: "none",
                  },
                  "&.Mui-focused": {
                    borderBottom: "none",
                  },
                }}
              />
            )}
          </Box>
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent={"space-between"}
          >
            {window.location.pathname === "/nurse/dashboard" && (
              <Box
                className={iconClassName}
                sx={{
                  position: "relative",
                }}
              >
                <img
                  src={FilterIcon}
                  alt="filter"
                  onClick={() => setShowFilters(true)}
                  style={{ marginRight: "5px", cursor: "pointer" }}
                />
              </Box>
            )}
            <Box
              bgcolor={"#fff"}
              padding={0}
              borderRadius={"20px"}
              display={"flex"}
              justifyContent={"center"}
              sx={{
                width: {
                  xs: "40px",
                  sm: "50px",
                  md: "60px",
                  lg: "60px",
                  xl: "60px",
                },
                height: {
                  xs: "40px",
                  sm: "50px",
                  md: "60px",
                  lg: "60px",
                  xl: "60px",
                },
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Box
                className={
                  redDotFlag
                    ? "notification-icon red-dot-visible"
                    : "notification-icon"
                }
                sx={{
                  position: "relative",
                }}
              >
                <img
                  className={"notification-icon"}
                  src={NotificationIcon}
                  alt="notification"
                  onClick={() => {
                    dispatch(setNotificationDot(false));
                    navigate("/user/notifications");
                  }}
                  style={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <img
              className="logo-icon"
              src={logoUrl ? logoUrl : ContactIcon}
              alt="pic"
              width={60}
              height={60}
              style={{ borderRadius: "50%", cursor: "pointer" }}
              onClick={() => navigate("/user/settings")}
            />
            {userData?.userType == "hospital" && (
              <Button
                sx={{
                  marginLeft: {
                    xs: "10px",
                    sm: "10px",
                    md: "20px",
                    lg: "20px",
                    xl: "20px",
                  },
                  height: {
                    xs: "40px",
                    sm: "40px",
                    md: "50px",
                    lg: "50px",
                    xl: "50px",
                  },
                  width: {
                    xs: "100px",
                    sm: "120px",
                    md: "150px",
                    lg: "150px",
                    xl: "150px",
                  },
                  fontSize: {
                    xs: "13px",
                    sm: "14px",
                    md: "15px",
                    lg: "15px",
                    xl: "15px",
                  },
                  fontWeight: 600,
                  borderRadius: "8px",
                  background: "#38879f",
                  textTransform: "none",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#38879f",
                  },
                }}
                onClick={() => {
                  navigate("/hospital/create-job-ad");
                }}
              >
                Post a Job
              </Button>
            )}
          </Box>
        </Box>
        {showFilters && (
          <JobFilters
            handleCloseFilter={() => setShowFilters(false)}
            open={showFilters}
          />
        )}
        <Box
          sx={{
            position: "relative",
            top: {
              xs: "70px",
              sm: "65px",
              md: "65px",
              lg: "58px",
              xl: "58px",
            },
          }}
          className="mobile-breadcrumb"
        >
          {breadcrumb?.length == 1 ? (
            <Typography
              sx={{
                fontSize: {
                  xs: "16px",
                  sm: "18px",
                  md: "24px",
                  lg: "26px",
                  xl: "28px",
                },
                textAlign: "left",
              }}
              fontWeight={700}
              color="#0D2645"
            >
              {breadcrumb[0]?.title}
            </Typography>
          ) : breadcrumb?.length > 0 ? (
            <Breadcrumbs aria-label="breadcrumb" separator="›">
              {breadcrumb?.map((i, index) => {
                if (index + 1 != breadcrumb?.length) {
                  if (i.isTypeFunc) {
                    return (
                      <Link
                        key={i?.route}
                        to={i.route}
                        color="inherit"
                        style={{
                          textDecoration: "none",
                          color: "#94a3b1",
                          fontSize: "16px",
                          fontWeight: 400,
                          lineHeight: "19.2px",
                        }}
                        onClick={() => dispatch(setSelectedType(type))}
                      >
                        {i.title}
                      </Link>
                    );
                  } else {
                    return (
                      <Link
                        key={i?.route}
                        to={i.route}
                        color="inherit"
                        style={{
                          textDecoration: "none",
                          color: "#94a3b1",
                          fontSize: "16px",
                          fontWeight: 400,
                          lineHeight: "19.2px",
                        }}
                        onClick={() => {
                          if (i?.func) {
                            i?.func();
                          }
                        }}
                      >
                        {i.title}
                      </Link>
                    );
                  }
                }
                return (
                  <Typography
                    key={i?.route}
                    sx={{
                      marginTop: "2.5px",
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "14px",
                        lg: "16px",
                        xl: "18px",
                      },
                    }}
                    fontWeight={700}
                    color="#0D2645"
                  >
                    {i?.title}
                  </Typography>
                );
              })}
            </Breadcrumbs>
          ) : (
            ""
          )}
        </Box>
        <Box
          bgcolor={tspColor ? "transparent" : "#fff"}
          borderRadius={"25px"}
          position="relative"
          sx={{
            marginTop: {
              xs: "85px",
              sm: "70px",
              md: "80px",
              lg: 0,
              xl: 0,
            },
            height: {
              xs: "calc(100vh - 140px)",
              sm: "calc(100vh - 125px)",
              md: "calc(100vh - 140px)",
              xl: "calc(100vh - 140px)",
              lg: "calc(100vh - 140px)",
            },
            overflow: "auto",
          }}
        >
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: 10,
              borderRadius: "50px",
              left: {
                xs: 0,
                sm: 0,
                md: 0,
                lg: "16.5%",
                xl: "16.5%",
              },
              background: "transparent",
            }}
            open={isLoading}
          >
            <CircularProgress />
          </Backdrop>
          {children}
        </Box>
      </Box>
    </Box>
  );
}

const JobFilters = ({ open, handleCloseFilter }) => {
  const dispatch = useDispatch();
  const [stateName, setStateNameLocal] = useState("Select");
  const [cityName, setCityNameLocal] = useState("Select");
  const [experience, setExperienceLocal] = useState([0, 0]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const filtersVal = useSelector((state) => state.filters);
  useEffect(() => {
    if (filtersVal?.state) {
      setStateNameLocal(filtersVal?.state);
    }
    if (filtersVal?.experience?.length > 0) {
      setExperienceLocal(filtersVal?.experience);
    }
    if (filtersVal?.city) {
      setCityNameLocal(filtersVal?.city);
    }
  }, [filtersVal]);
  console.log(filtersVal, "filtersVal");

  useEffect(() => {
    getStateCityData();
  }, []);

  useEffect(() => {
    if (stateList?.length > 0 && stateName) {
      const foundObj = stateList.find((i) => i.state === stateName);
      if (foundObj) {
        setCityList(foundObj?.cities);
      }
    }
  }, [stateList?.length, stateName]);
  const getStateCityData = () => {
    dispatch(setLoading(true));
    GetStateCityData()
      .then((resp) => {
        const statesData = [
          ...resp.data.states?.countries[0]?.states,
          ...resp.data.states?.countries[1]?.states,
        ];
        setStateList(statesData);
        console.log(statesData, "Fdsfsdf");
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
  return (
    <Dialog
      open={open}
      sx={{
        textAlign: "center",
      }}
      PaperProps={{
        sx: {
          width: "450px",
          borderRadius: "25px",
        },
      }}
      onClose={handleCloseFilter}
    >
      <DialogContent>
        <Box
          sx={{
            bgcolor: "#fff",
            borderRadius: "25px",
          }}
        >
          <Box padding={"20px"}>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "20px",
                  lineHeight: "24px",
                }}
              >
                Filter by
              </Typography>
              <img src={CloseIcon} alt="close" onClick={handleCloseFilter} />
            </Box>
            <Box>
              <InputLabel
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "21.6px",
                }}
              >
                State
              </InputLabel>
              <Select
                name="licensedStates"
                placeholder="Select States"
                renderValue={(value) => (
                  <Typography
                    sx={{ color: value == "Select" ? "#7b8c9d" : "#0d2645" }}
                  >
                    {value}
                  </Typography>
                )}
                inputProps={{
                  style: {
                    padding: "10px",
                  },
                }}
                value={stateName}
                defaultValue={"Select"}
                sx={{
                  padding: "10px 5px 10px 0",
                  background: "#eff6f8",
                  color: "#0d2645",
                  fontSize: "16px",
                  width: "100%",
                  height: "50px",
                  textAlign: "left",
                  borderRadius: "10px",
                  "&:after": {
                    borderBottom: "none",
                  },
                  "&:before": {
                    borderBottom: "none",
                  },
                  "&:hover:not(.Mui-disabled, .Mui-error):before": {
                    borderBottom: "none",
                  },
                  "&.Mui-focused": {
                    border: "none",
                    outline: "none",
                  },
                }}
                onChange={(e) => {
                  const foundObj = stateList.find(
                    (i) => i.state === e.target.value
                  );
                  setCityList(foundObj.cities);
                  setStateNameLocal(e.target.value);
                  setCityNameLocal("Select");
                }}
              >
                {stateList.map((stateObj) => {
                  return (
                    <MenuItem
                      value={stateObj?.state}
                      key={stateObj?.state}
                      sx={{
                        height: "50px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        color: "#0d2645",
                        padding: "0 8px",
                        "&.Mui-selected": {
                          background: "none",
                        },
                      }}
                    >
                      <Typography
                        fontWeight={600}
                        fontSize={16}
                        lineHeight={29}
                      >
                        {stateObj?.state}
                      </Typography>
                      <Checkbox
                        sx={{
                          borderRadius: "50%",
                        }}
                        icon={<img src={tickCircle} alt="checked" />}
                        checkedIcon={
                          <img src={tickCheckedCircle} alt="checked" />
                        }
                        checked={stateName === stateObj?.state}
                      />
                    </MenuItem>
                  );
                })}
              </Select>
              <InputLabel
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "21.6px",
                  marginTop: "20px",
                }}
              >
                City
              </InputLabel>
              <Select
                renderValue={(value) => (
                  <Typography
                    sx={{ color: value == "Select" ? "#7b8c9d" : "#0d2645" }}
                  >
                    {value}
                  </Typography>
                )}
                onChange={(e) => {
                  setCityNameLocal(e.target.value);
                }}
                disabled={stateName == "Select" || !stateName}
                value={cityName}
                sx={{
                  padding: "10px 5px 10px 0",
                  background: "#eff6f8",
                  color: "#0d2645",
                  fontSize: "16px",
                  width: "100%",
                  height: "50px",
                  textAlign: "left",
                  borderRadius: "10px",
                  "&:after": {
                    borderBottom: "none",
                  },
                  "&:before": {
                    borderBottom: "none",
                  },
                  "&:hover:not(.Mui-disabled, .Mui-error):before": {
                    borderBottom: "none",
                  },
                  "&.Mui-focused": {
                    border: "none",
                    outline: "none",
                  },
                }}
              >
                {cityList.map((cityObj) => {
                  return (
                    <MenuItem
                      value={cityObj}
                      key={cityObj}
                      sx={{
                        height: "50px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        color: "#0d2645",
                        padding: "0 8px",
                        "&.Mui-selected": {
                          background: "none",
                        },
                      }}
                    >
                      <Typography
                        fontWeight={600}
                        fontSize={16}
                        lineHeight={29}
                      >
                        {cityObj}
                      </Typography>
                      <Checkbox
                        sx={{
                          borderRadius: "50%",
                        }}
                        icon={<img src={tickCircle} alt="checked" />}
                        checkedIcon={
                          <img src={tickCheckedCircle} alt="checked" />
                        }
                        checked={cityName === cityObj}
                      />
                    </MenuItem>
                  );
                })}
              </Select>
              <InputLabel
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "21.6px",
                  marginTop: "20px",
                  marginBottom: "10px",
                }}
              >
                Years of Experience
              </InputLabel>
              <Slider
                value={experience}
                onChange={(ev, newValue) => setExperienceLocal(newValue)}
                max={10}
                min={0}
                valueLabelFormat={(value) => (value == 10 ? "10+" : value)}
                slots={{
                  thumb: CustomThumb,
                }}
                sx={{
                  marginTop: "10px",
                  "& .MuiSlider-track ": {
                    color: "#38879f",
                    height: 5,
                  },
                  "& .MuiSlider-rail": {
                    color: "#d7e7ec",
                    height: 5,
                  },
                  "& .MuiSlider-valueLabel": {
                    fontSize: 12,
                    fontWeight: "normal",
                    top: "4px",
                    backgroundColor: "unset",
                    color: "#000",
                    "&::before": {
                      display: "none",
                    },
                    "& *": {
                      background: "transparent",
                      color: "#000",
                    },
                  },
                }}
                valueLabelDisplay="on"
              />
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            padding={"20px"}
            alignItems={"center"}
            sx={{
              background: "#ecf3f6 !important",
              borderBottomLeftRadius: "25px",
              borderBottomRightRadius: "25px",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                lineHeight: "19.2px",
                fontWeight: 700,
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(setSearch(""));
                dispatch(setStateName(""));
                dispatch(setExperience([0, 0]));
                dispatch(setCity(""));
                setCityNameLocal("Select");
                setStateNameLocal("Select");
                setExperienceLocal([0, 0]);
              }}
            >
              Clear All
            </Typography>
            <Button
              sx={{
                width: "40%",
                height: "55px",
                background: "#38879f",
                color: "#fff",
                fontWeight: 600,
                borderRadius: "10px",
                "&.Mui-disabled": {
                  background: "#adcdd7",
                  color: "#fff",
                },
                "&:hover": {
                  backgroundColor: "#38879f",
                },
              }}
              onClick={() => {
                dispatch(setStateName(stateName));
                dispatch(setExperience(experience));
                dispatch(setCity(cityName));
                handleCloseFilter();
              }}
            >
              View Jobs
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const CustomThumb = (props) => {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <img src={ThumbIcon} alt="thumb" />
    </SliderThumb>
  );
};
