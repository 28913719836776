import { Box, Grid, Typography } from "@mui/material";
import AppLogo from "../../assets/icons/app-logo.svg";
import BannerImg from "../../assets/images/hospital-auth-banner-img.png";
import BannerNurseImg from "../../assets/images/nurse-auth-banner-img.png";

export default function Layout({ children, isSignIn }) {
  const userType = localStorage.getItem("userType");
  return (
    <Grid
      sx={{
        height: {
          xs: "100vh",
          sm: "100vh",
          md: "100vh",
          xl: "auto",
          lg: "auto",
        },
        alignItems: {
          xs: "center",
          sm: "center",
          md: "center",
        },
        justifyContent: {
          sm: "center",
        },
        overflow: "hidden",
      }}
      container
      item
    >
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={6}
        xl={6}
        sx={{
          padding: {
            xs: "4.5% 12%",
            sm: "4.5% 12%",
            md: "3% 6%",
            lg: "1% 12%",
            xl: "1% 12%",
          },
        }}
      >
        <Box>
          <img className="app-logo-role" src={AppLogo} alt="logo" />
          <Typography
            variant="h3"
            color="#0d2645"
            fontSize={26}
            fontWeight={600}
            lineHeight={"31.2px"}
            margin={"20px 10px 10px 10px"}
          >
            Get Started!
          </Typography>
          <Typography
            variant="h6"
            color="#8391a0"
            fontSize={16}
            fontWeight={400}
            lineHeight={"25px"}
            margin={"10px"}
          >
            {isSignIn
              ? "Sign In to your account"
              : "Sign Up to create an account"}
          </Typography>
        </Box>
        {children}
      </Grid>
      <Grid
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "block",
            xl: "block",
          },
        }}
        md={12}
        lg={6}
        xl={6}
      >
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            position: "relative",
            background: "#38879F1A",
            borderRadius: "98px 0 0 98px",
          }}
        >
          <Box
            sx={{
              height: "100vh",
              width: "calc(100% - 275px)",
              position: "absolute",
              left: 0,
              top: 0,
            }}
          ></Box>
          <Box
            sx={{
              width: "275px",
              height: "100vh",
              background: "#38879F",
              position: "absolute",
              right: 0,
              top: 0,
            }}
          ></Box>
          <Box
            className={`common-img-div ${
              userType === "hospital" ? "hospital-img-div" : "nurse-div-img"
            }`}
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: {
                xs: 0,
                sm: 0,
                md: 0,
                lg: userType === "hospital" ? "-120px" : 0,
                xl: userType === "hospital" ? "-205px" : "-140px",
              },
              margin: "auto",
              width: "100%",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
            <img
              className={`banner-img-auth ${
                userType === "hospital" ? "" : "nurse-auth-img"
              }`}
              src={userType === "hospital" ? BannerImg : BannerNurseImg}
              alt="banner image"
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
