import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import RoleSelection from "./components/common/auth/RoleSelection";
import Signup from "./components/common/auth/signup";
import Signin from "./components/common/auth/signin";
import TermsAndConditions from "./components/common/terms-and-policy/termsCondition";
import PrivacyPolicy from "./components/common/terms-and-policy/privacyPolicy";
import ForgotPassword from "./components/common/auth/reset-password/forgotPassword";
import toast, { Toaster } from "react-hot-toast";
import VerifyOtp from "./components/common/auth/reset-password/verifyOtp";
import ResetPassword from "./components/common/auth/reset-password/resetPassword";
import PasswordChangeSuccess from "./components/common/auth/reset-password/success";
import ProfileLayout from "./components/hospital/create-profile";
import NurseProfileLayout from "./components/nurse/create-profile";
import ApprovedScreen from "./components/hospital/create-profile/approved";
import NurseApprovedAC from "./components/nurse/create-profile/approved";
import ProtectedRoute from "./components/common/ProtectedRoute";
import HospitalDashboard from "./components/hospital/Dashboard";
import EditProfile from "./components/hospital/edit-profile";
import NurseDashboard from "./components/nurse/dashboard";
import CreateJobAd from "./components/hospital/job-module/create-job";
import PendingJobs from "./components/hospital/job-module/pending-jobs";
import ViewPendingJob from "./components/hospital/job-module/pending-jobs/viewPendingJob";
import LandingPage from "./components/landing-page/landingPage";
import ActiveJobs from "./components/hospital/job-module/active-jobs";
import ViewActiveJob from "./components/hospital/job-module/active-jobs/viewActiveJob";
import ApplyJob from "./components/nurse/dashboard/apply-job";
import AppliedJobs from "./components/nurse/applied-jobs";
import ViewAppliedJob from "./components/nurse/applied-jobs/view-applied-job";
import CompletedJobs from "./components/hospital/job-module/completed-jobs";
import ViewCompletedJob from "./components/hospital/job-module/completed-jobs/viewCompletedJob";
import NurseCompletedJobs from "./components/nurse/completed-jobs";
import CompletedJobDetails from "./components/nurse/completed-jobs/ViewCompletedJob";
import Notifications from "./components/common/notifications";
import { useEffect } from "react";
import {
  analytics,
  generateToken,
  messaging,
} from "./utils/notifications/firebase";
import { onMessage } from "firebase/messaging";
import HiredNurses from "./components/hospital/hired-nurses";
import ViewHiredNurse from "./components/hospital/hired-nurses/viewHiredNurse";
import { setNotificationDot } from "./redux/slices/flags_slice";
import { useDispatch } from "react-redux";
import { logEvent } from "firebase/analytics";
function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    generateToken();
    onMessage(messaging, (payload) => {
      toast.success(payload.notification?.title);
      dispatch(setNotificationDot(true));
    });
    logEvent(analytics, "page_view");
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/role-selection" element={<RoleSelection />} />
          <Route path="/hospital/signup" element={<Signup />} />
          <Route path="/nurse/signup" element={<Signup />} />
          <Route path="/hospital/signin" element={<Signin />} />
          <Route path="/nurse/signin" element={<Signin />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/verify-otp" element={<VerifyOtp />} />
          <Route path="/password-changed" element={<PasswordChangeSuccess />} />
          <Route
            path="/hospital/create-profile"
            element={
              <ProtectedRoute withoutLayout>
                <ProfileLayout />
              </ProtectedRoute>
            }
          />
          <Route
            path="/hospital/approval-status"
            element={<ApprovedScreen />}
          />
          <Route
            path="/hospital/dashboard"
            element={
              <ProtectedRoute tspColor>
                <HospitalDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/hospital/pending-jobs"
            element={
              <ProtectedRoute tspColor>
                <PendingJobs />
              </ProtectedRoute>
            }
          />
          <Route
            path="/hospital/active-jobs"
            element={
              <ProtectedRoute tspColor>
                <ActiveJobs />
              </ProtectedRoute>
            }
          />
          <Route
            path="/hospital/completed-jobs"
            element={
              <ProtectedRoute tspColor>
                <CompletedJobs />
              </ProtectedRoute>
            }
          />
          <Route
            path="/hospital/hired-nurses"
            element={
              <ProtectedRoute tspColor>
                <HiredNurses />
              </ProtectedRoute>
            }
          />
          <Route
            path="/hospital/hired-nurses/:id"
            element={
              <ProtectedRoute>
                <ViewHiredNurse />
              </ProtectedRoute>
            }
          />
          <Route
            path="/hospital/active-jobs/:id"
            element={
              <ProtectedRoute>
                <ViewActiveJob />
              </ProtectedRoute>
            }
          />
          <Route
            path="/hospital/completed-jobs/:id"
            element={
              <ProtectedRoute>
                <ViewCompletedJob />
              </ProtectedRoute>
            }
          />
          <Route
            path="/hospital/pending-jobs/:id"
            element={
              <ProtectedRoute>
                <ViewPendingJob />
              </ProtectedRoute>
            }
          />
          <Route
            path="/hospital/create-job-ad"
            element={
              <ProtectedRoute>
                <CreateJobAd />
              </ProtectedRoute>
            }
          />
          <Route
            path="/hospital/edit-job/:id"
            element={
              <ProtectedRoute>
                <CreateJobAd />
              </ProtectedRoute>
            }
          />
          <Route
            path="/nurse/create-profile"
            element={
              <ProtectedRoute withoutLayout>
                <NurseProfileLayout />
              </ProtectedRoute>
            }
          />
          <Route path="/nurse/account-created" element={<NurseApprovedAC />} />
          <Route
            path="/nurse/dashboard"
            element={
              <ProtectedRoute tspColor>
                <NurseDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/nurse/applied-jobs"
            element={
              <ProtectedRoute tspColor>
                <AppliedJobs />
              </ProtectedRoute>
            }
          />
          <Route
            path="/nurse/completed-jobs"
            element={
              <ProtectedRoute tspColor>
                <NurseCompletedJobs />
              </ProtectedRoute>
            }
          />
          <Route
            path="/nurse/applied-jobs/:id"
            element={
              <ProtectedRoute>
                <ViewAppliedJob />
              </ProtectedRoute>
            }
          />
          <Route
            path="/nurse/completed-jobs/:id"
            element={
              <ProtectedRoute>
                <CompletedJobDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/nurse/dashboard/jobs/:id"
            element={
              <ProtectedRoute>
                <ApplyJob />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user/settings"
            element={
              <ProtectedRoute>
                <EditProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user/notifications"
            element={
              <ProtectedRoute>
                <Notifications />
              </ProtectedRoute>
            }
          />
        </Routes>
      </BrowserRouter>
      <Toaster
        position="top-center"
        toastOptions={{
          success: {
            style: {
              background: "lightgreen",
              color: "white",
            },
          },
          error: {
            style: {
              background: "red",
              color: "white",
            },
          },
        }}
      />
    </div>
  );
}

export default App;
