import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import clientImg from "../../assets/landing/client-img.svg";
import Carousel from "react-material-ui-carousel";
const ClientTestimonials = () => {
  return (
    <>
      <Box >
        <Container maxWidth="xl">
          <Box
            sx={{
              padding: {
                xs: "45px 0",
                sm: "60px 0",
                md: "60px 0",
                lg: "120px 0",
                xl: "120px 0",
              },
            }}
            className="custom-carousel"
          >
            <Carousel
              indicatorIconButtonProps={{
                style: {
                  // 1
                  color: "rgba(56, 135, 159, 0.2)", // 3
                },
              }}
              activeIndicatorIconButtonProps={{
                style: {
                  backgroundColor: "#38879F", // 2
                  color: "#38879F",
                },
              }}
              interval={2000}
              stopAutoPlayOnHover={true}
            >
              {[1, 2].map((i, idx) => {
                return (
                  <Grid container item key={i}>
                    <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Box
                        className={"client-syas-responsive"}
                        sx={{
                          textAlign: "left",
                          paddingRight: {
                            xs: 0,
                            sm: 0,
                            md: "10%",
                            lg: "20%",
                            xl: "20%",
                          },
                          paddingBottom: {
                            xs: "40px",
                            sm: "40px",
                            md: 0,
                            lg: 0,
                            xl: 0,
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "18px",
                            fontWeight: 700,
                            color: "#38879F",
                            lineHeight: "22px",
                            marginBottom: "10px",
                          }}
                          variant="h6"
                        >
                          Testimonials
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "35px",
                              sm: "35px",
                              md: "42px",
                              lg: "51px",
                              xl: "51px",
                            },
                            fontWeight: 700,
                            lineHeight: {
                              xs: "42px",
                              sm: "42px",
                              md: "47px",
                              lg: "61px",
                              xl: "61px",
                            },
                            color: "#0D2645",
                          }}
                          variant="h4"
                        >
                          What
                          <Typography
                            sx={{
                              color: "#38879F",
                            }}
                            variant="span"
                          >
                            Client
                          </Typography>{" "}
                          Say About{" "}
                          <Typography
                            variant="span"
                            sx={{
                              color: "#38879F",
                            }}
                          >
                            Us
                          </Typography>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={12} md={8} lg={8} xl={8}>
                      <Box>
                        <Box
                          sx={{
                            border: " 2px solid rgba(56, 135, 159, 0.25)",
                            padding: {
                              xs: "45px 20px 75px",
                              sm: "45px 20px 75px",
                              md: "40px 40px 80px 40px",
                              lg: "45px 200px 80px 40px",
                              xl: "45px 200px 80px 40px",
                            },
                            borderRadius: "12px",
                            overflowY: "scroll",
                            height: {
                              xs: "400px",
                              sm: "250px",
                              md: "300px",
                              lg: "250px",
                              xl: "200px"
                            }
                          }}
                        >
                          <Typography
                            sx={{
                              color: "rgba(56, 135, 159, 0.75)",
                              fontSize: "18px",
                              lineHeight: "34px",
                              fontWeight: 400,
                              textAlign: "left",
                            }}
                          >
                            {idx == 0
                              ? `"Transparent Care has made a huge difference in how I look at staffing agencies. The
platform is easy to use, and the real-time job listings mean I can find the right
opportunities quickly."`
                              : `"Working with Transparent Care has been a game-changer. Knowing that hospitals have
to keep a certain nurse driven star rating reassures me that the assignment will be
welcoming. I feel truly valued as an individual in my profession."`}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            position: "relative",
                            top: "-50px",
                            paddingLeft: "40px",
                            width: "135px",
                          }}
                        >
                          <Box
                            sx={{
                              width: "94px",
                              height: "94px",
                              borderRadius: "50%",
                              marginBottom: "15px",
                            }}
                          >
                            <img
                              src={
                                idx == 0
                                  ? "https://cdn.lifehack.org/wp-content/uploads/2015/02/what-makes-people-happy.jpeg"
                                  : clientImg
                              }
                              alt=""
                              className="client-test-img"
                            />
                          </Box>
                          <Typography
                            sx={{
                              color: "#0D2645",
                              fontSize: "14px",
                              fontWeight: 700,
                              lineHeight: "17px",
                            }}
                            variant="h6"
                          >
                            {idx === 0 ? "Stephanie D." : "Emily R."}
                          </Typography>
                          <Typography
                            sx={{
                              color: "rgba(56, 135, 159, 0.75)",
                              fontSize: "14px",
                              fontWeight: 500,
                              lineHeight: "17px",
                              marginTop: "7px",
                            }}
                          >
                            Registered Nurse
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                );
              })}
            </Carousel>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ClientTestimonials;
