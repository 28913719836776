import { Box } from "@mui/material";
import { useEffect, useRef } from "react";

export default function InfiniteScrollWrapper(props) {
  const { children, handleSetPage, hasResult } = props;
  const listRef = useRef();
  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = listRef.current;
      console.log(scrollTop, clientHeight, scrollHeight, "scrollHeight");
      if (
        (Math.round(scrollTop) + clientHeight >= scrollHeight ||
          Math.round(scrollTop) + clientHeight >= scrollHeight - 1) &&
        hasResult
      ) {
        handleSetPage();
      }
    };

    listRef.current?.addEventListener("scroll", handleScroll);
    return () => listRef.current?.removeEventListener("scroll", handleScroll);
  }, [hasResult]);
  return (
    <Box
      sx={{
        overflowY: "auto",
        maxHeight: {
          xs: "initial",
          sm: "initial",
          md: "initial",
          lg: "80vh",
          xl: "80vh",
        },
        paddingBottom: "20px",
      }}
      ref={listRef}
    >
      {children}
    </Box>
  );
}
