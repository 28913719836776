import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import footerLogo from "../../assets/landing/footer-logo.svg";
import facebook from "../../assets/landing/facebook.svg";
import twitter from "../../assets/landing/twitter.svg";
import youtube from "../../assets/landing/youtube.svg";
import { Link, useNavigate } from "react-router-dom";
import callIcon from "../../assets/icons/call.svg";
const Footer = (props) => {
  const { homeID, howWeWorkID, faqID, whoWeID } = props;
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          background: "#0D2645",
        }}
      >
        <Container
          maxWidth={"xl"}
        >
          <Box
            sx={{
              padding: {
                xs: "45px 0",
                sm: "60px 0",
                md: "60px 0",
                lg: "60px 0 40px",
                xl: "60px 0 40px",
              },
            }}
          >
            <Grid container item spacing={12}>
              {/*mobile time hide*/}
              <Grid
                sx={{
                  textAlign: "left",
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                    xl: "block",
                  },
                }}
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
                item
              >
                <Box
                  display={"flex"}
                  justifyContent={"space-evenly"}
                  alignItems={"center"}
                >
                  <img style={{ cursor: "pointer" }} alt="" src={footerLogo} />
                  <Box>
                    <Typography color="#fff">
                      106 Langtree Village Road Suite 301
                      <br />
                      Mooresville, NC 28117
                    </Typography>
                    <Box marginTop={"10px"}>
                      <Link
                        to="tel:+18885089112"
                        style={{ textDecoration: "none" }}
                      >
                        <Box display={"flex"} alignItems={"center"}>
                          <img
                            src={callIcon}
                            alt="call"
                            width={30}
                            height={30}
                            style={{
                              background: "#fff",
                              padding: "6px",
                              borderRadius: "50%",
                            }}
                          />
                          <Typography marginLeft={1} color="#fff">
                            +1 888-508-9112
                          </Typography>
                        </Box>
                      </Link>
                      <Link
                        to="tel:+18885089112"
                        style={{
                          color: "white",
                        }}
                      >
                        <Typography marginTop={"10px"}>
                          {"Call Now >>"}
                        </Typography>
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              {/*mobile time hide END*/}
              <Grid xs={12} sm={12} md={7} lg={7} xl={7} item>
                <Grid container item>
                  <Grid xs={12} sm={6} md={4} lg={4} xl={4}>
                    <Box
                      sx={{
                        marginBottom: "40px",
                        textAlign: "left",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "20px",
                          fontWeight: 500,
                          lineHeight: "25px",
                          cursor: "pointer",
                        }}
                        variant="a"
                        onClick={() => {
                          const elem = document.getElementById(homeID);
                          elem.scrollIntoView({ behavior: "smooth" });
                        }}
                      >
                        Home
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginBottom: "40px",
                        textAlign: "left",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "20px",
                          fontWeight: 500,
                          lineHeight: "25px",
                          cursor: "pointer",
                        }}
                        variant="a"
                        onClick={() => {
                          const elem = document.getElementById(whoWeID);
                          elem.scrollIntoView({ behavior: "smooth" });
                        }}
                      >
                        Who We Are
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginBottom: "40px",
                        textAlign: "left",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "20px",
                          fontWeight: 500,
                          lineHeight: "25px",
                          cursor: "pointer",
                        }}
                        variant="a"
                        onClick={() => {
                          const elem = document.getElementById(howWeWorkID);
                          elem.scrollIntoView({ behavior: "smooth" });
                        }}
                      >
                        How We Work
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginBottom: "40px",
                        textAlign: "left",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "20px",
                          fontWeight: 500,
                          lineHeight: "25px",
                          cursor: "pointer",
                        }}
                        variant="a"
                        onClick={() => {
                          const elem = document.getElementById(faqID);
                          elem.scrollIntoView({ behavior: "smooth" });
                        }}
                      >
                        FAQs
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid xs={12} sm={6} md={4} lg={4} xl={4}>
                    <Box
                      sx={{
                        marginBottom: "40px",
                        textAlign: "left",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "20px",
                          fontWeight: 500,
                          lineHeight: "25px",
                          cursor: "pointer",
                        }}
                        variant="a"
                        onClick={() => navigate("/terms-and-conditions")}
                      >
                        Terms & Conditions
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginBottom: "40px",
                        textAlign: "left",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "20px",
                          fontWeight: 500,
                          lineHeight: "25px",
                          cursor: "pointer",
                        }}
                        variant="a"
                        onClick={() => navigate("/privacy-policy")}
                      >
                        Privacy Policy
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    sx={{
                      borderTop: {
                        xs: "1px solid rgba(255,255,255,0.2)",
                        sm: "1px solid rgba(255,255,255,0.2)",
                        md: "none",
                        lg: "none",
                        xl: "none",
                      },
                      display: {
                        xs: "flex",
                        sm: "flex",
                        md: "block",
                        lg: "block",
                        xl: "block",
                      },
                      alignItems: {
                        xs: "center",
                        sm: "center",
                        md: "flex-start",
                        lg: "flex-start",
                        xl: "flex-start",
                      },
                      justifyContent: {
                        xs: "center",
                        sm: "center",
                        md: "flex-start",
                        lg: "flex-start",
                        xl: "flex-start",
                      },
                      paddingTop: {
                        xs: "40px",
                        sm: "40px",
                        md: 0,
                        lg: 0,
                        xl: 0,
                      },
                    }}
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                  >
                    <Box
                      sx={{
                        marginBottom: "40px",
                        textAlign: "left",
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "block",
                          lg: "block",
                          xl: "block",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "20px",
                          fontWeight: 500,
                          lineHeight: "25px",
                        }}
                      >
                        Follow Us-
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginBottom: "30px",
                        display: "flex",
                        alignItems: "center",
                        textAlign: "left",
                        padding: {
                          xs: "0 15px",
                          sm: "0 15px",
                          md: 0,
                          lg: 0,
                          xl: 0,
                        },
                      }}
                    >
                      <img src={facebook} alt="" />
                      <Link
                        target="_blank"
                        to="https://www.facebook.com/profile.php?id=61558132444567"
                        style={{ textDecoration: "none" }}
                      >
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: "20px",
                            fontWeight: 500,
                            lineHeight: "25px",
                            marginLeft: "10px",
                            display: {
                              xs: "none",
                              sm: "none",
                              md: "block",
                              lg: "block",
                              xl: "block",
                            },
                          }}
                          variant="a"
                        >
                          Facebook
                        </Typography>
                      </Link>
                    </Box>
                    <Box
                      sx={{
                        marginBottom: "30px",
                        display: "flex",
                        alignItems: "center",
                        textAlign: "left",
                        padding: {
                          xs: "0 15px",
                          sm: "0 15px",
                          md: 0,
                          lg: 0,
                          xl: 0,
                        },
                      }}
                    >
                      <img
                        src={twitter}
                        alt=""
                        style={{
                          background: "#fff",
                          padding: "6px",
                          borderRadius: "50%",
                        }}
                      />
                      <Link
                        target="_blank"
                        to="https://x.com/@traveljobsllc"
                        style={{ textDecoration: "none" }}
                      >
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: "20px",
                            fontWeight: 500,
                            lineHeight: "25px",
                            marginLeft: "10px",
                            display: {
                              xs: "none",
                              sm: "none",
                              md: "block",
                              lg: "block",
                              xl: "block",
                            },
                          }}
                          variant="a"
                        >
                          Twitter
                        </Typography>
                      </Link>
                    </Box>
                    <Box
                      sx={{
                        marginBottom: "30px",
                        display: "flex",
                        alignItems: "center",
                        textAlign: "left",
                        padding: {
                          xs: "0 15px",
                          sm: "0 15px",
                          md: 0,
                          lg: 0,
                          xl: 0,
                        },
                      }}
                    >
                      <img src={youtube} alt="" />
                      <Link
                        target="_blank"
                        to="https://youtube.com/@transparentcare?si=-6ZF9w6v0ApuqBGo"
                        style={{ textDecoration: "none" }}
                      >
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: "20px",
                            fontWeight: 500,
                            lineHeight: "25px",
                            marginLeft: "10px",
                            display: {
                              xs: "none",
                              sm: "none",
                              md: "block",
                              lg: "block",
                              xl: "block",
                            },
                          }}
                          variant="a"
                        >
                          Youtube
                        </Typography>
                      </Link>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "none",
                  lg: "none",
                  xl: "none",
                },
                flexDirection: "column",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img style={{ cursor: "pointer" }} alt="" src={footerLogo} />
              </Box>
              <Box>
                <Typography color="#fff">
                  106 Langtree Village Road Suite 301
                  <br />
                  Mooresville, NC 28117
                </Typography>
                <Box marginTop={"10px"}>
                  <Link
                    to="tel:+18885089112"
                    style={{ textDecoration: "none" }}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      gap={1}
                    >
                      <img
                        src={callIcon}
                        alt="call"
                        width={30}
                        height={30}
                        style={{
                          background: "#fff",
                          padding: "6px",
                          borderRadius: "50%",
                          marginTop: "6px",
                        }}
                      />
                      <Typography color="#fff" marginTop={"5px"}>
                        +1 888-508-9112
                      </Typography>
                    </Box>
                  </Link>
                  <Link
                    to="tel:+18885089112"
                    style={{
                      color: "white",
                    }}
                  >
                    <Typography marginTop={"10px"}>{"Call Now >>"}</Typography>
                  </Link>
                </Box>
              </Box>
            </Box>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "19px",
                color: "#fff",
                textAlign: "center",
                marginTop: "50px",
              }}
            >
              Copyright &copy; 2024 Transparent Care
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
