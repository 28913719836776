import { Box, Button, Chip, Divider, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GetJobAppliedList } from "../../../controller/services/nurse/jobs";
import {
  setActiveSelectedTab,
  setLoading,
  setSelectedType,
} from "../../../redux/slices/flags_slice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { format } from "date-fns";
import { getFormattedValue, getShiftNames } from "../../../utils/helper";
import NoDataFound from "../../common/no-data";
import InfiniteScrollWrapper from "../../common/infinite-scroll";
import { setBreadcrumb } from "../../../redux/slices/breadcrumb_slice";
import EmptyIcon from "../../../assets/icons/empty-board.svg";

const tabStyle = {
  width: "24%",
  height: {
    xs: "45px",
    sm: "48px",
    md: "55px",
    lg: "55px",
    xl: "55px",
  },
  marginTop: {
    xs: "10px",
    sm: "10px",
    md: "10px",
    lg: "15px",
    xl: "15px",
  },
  background: "#fff",
  color: "#0D2645",
  fontWeight: 500,
  fontSize: {
    xs: "10px",
    sm: "12px",
    md: "14px",
    lg: "18px",
    xl: "18px",
  },
  lineHeight: {
    xs: "13px",
    sm: "15px",
    md: "17px",
    lg: "21.6px",
    xl: "21.6px",
  },
  borderRadius: "10px",
  marginRight: {
    xs: "5px",
    sm: "10px",
    md: "15px",
    lg: "20px",
    xl: "20px",
  },
  "&:hover": {
    backgroundColor: "#fff",
  },
};

const labelStyles = {
  fontFamily: "Lato",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "19.4px",
  textAlign: "left",
  color: "#8592a1",
};

const valueStyles = {
  fontFamily: "Lato",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "19.2px",
  textAlign: "left",
  width: "auto",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
    paddingRight:'15px'
};

export default function AppliedJobs() {
  const [jobList, setJobList] = useState([]);
  const [page, setPage] = useState(1);
  const type = useSelector((state) => state.flags.type);
  const [hasNextResult, setHasNextResult] = useState(0);
  const [counts, setCounts] = useState(null);
  const [noDataTitle, setNoDataTitle] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    if (page) {
      fetchAllJobs();
    }
  }, [page, type]);

  useEffect(() => {
    setPage(1);
    if (type == "pending") {
      setNoDataTitle("No Pending Jobs Available");
    } else if (type == "declined") {
      setNoDataTitle("No Declined Jobs Available");
    } else if (type == "offerReceived") {
      setNoDataTitle("No Offer Received");
    } else {
      setNoDataTitle("No Accepted Offers");
    }
  }, [type]);

  useEffect(() => {
    const arr = [
      {
        title: "Jobs Applied",
        route: "",
      },
    ];
    dispatch(setBreadcrumb(arr));
  }, []);
  const fetchAllJobs = () => {
    dispatch(setLoading(true));
    const payload = {
      status: type,
      page: page,
      limit: 10,
    };
    GetJobAppliedList(payload)
      .then((resp) => {
        if (page == 1) {
          setJobList(resp.data.list);
        } else {
          setJobList((prev) => [...prev, ...resp.data.list]);
        }
        setHasNextResult(resp.data?.hasResult);
        setCounts(resp.data?.applicationCount);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        marginBottom={"30px"}
        sx={{
          width: {
            xs: "100%",
            sm: "100%",
            md: "90%",
            lg: "90%",
            xl: "65%",
          },
        }}
      >
        <Button
          sx={{
            ...tabStyle,
            ...(type === "pending"
              ? {
                  background: "#38879f",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#38879f",
                  },
                }
              : {}),
          }}
          onClick={() => dispatch(setSelectedType("pending"))}
        >
          Pending ({counts?.pendingApplicationCount || 0})
        </Button>
        <Button
          sx={{
            ...tabStyle,
            ...(type === "declined"
              ? {
                  background: "#38879f",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#38879f",
                  },
                }
              : {}),
          }}
          onClick={() => dispatch(setSelectedType("declined"))}
        >
          Declined ({counts?.declinedApplicationCount || 0})
        </Button>
        <Button
          sx={{
            ...tabStyle,
            ...(type === "offerReceived"
              ? {
                  background: "#38879f",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#38879f",
                  },
                }
              : {}),
          }}
          onClick={() => dispatch(setSelectedType("offerReceived"))}
        >
          Offer Received ({counts?.offerReceivedCount || 0})
        </Button>
        <Button
          sx={{
            ...tabStyle,
            ...(type === "accepted"
              ? {
                  background: "#38879f",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#38879f",
                  },
                }
              : {}),
          }}
          onClick={() => dispatch(setSelectedType("accepted"))}
        >
          Accepted ({counts?.acceptedApplicationCount || 0})
        </Button>
      </Box>
      {jobList?.length > 0 ? (
        <InfiniteScrollWrapper
          handleSetPage={() => setPage((prev) => prev + 1)}
          hasResult={hasNextResult}
        >
          <Grid container spacing={4}>
            {jobList.map((job) => {
              return <AppliedJobCard job={job} key={job?._id} />;
            })}
          </Grid>
        </InfiniteScrollWrapper>
      ) : (
        <NoDataFound title={noDataTitle} icon={EmptyIcon} height="60vh" />
      )}
    </Box>
  );
}

const AppliedJobCard = ({ job }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Grid xs={12} sm={12} md={12} item lg={6} xl={6}>
      <Box
        bgcolor={"#fff"}
        padding={"20px"}
        borderRadius={"25px"}
        boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
        height="100%"
      >
        <Grid container>
          <Grid xs={12} sm={2} md={2} lg={1} xl={1} item>
            <img
              src={job?.hospitalDetails?.hospitalLogo}
              alt="logo"
              width={50}
              height={50}
              style={{ borderRadius: "50%" }}
            />
          </Grid>
          <Grid
            xs={12}
            sm={10}
            md={10}
            lg={11}
            xl={11}
            item
            sx={{
              paddingLeft: {
                xs: 0,
                sm: "10px",
                md: "15px",
                lg: "20px",
                xl: "20px",
              },
              paddingTop: {
                xs: "15px",
                sm: 0,
                md: 0,
                lg: 0,
                xl: 0,
              },
            }}
          >
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "18px",
                      sm: "18px",
                      md: "20px",
                      lg: "20px",
                      xl: "20px",
                    },
                  }}
                  fontWeight={700}
                  lineHeight={"24px"}
                  textAlign={"left"}
                  color={"primary"}
                >
                  {job?.jobDetails?.title}
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "14px",
                      lg: "15px",
                      xl: "16px",
                    },
                    paddingTop: {
                      xs: 0,
                      sm: "3px",
                      md: "5px",
                      lg: "7px",
                      xl: "7px",
                    },
                  }}
                  fontWeight={700}
                  lineHeight={"19.2px"}
                  color="#8592a1"
                  textAlign={"left"}
                >
                  {job?.hospitalDetails?.hospitalName}
                </Typography>
              </Box>
              <Chip
                sx={{
                  background: "#ecf3f6",
                }}
                label={
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontSize: {
                          xs: "10px",
                          sm: "11px",
                          md: "12px",
                          lg: "12px",
                          xl: "12px",
                        },
                        fontWeight: 400,
                        lineHeight: "12px",
                        textAlign: "left",
                        color: "#50647a",
                      }}
                    >
                      Start On:
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontSize: {
                          xs: "14px",
                          sm: "15px",
                          md: "16px",
                          lg: "16px",
                          xl: "16px",
                        },
                        fontWeight: 500,
                        lineHeight: "19.2px",
                        textAlign: "left",
                        color: "#0D2645",
                      }}
                    >
                      &nbsp;
                      {job?.jobDetails?.startDate
                        ? format(
                            new Date(job?.jobDetails?.startDate),
                            "dd MMM, yyyy"
                          )
                        : ""}
                    </Typography>
                  </Box>
                }
                variant="filled"
              />
            </Box>
            <Grid container>
              <Grid
                className="border-right-line"
                sx={{ position: "relative" }}
                xs={6}
                sm={6}
                lg={6}
                xl={4.5}
                md={4}
                item
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  sx={{
                    padding: {
                      xs: "10px 5px",
                      sm: "10px 5px",
                      md: "15px 0",
                      lg: "15px 0",
                      xl: "15px 0",
                    },
                  }}
                >
                  <Typography sx={{ ...labelStyles }}>Shift:</Typography>
                  <Typography sx={{ ...valueStyles }} color={"primary"}>
                    &nbsp;
                    {job?.jobDetails?.shifts?.map((shift, idx) => {
                      return (
                        getShiftNames(shift) +
                        (job?.jobDetails?.shifts?.length !== idx + 1
                          ? ", "
                          : "")
                      );
                    })}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={6} sm={6} lg={6} xl={4.5} md={4} item>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  sx={{
                    padding: {
                      xs: "10px 5px",
                      sm: "10px 5px",
                      md: "15px 0",
                      lg: "15px 0",
                      xl: "15px 0",
                    },
                  }}
                >
                  <Typography sx={{ ...labelStyles }}>Duration:</Typography>
                  <Typography sx={{ ...valueStyles }} color={"primary"}>
                    &nbsp;{job?.jobDetails?.duration} weeks
                  </Typography>
                </Box>
              </Grid>
              {/* <Grid md={4} item> */}
              {/* <Box display={"flex"} alignItems={"center"} padding={"15px 0"}>
                    <Typography sx={{ ...labelStyles }}>
                      Open Positions:
                    </Typography>
                    <Typography sx={{ ...valueStyles }} color={"primary"}>
                      &nbsp;{job?.numberOfOpenings}
                    </Typography>
                  </Box> */}
              {/* </Grid> */}
              {/* <Grid md={4.5} item>
                <Box display={"flex"} alignItems={"center"} padding={"15px 0"}>
                  <Typography sx={{ ...labelStyles }}>
                    Experience Required:
                  </Typography>
                  <Typography sx={{ ...valueStyles }} color={"primary"}>
                    &nbsp;{job?.jobDetails?.experience} years
                  </Typography>
                </Box>
              </Grid>
              <Grid md={4.5} item>
                <Box display={"flex"} alignItems={"center"} padding={"15px 0"}>
                  <Typography sx={{ ...labelStyles }}>
                    Open Positions:
                  </Typography>
                  <Typography sx={{ ...valueStyles }} color={"primary"}>
                    &nbsp;{job?.jobDetails?.numberOfOpenings}
                  </Typography>
                </Box>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
        <Divider
          sx={{
            marginBottom: {
              xs: "10px",
              sm: "10px",
              md: "15px",
              lg: "15px",
              xl: "15px",
            },
          }}
        />
        <Grid container alignItems={"center"}>
          <Grid xs={6} sm={6} lg={6} xl={6} md={6}>
            <Box display={"flex"} alignItems={"center"}>
              <Typography sx={{ ...labelStyles }}>Job Offer:</Typography>
              <Typography sx={{ ...valueStyles }}>
                &nbsp;
                {getFormattedValue(
                  job?.jobDetails?.weeklyPay +
                    job?.jobDetails?.houseAllowance +
                    job?.jobDetails?.mealAllowance
                )}{" "}
                <span style={{ ...labelStyles }}>/wk</span>
              </Typography>
            </Box>
          </Grid>
          <Grid xs={6} sm={6} lg={6} xl={6} md={6} textAlign={"right"} item>
            <Button
              sx={{
                background: "#38879f",
                color: "#fff",
                fontWeight: 600,
                padding: "5px 15px",
                borderRadius: "50px",
                "&.Mui-disabled": {
                  background: "#adcdd7",
                  color: "#fff",
                },
                "&:hover": {
                  backgroundColor: "#38879f",
                },
              }}
              onClick={() => {
                navigate(`/nurse/applied-jobs/${job?._id}`);
                dispatch(setActiveSelectedTab(0));
              }}
            >
              Details
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
