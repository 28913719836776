import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  ListItemIcon,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { GetJobDetails } from "../../../../controller/services/hospital/jobs";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveSelectedTab,
  setLoading,
} from "../../../../redux/slices/flags_slice";
import { format } from "date-fns";
import StyledChip from "../../../common/styled/chip";
import { getFormattedValue, getShiftNames } from "../../../../utils/helper";
import DocIcon from "../../../../assets/icons/policy.svg";
import StarIcon from "../../../../assets/icons/star.svg";
import BackNavigation from "../../../../assets/icons/back-profile.svg";
import ClockIcon from "../../../../assets/icons/clock.svg";
import EmailIcon from "../../../../assets/icons/user-email.svg";
import CallIcon from "../../../../assets/icons/user-call.svg";
import CommonModal from "../../../common/modals/commonModal";
import TickCardIcon from "../../../../assets/icons/tick-card.svg";
import { setBreadcrumb } from "../../../../redux/slices/breadcrumb_slice";
import NurseIcon from "../../../../assets/icons/nurse.svg";
import RatingModal from "../../../common/modals/ratingModal";
import { SendReview } from "../../../../controller/services/common";
import { RatingsAndReviewsListHos } from "../active-jobs/viewActiveJob";

const labelStyles = {
  fontFamily: "Lato",
  fontSize: {
    xs: "16px",
    sm: "16px",
    md: "18px",
    lg: "18px",
    xl: "18px",
  },
  fontWeight: 700,
  lineHeight: "21.6px",
  color: "#0D2645",
  padding: "10px 0",
};

const valueStyles = {
  fontFamily: "Lato",
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "16px",
    lg: "16px",
    xl: "16px",
  },
  fontWeight: 400,
  lineHeight: "26px",
  color: "#8b97a6",
  padding: {
    xs: "0 0 10px",
    sm: "0 0 10px",
    md: "10px 0",
    lg: "10px 0",
    xl: "10px 0",
  },
};

const userMenus = [
  {
    index: 0,
    title: "Job Description",
    Icon: DocIcon,
  },
  {
    index: 1,
    title: "Hired Nurses",
    Icon: NurseIcon,
    key: "hiredNurses",
  },
];

const styles = {
  color: "#0d2645",
  fontSize: {
    xs: "14px",
    sm: "15px",
    md: "15px",
    lg: "16px",
    xl: "16px",
  },
  lineHeight: "18.5px",
  fontWeight: 500,
  margin: {
    xs: 0,
    sm: "15px 0 0",
    md: "15px 0 0",
    lg: "15px 0",
    xl: "15px",
  },
  padding: {
    xs: "0 10px",
    sm: "10px",
    md: "10px",
    lg: "10px",
    xl: "10px 20px",
  },
  borderBottom: {
    xs: "5px solid transparent",
    sm: 0,
    md: 0,
    lg: 0,
    xl: 0,
  },
  width: {
    xs: "48%",
    sm: "auto",
    md: "auto",
    lg: "auto",
    xl: "auto",
  },
  borderRadius: {
    xs: 0,
    sm: "10px",
    md: "10px",
    lg: "10px",
    xl: "10px",
  },
  float: {
    xs: "left",
    sm: "none",
    md: "none",
    lg: "none",
    xl: "none",
  },
};

const statusColors = {
  pending: "#F19000",
  declined: "#da0000",
  offerSent: "#00b81d",
};

const statusLabels = {
  pending: "Pending",
  offerSent: "Accepted",
  declined: "Declined",
};

export default function ViewCompletedJob() {
  const params = useParams();
  const dispatch = useDispatch();
  const [jobData, setJobData] = useState(null);
  const selected = useSelector((state) => state.flags?.selectedActiveTab);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [showRatings, setShowRatings] = useState(false);

  useEffect(() => {
    fetchJobDetails();
  }, []);
  useEffect(() => {
    const arr = [
      {
        title: "Completed Jobs",
        route: "/hospital/active-jobs",
      },
      {
        title: jobData?.title,
        route: "",
      },
    ];
    if (jobData?.title) {
      dispatch(setBreadcrumb(arr));
    }
  }, [jobData?.title, selected]);
  const fetchJobDetails = () => {
    dispatch(setLoading(true));
    GetJobDetails(params.id)
      .then((resp) => {
        setJobData(resp.data.job);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const handleClickMenu = (item) => {
    dispatch(setActiveSelectedTab(item.index));
  };

  if (jobData) {
    return (
      <Box
        sx={{
          padding: {
            xs: "20px 15px",
            sm: "20px 15px",
            md: "20px",
            lg: "30px",
            xl: "30px",
          },
        }}
        maxHeight={"90vh"}
        overflow={"auto"}
      >
        {showRatings ? (
          <RatingsAndReviewsListHos
            newUserDetails={showUserDetails}
            breadCrumb={[
              {
                title: "Completed Jobs",
                route: "/hospital/completed-jobs",
              },
              {
                title: jobData?.title,
                route: `/hospital/completed-jobs/${jobData?._id}`,
                func: () => {
                  setShowUserDetails(false);
                  setShowRatings(false);
                },
              },
              {
                title: "Hired Nurses",
                route: `/hospital/completed-jobs/${jobData?._id}`,
                func: () => {
                  setShowUserDetails(false);
                  setShowRatings(false);
                },
              },
              {
                title:
                  showUserDetails?.firstName + " " + showUserDetails?.lastName,
                route: "",
              },
            ]}
            handleHideRatings={() => {
              setShowRatings(false);
              const arr = [
                {
                  title: "Active Jobs",
                  route: "/hospital/active-jobs",
                },
                {
                  title: jobData?.title,
                  route: `/hospital/active-jobs/${jobData?._id}`,
                  func: () => {
                    setShowUserDetails(false);
                  },
                },
                {
                  title: selected,
                  route: `/hospital/completed-jobs/${jobData?._id}`,
                  func: () => {
                    setShowUserDetails(false);
                  },
                },
                {
                  title:
                    showUserDetails?.firstName +
                    " " +
                    showUserDetails?.lastName,
                  route: "",
                },
              ];
              if (jobData?.title) {
                dispatch(setBreadcrumb(arr));
              }
            }}
            handleShowUser={() => setShowUserDetails(false)}
            jobData={jobData}
            type={selected}
            isNotActive
          />
        ) : (
          <>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                sx={{
                  width: {
                    xs: "100%",
                    sm: "auto",
                    md: "auto",
                    lg: "auto",
                    xl: "auto",
                  },
                  marginBottom: {
                    xs: "10px",
                    sm: "20px",
                    md: "20px",
                    lg: "20px",
                    xl: "20px",
                  },
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                    md: "row",
                    lg: "row",
                    xl: "row",
                  },
                }}
              >
                <Typography
                  sx={{
                    textAlign: "left",
                    marginBottom: {
                      xs: "5px",
                      sm: "10px",
                      md: 0,
                      lg: 0,
                      xl: 0,
                    },
                    fontFamily: "Lato",
                    fontSize: {
                      xs: "16px",
                      sm: "18px",
                      md: "22px",
                      lg: "24px",
                      xl: "24px",
                    },
                    fontWeight: 700,
                    lineHeight: "28.8px",
                    color: "#0D2645",
                    marginRight: {
                      xs: 0,
                      sm: "10px",
                      md: "10px",
                      lg: "10px",
                      xl: "10px",
                    },
                  }}
                >
                  {jobData?.title}
                </Typography>
                <Chip
                  sx={{
                    background: "#ecf3f6",
                    width: {
                      xs: "165px",
                      sm: "200px",
                      md: "auto",
                      lg: "auto",
                      xl: "auto",
                    },
                  }}
                  label={
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "10px",
                            sm: "10px",
                            md: "11px",
                            lg: "12px",
                            xl: "12px",
                          },
                          fontWeight: 400,
                          lineHeight: "12px",
                          textAlign: "left",
                          color: "#50647a",
                        }}
                      >
                        Start Date:
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "14px",
                            sm: "15px",
                            md: "16px",
                            lg: "16px",
                            xl: "16px",
                          },
                          fontWeight: 600,
                          lineHeight: "19.2px",
                          textAlign: "left",
                          color: "#0D2645",
                        }}
                      >
                        &nbsp;
                        {jobData?.startDate
                          ? format(new Date(jobData?.startDate), "dd MMM, yyyy")
                          : ""}
                      </Typography>
                    </Box>
                  }
                  variant="filled"
                />
              </Box>
              <Box>
                {/* <img
                src={EditJobIcon}
                alt="edit job"
                onClick={() => navigate(`/hospital/edit-job/${jobData?._id}`)}
              /> */}
              </Box>
            </Box>
            <Grid container>
              <Grid
                sx={{ padding: "5px 2px" }}
                xs={6}
                sm={6}
                md={6}
                lg={4}
                xl={2}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Job Offer:"}
                  value={`${getFormattedValue(
                    jobData?.weeklyPay +
                      jobData?.houseAllowance +
                      jobData?.mealAllowance
                  )} /wk`}
                />
              </Grid>
              <Grid
                sx={{
                  padding: "5px 2px",
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    xl: "block",
                    lg: "block",
                  },
                }}
                xs={12}
                sm={6}
                md={6}
                lg={5}
                xl={3}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Shift:"}
                  value={jobData?.shifts?.map((shift, idx) => {
                    return (
                      <Box
                        sx={{
                          display: "inline-block",
                          width: "auto !important",
                        }}
                        key={shift}
                      >
                        {getShiftNames(shift) +
                          (jobData?.shifts?.length !== idx + 1 ? ", " : "")}
                      </Box>
                    );
                  })}
                />
              </Grid>
              <Grid
                sx={{ padding: "5px 2px" }}
                xs={6}
                sm={6}
                md={4}
                lg={3}
                xl={2}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Duration:"}
                  value={`${jobData?.duration} Weeks`}
                />
              </Grid>
              <Grid
                sx={{
                  padding: "5px 2px",
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    xl: "none",
                    lg: "none",
                  },
                }}
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={3}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Shift:"}
                  value={jobData?.shifts?.map((shift, idx) => {
                    return (
                      <Box
                        sx={{
                          display: "inline-block",
                          width: "auto !important",
                        }}
                        key={shift}
                      >
                        {getShiftNames(shift) +
                          (jobData?.shifts?.length !== idx + 1 ? ", " : "")}
                      </Box>
                    );
                  })}
                />
              </Grid>
              <Grid
                sx={{ padding: "5px 2px" }}
                xs={6}
                sm={4}
                md={4}
                lg={3}
                xl={2}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Exp. Required:"}
                  value={`${jobData?.experience} Years`}
                />
              </Grid>
              <Grid
                sx={{ padding: "5px 2px" }}
                xs={6}
                sm={4}
                md={4}
                lg={3}
                xl={2}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Hired Positions:"}
                  value={jobData?.numberOfOpenings}
                />
              </Grid>
            </Grid>
            <Divider
              sx={{
                margin: {
                  xs: "10px 0",
                  sm: "10px 0",
                  md: "20px 0",
                  lg: "20px 0",
                  xl: "20px 0",
                },
              }}
            />
            {showUserDetails ? (
              <Grid container item>
                <Grid md={12}>
                  <ViewApplicantDetails
                    jobData={jobData}
                    userDetails={showUserDetails}
                    handleShowRatings={() => setShowRatings(true)}
                    closeShowDetails={() => {
                      setShowUserDetails(null);
                      const arr = [
                        {
                          title: "Completed Jobs",
                          route: "/hospital/completed-jobs",
                        },
                        {
                          title: jobData?.title,
                          route: `/hospital/completed-jobs/${jobData?._id}`,
                          func: () => setShowUserDetails(null),
                        },
                      ];
                      if (jobData?.title) {
                        dispatch(setBreadcrumb(arr));
                      }
                    }}
                    handleSelectTab={(num) =>
                      dispatch(setActiveSelectedTab(num))
                    }
                    selected={selected}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container item columnSpacing={3}>
                <Grid xs={12} sm={4} md={5} lg={4} xl={3} item>
                  <MenuList
                    sx={{
                      padding: {
                        xs: "0 !important",
                        sm: "10px !important",
                        md: "10px !important",
                        lg: "10px !important",
                        xl: "10px !important",
                      },
                      display: {
                        xs: "flex",
                        sm: "block",
                        md: "block",
                        lg: "block",
                        xl: "block",
                      },
                      borderRadius: 0,
                      borderBottom: {
                        xs: "1px solid rgba(0, 0, 0, 0.12)",
                        sm: 0,
                        md: 0,
                        lg: 0,
                        xl: 0,
                      },
                      overflow: {
                        xs: "auto",
                        sm: "initial",
                        md: "initial",
                        lg: "initial",
                        xl: "initial",
                      },
                    }}
                    variant="selectedMenu"
                  >
                    {userMenus.map((item) => {
                      const isActive = item.index === selected;
                      return (
                        <MenuItem
                          key={item.index}
                          onClick={() => handleClickMenu(item)}
                          sx={{
                            ...styles,
                            ...(isActive && {
                              color: {
                                xs: "#38879F",
                                sm: "#fff",
                                md: "#fff",
                                lg: "#fff",
                                xl: "#fff",
                              },
                              background: {
                                xs: "transparent",
                                sm: "#38879f",
                                md: "#38879f",
                                lg: "#38879f",
                                xl: "#38879f",
                              },
                              padding: {
                                xs: "0 10px",
                                sm: "10px",
                                md: "10px",
                                lg: "10px",
                                xl: "10px 20px",
                              },
                              borderBottom: {
                                xs: "5px solid #38879f",
                                sm: 0,
                                md: 0,
                                lg: 0,
                                xl: 0,
                              },
                              borderRadius: {
                                xs: 0,
                                sm: "10px",
                                md: "10px",
                                lg: "10px",
                                xl: "10px",
                              },
                              fontWeight: 700,
                            }),
                            "&:hover": {
                              color: "#0d2645",
                              padding: {
                                xs: "0 10px",
                                sm: "10px",
                                md: "10px",
                                lg: "10px",
                                xl: "10px 20px",
                              },
                              borderBottom: {
                                xs: "5px solid #38879F",
                                sm: 0,
                                md: 0,
                                lg: 0,
                                xl: 0,
                              },
                            },
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              display: {
                                xs: "none !important",
                                sm: "inline-flex !important",
                                md: "inline-flex !important",
                                lg: "inline-flex !important",
                                xl: "inline-flex !important",
                              },
                            }}
                          >
                            <img src={item.Icon} alt="app-icon" />
                          </ListItemIcon>
                          <Typography variant="inherit">
                            {`${item.title}${
                              item.key
                                ? ` (${
                                    jobData && jobData[item.key]
                                      ? jobData[item.key].length
                                      : 0
                                  })`
                                : ""
                            }`}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </Grid>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{
                    mr: "-1px",
                    borderColor: "#dcefe7",
                    borderRadius: "12px",
                  }}
                />
                <Grid
                  xl={9}
                  lg={8}
                  md={8}
                  xs={12}
                  sm={8}
                  sx={{
                    height: {
                      xs: "auto",
                      sm: "auto",
                      md: "auto",
                      lg: "auto",
                      xl: "auto",
                    },
                  }}
                  item
                >
                  {selected === 0 ? (
                    <JobDescription jobData={jobData} />
                  ) : (
                    <PendingCandidates
                      jobData={jobData}
                      type="pending"
                      handleShowUser={(data) => {
                        setShowUserDetails(data);
                      }}
                      selected={selected}
                    />
                  )}
                  {/* <ViewApplicantDetails /> */}
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Box>
    );
  }
  return <></>;
}

const JobDescription = ({ jobData }) => {
  const userData = useSelector((state) => state.user.userData);
  return (
    <>
      {" "}
      <Box textAlign={"left"}>
        <Typography sx={{ ...labelStyles }}>Job Location</Typography>
        <Typography sx={{ ...valueStyles }}>
          {userData?.address}, {userData?.city}, {userData?.state}
        </Typography>
      </Box>
      <Box textAlign={"left"}>
        <Typography sx={{ ...labelStyles }}>Job Description</Typography>
        <Typography sx={{ ...valueStyles }}>{jobData?.description}</Typography>
      </Box>
      <Box textAlign={"left"} bgcolor={"#fbfdfd"}>
        <Typography sx={{ ...labelStyles, marginBottom: "10px" }}>
          Paycheck
        </Typography>
        <Box
          border={"1.5px solid #99c1cd"}
          padding={"20px"}
          borderRadius={"20px"}
        >
          <Box
            padding={"0 0 20px 0"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: "14px",
                  sm: "14px",
                  md: "16px",
                  lg: "16px",
                  xl: "16px",
                },
                fontWeight: 500,
                lineHeight: "19.2px",
                color: "#54677c",
              }}
            >
              Weekly Earnings
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "16px",
                  sm: "16px",
                  md: "18px",
                  lg: "18px",
                  xl: "18px",
                },
                fontWeight: 700,
                lineHeight: "21.6px",
                color: "#0D2645",
              }}
            >
              {getFormattedValue(jobData?.weeklyPay)}
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "16.8px",
                  color: "#8b97a6",
                }}
              >
                /wk
              </span>
            </Typography>
          </Box>
          <Divider />
          <Box>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              padding={"20px 0 5px 0"}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "14px",
                    sm: "14px",
                    md: "16px",
                    lg: "16px",
                    xl: "16px",
                  },
                  fontWeight: 500,
                  lineHeight: "19.2px",
                  color: "#54677c",
                }}
              >
                Travel Per Diems
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: "16px",
                    sm: "16px",
                    md: "18px",
                    lg: "18px",
                    xl: "18px",
                  },
                  fontWeight: 700,
                  lineHeight: "21.6px",
                  color: "#0D2645",
                }}
              >
                {getFormattedValue(
                  jobData?.houseAllowance + jobData?.mealAllowance
                )}
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "16.8px",
                    color: "#8b97a6",
                  }}
                >
                  /wk
                </span>
              </Typography>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              padding={"5 0"}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "19.2px",
                  color: "#54677c",
                }}
              >
                Meals
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "21.6px",
                  color: "#0D2645",
                }}
              >
                {getFormattedValue(jobData?.mealAllowance)}
              </Typography>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              padding={"5px 0 20px 0"}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "19.2px",
                  color: "#54677c",
                }}
              >
                Housing
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "21.6px",
                  color: "#0D2645",
                }}
              >
                {getFormattedValue(jobData?.houseAllowance)}
              </Typography>
            </Box>
            <Divider />
            <Box
              paddingTop={"20px"}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "14px",
                    sm: "14px",
                    md: "16px",
                    lg: "16px",
                    xl: "16px",
                  },
                  fontWeight: 500,
                  lineHeight: "19.2px",
                  color: "#54677c",
                }}
              >
                Total Weekly Payment
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: "16px",
                    sm: "16px",
                    md: "18px",
                    lg: "18px",
                    xl: "18px",
                  },
                  fontWeight: 700,
                  lineHeight: "21.6px",
                  color: "#0D2645",
                }}
              >
                {getFormattedValue(
                  jobData?.weeklyPay +
                    jobData?.houseAllowance +
                    jobData?.mealAllowance
                )}
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "16.8px",
                    color: "#8b97a6",
                  }}
                >
                  /wk
                </span>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const PendingCandidates = ({ jobData, type, handleShowUser, selected }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const arr = [
      {
        title: "Completed Jobs",
        route: "/hospital/completed-jobs",
      },
      {
        title: jobData?.title,
        route: "",
      },
    ];
    if (jobData?.title) {
      dispatch(setBreadcrumb(arr));
    }
  }, [jobData?.title]);
  return (
    <Grid
      sx={{
        marginTop: {
          xs: "20px",
          sm: "20px",
          md: 0,
          lg: 0,
          xl: 0,
        },
      }}
    >
      {jobData?.hiredNurses?.length > 0
        ? jobData?.hiredNurses?.map((candidate) => {
            return (
              <Box
                className={"card-adjust-according-device"}
                sx={{ width: "201px", margin: "0 10px 20px", float: "left" }}
                key={candidate?._id}
              >
                <Grid
                  key={candidate?._id}
                  bgcolor={"#f5f9fa"}
                  border={"1px solid #bdd7df"}
                  padding={"10px"}
                  borderRadius={"25px"}
                  sx={{ width: "100%", cursor: "pointer" }}
                  onClick={() => {
                    const arr = [
                      {
                        title: "Active Jobs",
                        route: "/hospital/active-jobs",
                      },
                      {
                        title: jobData?.title,
                        route: `/hospital/completed-jobs/${jobData?._id}`,
                        func: () => {
                          handleShowUser(false);
                        },
                      },
                      {
                        title: type,
                        route: `/hospital/completed-jobs/${jobData?._id}`,
                        func: () => {
                          handleShowUser(false);
                        },
                      },
                      {
                        title: candidate?.firstName + " " + candidate?.lastName,
                        route: "",
                      },
                    ];
                    if (jobData?.title) {
                      dispatch(setBreadcrumb(arr));
                    }
                    handleShowUser(candidate);
                  }}
                  position={"relative"}
                >
                  <Box paddingTop={"10px"}>
                    {type === "offerSent" && (
                      <Chip
                        label={statusLabels[candidate?.status] || ""}
                        sx={{
                          bgcolor: statusColors[candidate?.status] || "",
                          color: "#fff",
                          marginTop: "5px",
                          fontWeight: 700,
                          fontSize: "16px",
                          lineHeight: "19.2px",
                          position: "absolute",
                          top: 7,
                          left: 0,
                          right: 0,
                          width: "100px",
                          margin: "auto",
                          zIndex: 2,
                        }}
                      />
                    )}
                    <Box
                      sx={{
                        position: "relative",
                        zIndex: 1,
                        paddingBottom: "15px",
                      }}
                    >
                      <img
                        src={candidate?.profilePicture}
                        alt="applicant-img"
                        height={161}
                        width={161}
                        style={{ borderRadius: "25px" }}
                        className="jobs-profile-candidate-img"
                      />
                      <Chip
                        sx={{
                          bgcolor: "#ffffff",
                          position: "absolute",
                          bottom: "10px",
                          left: 0,
                          right: 0,
                          margin: "auto",
                          width: {
                            xs: "50%",
                            sm: "50%",
                            md: "40%",
                            lg: "40%",
                            xl: "35%",
                          },
                        }}
                        label={
                          <Box display={"flex"} justifyContent={"space-evenly"}>
                            <img src={StarIcon} alt="star" />
                            <Typography
                              color="#0d2645"
                              fontSize={"14px"}
                              lineHeight={"21px"}
                              fontWeight={500}
                              marginLeft={"5px"}
                            >
                              {candidate?.rating || 0}
                            </Typography>
                          </Box>
                        }
                      />
                    </Box>
                    <Typography
                      fontSize={"17px"}
                      fontWeight={700}
                      lineHeight={"18px"}
                      color="#0d2645"
                      marginBottom={"5px"}
                    >
                      {candidate?.firstName} {candidate?.lastName}
                    </Typography>
                    <Typography
                      fontSize={"17px"}
                      fontWeight={700}
                      lineHeight={"18px"}
                      color="#0d2645"
                      marginBottom={"5px"}
                    >
                      {candidate?.experience}{" "}
                      <span
                        style={{
                          fontSize: "16px",
                          lineHeight: "20.8px",
                          fontWeight: 500,
                          color: "#808f9f",
                        }}
                      >
                        yrs
                      </span>
                    </Typography>
                    <Typography
                      fontSize={"15px"}
                      fontWeight={700}
                      lineHeight={"18px"}
                      color="#0d2645"
                      marginBottom={"10px"}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          lineHeight: "20.8px",
                          fontWeight: 500,
                        }}
                      >
                        Applied on:&nbsp;
                      </span>
                      {format(new Date(candidate?.appliedOn), "MMM dd")}
                    </Typography>
                  </Box>
                </Grid>
              </Box>
            );
          })
        : null}
    </Grid>
  );
};

const ViewApplicantDetails = ({
  jobData,
  closeShowDetails,
  userDetails,
  handleShowRatings,
}) => {
  const dispatch = useDispatch();
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [reviewModalVisible, setReviewModalVisible] = useState(false);

  useEffect(() => {
    const arr = [
      {
        title: "Completed Jobs",
        route: "/hospital/completed-jobs",
      },
      {
        title: jobData?.title,
        route: `/hospital/completed-jobs/${jobData?._id}`,
        func: () => {
          dispatch(setActiveSelectedTab(0));
          closeShowDetails();
        },
      },
      {
        title: "Hired Nurses",
        route: `/hospital/completed-jobs/${jobData?._id}`,
        func: () => {
          dispatch(setActiveSelectedTab(1));
          closeShowDetails();
        },
      },
      {
        title: userDetails?.firstName + " " + userDetails?.lastName,
        route: "",
      },
    ];
    if (jobData?.title) {
      dispatch(setBreadcrumb(arr));
    }
  }, []);
  const handleReviewSubmit = (values) => {
    const payload = {
      userId: userDetails?._id,
      jobId: jobData?._id,
      rating: values?.rating,
      review: values?.review || " ",
    };
    SendReview(payload)
      .then((resp) => {
        setReviewModalVisible(false);
        setSuccessModalVisible(true);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
  return (
    <Box textAlign={"left"}>
      <Typography
        display={"flex"}
        alignItems={"center"}
        marginBottom={"20px"}
        onClick={closeShowDetails}
        sx={{
          cursor: "pointer",
        }}
      >
        <img src={BackNavigation} alt="back" />
        <span
          style={{
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "19.2px",
            color: "#38879F",
            marginLeft: "5px",
          }}
        >
          Back
        </span>
      </Typography>
      <Box
        sx={{
          height: "calc(100vh - 490px)",
          overflow: "auto",
        }}
      >
        <Box display={"flex"} alignItems={"center"}>
          <img
            src={userDetails?.profilePicture}
            width={132}
            style={{ borderRadius: "25px" }}
          />
          <Box marginLeft={"20px"}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "28.8px",
                color: "#0d2645",
              }}
            >
              {userDetails?.firstName} {userDetails?.lastName}
            </Typography>
            <Box
              display={"flex"}
              justifyContent={"space-evenly"}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                if (userDetails?.rating > 0) {
                  handleShowRatings();
                }
              }}
            >
              <img src={StarIcon} alt="star" />
              <Typography
                color="#0d2645"
                fontSize={"14px"}
                lineHeight={"21px"}
                fontWeight={500}
                marginLeft={"5px"}
              >
                {`${userDetails?.rating} (${userDetails?.ratingCount} ${
                  userDetails?.ratingCount > 1 ? "reviews" : "review"
                })`}
              </Typography>
            </Box>
            <Chip
              label={"Completed"}
              sx={{
                bgcolor: "#00b81d",
                color: "#fff",
                marginTop: "5px",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "19.2px",
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: {
              xs: "block",
              sm: "block",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
            width: {
              xs: "100%",
              sm: "100%",
              md: "50%",
              lg: "50%",
              xl: "45%",
            },
          }}
          marginTop={"20px"}
          justifyContent={"space-between"}
        >
          <Chip
            sx={{
              height: "35px",
              marginBottom: {
                xs: "10px",
                sm: "10px",
                md: 0,
                lg: 0,
                xl: 0,
              },
              marginRight: {
                xs: "10px",
                sm: "10px",
                md: 0,
                lg: 0,
                xl: 0,
              },
            }}
            variant={"outlined"}
            label={
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <img src={ClockIcon} />
                <Typography
                  sx={{
                    fontFamily: "Lato",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "19.2px",
                    color: "#0D2645",
                    paddingLeft: "5px",
                  }}
                >
                  {userDetails?.experience} Years Experience
                </Typography>
              </Box>
            }
          />
          <Chip
            sx={{
              height: "35px",
              marginBottom: {
                xs: "10px",
                sm: "10px",
                md: 0,
                lg: 0,
                xl: 0,
              },
            }}
            variant={"outlined"}
            label={
              <Link
                to={`tel:${userDetails?.countryCode + userDetails?.phone}`}
                style={{ textDecoration: "none" }}
              >
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <img src={CallIcon} />
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "19.2px",
                      color: "#0D2645",
                      paddingLeft: "5px",
                    }}
                  >
                    +{userDetails?.countryCode + "-" + userDetails?.phone}
                  </Typography>
                </Box>
              </Link>
            }
          />
          <Chip
            sx={{
              height: "35px",
              marginBottom: {
                xs: "10px",
                sm: "10px",
                md: 0,
                lg: 0,
                xl: 0,
              },
              marginRight: {
                xs: "10px",
                sm: "10px",
                md: 0,
                lg: 0,
                xl: 0,
              },
            }}
            variant={"outlined"}
            label={
              <Link
                to={`mailto:${userDetails?.email}`}
                style={{ textDecoration: "none" }}
              >
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <img src={EmailIcon} />
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "19.2px",
                      color: "#0D2645",
                      paddingLeft: "5px",
                    }}
                  >
                    {userDetails?.email}
                  </Typography>
                </Box>
              </Link>
            }
          />
        </Box>
        <Box margin={"20px 0"}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 400,
              lineHeight: "21.6px",
              color: "#0d2645",
              marginBottom: "10px",
            }}
          >
            Profile Description
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "26px",
              color: "#808f9f",
            }}
          >
            {userDetails?.bio}
          </Typography>
        </Box>
        {userDetails?.resume && (
          <Link to={userDetails?.resume} target="_blank">
            <Button
              sx={{
                height: "55px",
                width: {
                  xs: "100%",
                  sm: "70%",
                  md: "50%",
                  lg: "20%",
                  xl: "11%",
                },
                marginTop: "15px",
                background: "#38879f",
                color: "#fff",
                fontWeight: 600,
                borderRadius: "10px",
                "&.Mui-disabled": {
                  background: "#adcdd7",
                  color: "#fff",
                },
                "&:hover": {
                  backgroundColor: "#38879f",
                },
              }}
            >
              View Resume
            </Button>
          </Link>
        )}
      </Box>
      {!userDetails?.isReviewed && (
        <Box
          sx={{
            paddingTop: "30px",
          }}
          textAlign={"right"}
        >
          <Button
            sx={{
              width: {
                xs: "100%",
                sm: "50%",
                md: "50%",
                lg: "30%",
                xl: "20%",
              },
              height: "55px",
              marginTop: "15px",
              background: "#38879f",
              color: "#fff",
              fontWeight: 600,
              borderRadius: "10px",
              "&.Mui-disabled": {
                background: "#adcdd7",
                color: "#fff",
              },
              "&:hover": {
                backgroundColor: "#38879f",
              },
            }}
            onClick={() => setReviewModalVisible(true)}
          >
            Write a Review
          </Button>
        </Box>
      )}
      <RatingModal
        open={reviewModalVisible}
        handleClose={() => setReviewModalVisible(false)}
        handleSubmit={handleReviewSubmit}
      />
      <CommonModal
        title="Review submitted
        successfully"
        open={successModalVisible}
        icon={TickCardIcon}
        onClose={() => setSuccessModalVisible(false)}
        isSingleButton
        singleBtnText={"Done"}
      />
    </Box>
  );
};
