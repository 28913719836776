import { Typography, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NurseSuccess from "../../../assets/icons/nurse-success.svg";

export default function NurseApprovedAC() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        padding: {
          xs: "30px",
          sm: "0 30px",
          md: "0 70px",
          lg: "0 70px",
          xl: "0 70px",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: {
          xs: "auto",
          sm: "auto",
          md: "100vh",
          lg: "100vh",
          xl: "100vh",
        },
      }}
    >
      <Box
        border={2}
        borderColor={"#38879F"}
        borderRadius={5}
        sx={{
          position: "relative",
          width: "100%",
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          sx={{
            padding: {
              xs: "30px 20px",
              sm: "45px",
              md: "45px",
              lg: "45px",
              xl: "70px 45px",
            },
            width: {
              xs: "100%",
              sm: "100%",
              md: "70%",
              lg: "35%",
              xl: "30%",
            },
          }}
        >
          <img src={NurseSuccess} alt="logo" />
          <Typography
            variant="h3"
            color="#0d2645"
            fontWeight={600}
            margin={"20px 0 10px"}
            sx={{
              fontSize: {
                xs: 20,
                sm: 22,
                md: 24,
                lg: 26,
                xl: 26,
              },
              lineHeight: {
                xs: "27px",
                sm: "28px",
                md: "31.2px",
                lg: "31.2px",
                xl: "31.2px",
              },
            }}
          >
            Your account has been created
          </Typography>
          <Typography
            variant="h6"
            color="#8391a0"
            fontWeight={400}
            margin={"10px 0"}
            sx={{
              fontSize: {
                xs: 12,
                sm: 14,
                md: 15,
                lg: 16,
                xl: 16,
              },
              lineHeight: {
                xs: "20px",
                sm: "22px",
                md: "24px",
                lg: "25px",
                xl: "25px",
              },
            }}
          >
            {/* Verification may take up to 24 hours. Thank you for your patience. */}
          </Typography>
          <Button
            sx={{
              width: "100%",
              height: "55px",
              marginTop: "15px",
              background: "#38879f",
              color: "#fff",
              fontWeight: 600,
              borderRadius: "10px",
              "&.Mui-disabled": {
                background: "#adcdd7",
                color: "#fff",
              },
              "&:hover": {
                backgroundColor: "#38879f",
              },
            }}
            onClick={() => navigate("/nurse/dashboard")}
          >
            Get Started
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
