import axiosInstance from "../../api/axios";

export const PostJobAd = async (payload) => {
  const res = await axiosInstance.post("/api/v1/job/", payload);
  return res.data;
};

export const UpdateJobAd = async (payload, id) => {
  const res = await axiosInstance.put(`/api/v1/job/${id}`, payload);
  return res.data;
};

export const GetAllJobs = async (payload) => {
  const res = await axiosInstance.post("/api/v1/job/list-by-hospital", payload);
  return res.data;
};

export const GetJobDetails = async (id) => {
  const res = await axiosInstance.get(`/api/v1/job/${id}`);
  return res.data;
};

export const GetAllJobApplications = async (payload) => {
  const res = await axiosInstance.post(
    "/api/v1/job/list-job-applications",
    payload
  );
  return res.data;
};

export const DeleteJob = async (id) => {
  const res = await axiosInstance.delete(`/api/v1/job/${id}`);
  return res.data;
};

export const DeclineJob = async (id) => {
  const res = await axiosInstance.post(
    `/api/v1/job/decline-job-application/${id}`
  );
  return res.data;
};

export const SendJobOffer = async (id) => {
  const res = await axiosInstance.post(`api/v1/job/send-offer/${id}`);
  return res.data;
};

export const CompleteJob = async (id) => {
  const res = await axiosInstance.post(
    `api/v1/job/complete-job-applicant/${id}`
  );
  return res.data;
};

export const SendChekrRequest = async (payload) => {
  const res = await axiosInstance.post("/api/v1/checkr/", payload);
  return res.data;
};
