import axios from "axios";
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Replace with your API base URL
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Modify the request config here (add headers, authentication tokens)
    const accessToken = JSON.parse(localStorage.getItem("userData"))?.token;

    // If token is present add it to request's Authorization Header
    if (accessToken) {
      if (config.headers) config.headers.accessToken = `Bearer ${accessToken}`;
    }
    config.headers.Authorization =
      "Basic " +
      btoa(
        process.env.REACT_APP_BASIC_USERNAME +
          ":" +
          process.env.REACT_APP_BASIC_PASSWORD
      );
    return config;
  },
  (error) => {
    // Handle request errors here

    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Modify the response data here
    return response;
  },
  (error) => {
    // Handle response errors here
    if (error.response.data.error?.status === 401) {
      localStorage.clear();
      window.location.reload();
    }
    return Promise.reject(error?.response.data);
  }
);

export default axiosInstance;
