import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  breadCrumbArr: false,
};

const breadcrumbSlice = createSlice({
  name: "breadcrumb",
  initialState,
  reducers: {
    setBreadcrumb(state, action) {
      state.breadCrumbArr = action.payload;
    },
  },
});

export const { setBreadcrumb } = breadcrumbSlice.actions;
export default breadcrumbSlice.reducer;
